import React from "react";
import "./searchInput.scss";
import Form from "react-bootstrap/Form";

const SearchInput = (props) => {
  
  return (
    <div>
        <Form.Group className="searchBar">
          {/* <img src={searchIcon}  alt="seacrhicon"/> */}
          <Form.Control
            type="text"
            placeholder='Search...'
            onChange={(e) => {
              props.searchHandler(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                props.searchInput(e.target.value);
              }
            }}
          />
        </Form.Group>
    </div>
  );
};

SearchInput.propTypes = {};

export default SearchInput;
