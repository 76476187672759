import React, { useState, useEffect, useRef } from "react";
import styles from "./projectRolesBreakdown.module.scss";
import PaginationSidebar from "../../../components/shared/pagination-sidebar";
import { ReactComponent as CastLogo } from "../../../assets/icons/circleArc.svg";
import { ReactComponent as PersonPlusSvg } from "../../../assets/icons/NoCastWithPlus.svg";
import { ReactComponent as CameraLogo } from "../../../assets/icons/cameraLogo.svg";
import { ReactComponent as LeftHand } from "../../../assets/icons/LeftHand.svg";
import { ReactComponent as RightHand } from "../../../assets/icons/RightHand.svg";
import Modelpic from "../../../assets/images/modelPic.png";
import Footer from "../../../components/shared/footer";
import { useParams, useLocation, Link, useHistory } from "react-router-dom";
import { getProjectRolesBreakdown, deleteProjectRoleByRoleId } from "../../../services/projectRole";
import DeleteUserModal from "../../../components/deleteUserModal";
import { defaultLimit } from "../../../constant";
import { toast } from "react-toastify";
import { deleteCastFromProjectRoleById } from "../../../services/projectRole";
import { ReactComponent as NoDataImgCircle } from "../../../assets/icons/mainimgWhiteYell.svg";
import { getActiveRoleIndex, calculateOffsetByLimitAndCount } from "../../../helpers/commonHelper";


const ProjectRolesBreakdown = (props) => {
	let optionCountNumber = 0;
	const location = useParams();
  const history = useHistory();
  const getroleIndex = useLocation();
	const projectId = location?.projectId || 0;
	const localSelectedProjectName = localStorage.getItem("selectedProjectName");
  const [sidebarpageViewType, setSidebarPageViewType] = useState("view");
	const [isOpenSidebar, setIsOpenSidebar] = useState(false);
	const [roleCountNumber, setRoleCountNumber] = useState(1);
	const [activeRoleindex, setActiveRoleIndex] = useState(0);
	const [activeRolesDetails, setActiveRolesDetails] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [rolesBreakdown, setRolesBreakdown] = useState([]);
	const [rolesBreakdownCount, setRolesBreakdownCount] = useState(0);
  const [dragingFlag, setDragingFlag] = useState(false);
  const [startx, setStartx] = useState(0);
	const [hitRequest, setHitRequest] = useState(false);
  let queryParams = new URLSearchParams(getroleIndex.search);
  const roleIndex = queryParams.get("roleIndex");
  const roleId = queryParams.get("roleId");
  const offSet = queryParams.get("offSet");
  const ref = useRef();
	const [params, setParams] = useState({
		projectId: projectId,
		limit: window.innerWidth > 1600 ? defaultLimit : 6,
		offSet: offSet ? parseInt(offSet) : 0
	});
	const [createdRoleType, setCreatedRoleType] = useState('');

	const fetchProjectRolesBreakdown = async () => {
		const result = await getProjectRolesBreakdown(params);
		if (result.status) {
			setRolesBreakdown(result?.data?.rows);
			setActiveRolesDetails(result?.data?.rows[0]);
			setRolesBreakdownCount(result?.data?.count);
		}
	};

	useEffect(() => {
		setRoleCountNumber(params.offSet + 1);
		fetchProjectRolesBreakdown();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params.offSet, hitRequest]);

	useEffect(() => {
		setActiveRolesDetails(rolesBreakdown[activeRoleindex]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeRoleindex, rolesBreakdown]);

	const setRoleCreatedEvent = () => {
		if (createdRoleType === "update") {
			fetchProjectRolesBreakdown();
		} else if (createdRoleType === "create") {
			const newCount = parseInt(rolesBreakdownCount) + 1;
			const getNewOffset = calculateOffsetByLimitAndCount(newCount, parseInt(params?.limit));
			setParams({ ...params, offSet: getNewOffset });
			params?.offSet === getNewOffset && setHitRequest((prev) => !prev);
			setActiveRoleIndex(getActiveRoleIndex(rolesBreakdownCount, params?.limit));
		}
		setSidebarPageViewType("view");
	};

  const deleteProjectRole = async () => {
    const result = await deleteProjectRoleByRoleId(activeRolesDetails?.id);
    if (result.status) {
      toast.success("Role has been deleted successfully");
      if (activeRoleindex === rolesBreakdown?.length - 1) {
        setActiveRoleIndex(0);
      };

			/**
			 * if we are on last page and there is only one data and
			 * if we delete that data then offset will be change
			 */ 
			if (rolesBreakdown.length === 1 && params?.offSet >= params?.limit) {
				setParams({
					...params,
					offSet: params.offSet - params.limit
				});
			}
      fetchProjectRolesBreakdown();
    } else {
      toast.error("Some error occured while deleting Role");
    }
  }

	const handleProjectRolePagination = (type) => {
		setActiveRoleIndex(0);
		let offSet = params.offSet - params.limit;
		if (type === "next") {
			offSet = params.offSet + params.limit;
		}
		setParams({
			...params,
			offSet
		});
	};

  const handleMouseDown = (e) => {
    setDragingFlag(true);
    setStartx(e.pageX);
  };

  const handleMouseUp = (e) => {
    setDragingFlag(false);
  }

  const handleMouseLeave = () => {
    setDragingFlag(false);
  }
  
  useEffect(() => {
    if (roleIndex) {
      setActiveRoleIndex(parseInt(roleIndex));
      queryParams.delete("roleIndex");
      queryParams.delete("offSet");
      history.replace({
        search: queryParams.toString(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (roleId) {
      const activeRoleId = rolesBreakdown.findIndex((item) => item?.id === parseInt(roleId))
      setActiveRoleIndex(activeRoleId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rolesBreakdown])


  const removeCastFromProjectRole = async (roleId, talentId, optionsLength) => {
		try {
			let removeRoleFromScene = false;
			if (optionsLength === 1) {
				removeRoleFromScene = true;
			}
			const result = await deleteCastFromProjectRoleById(roleId, talentId, 0, removeRoleFromScene);
			if (!result.status) {
				toast.error(result.errorMsg);
			} else {
				toast.success(`Cast has been removed successfully.`);
        fetchProjectRolesBreakdown();
			}
		} catch (error) {
			toast.error(`Some issue occurred while removing cast.`);
		}
	};

  return (
    <>
		<div className={isOpenSidebar === false ? styles.collapseSidebar : styles.rolesBreakDownContainer}>
			<div className={styles.sidebar}>
				<PaginationSidebar
					data= { rolesBreakdown }
					limit= { params.limit }
					offSet = { params.offSet }
					handlePagination = { handleProjectRolePagination }
					projectId = { projectId }
					totalCount = { rolesBreakdownCount }
					isOpenSidebar = { isOpenSidebar }
					setIsOpenSidebar = { (value) => setIsOpenSidebar(value) }
					heading={ "My Roles" }
					pageType={ "roles-breakdown" }
					subTitleForCount = { "Roles" }
					activeRoleindex = { activeRoleindex }
					setActiveRoleIndex = { setActiveRoleIndex }
					setRoleCreatedEvent = { setRoleCreatedEvent }
          sidebarpageViewType = { sidebarpageViewType }
          setSidebarPageViewType = { setSidebarPageViewType }
					setCreatedRoleType = { setCreatedRoleType }
				/>
			</div>
			<div className={`${styles.content} spin`}>
				<div className={styles.headerBox}>
					<div className={styles.firsthead}>
						<CameraLogo />
						<div className="logotext">
							<h2 className={styles.logotoptext}>{activeRolesDetails?.role_name ? activeRolesDetails.role_name : ""}</h2>
							<p className={styles.logobotText}><span>{rolesBreakdown?.length > 0 && `Role ${roleCountNumber + activeRoleindex}`} </span>
							{activeRolesDetails?.projectRoles?.project_name ? activeRolesDetails.projectRoles.project_name : localSelectedProjectName}</p>
						</div>
					</div>
					<div className={styles.midHeader}>
						<p className={styles.midToptxt}>My Roles</p> 
						<div className={styles.midbotDiv}>
							{activeRolesDetails?.projectRoles?.project_type_id === 3 &&
								<p className={styles.midbotinnText}><span>{activeRolesDetails?.episode_count}</span> Episodes</p>
							}
							<p className={styles.midbotinnText}><span>{activeRolesDetails?.scene_count}</span> Scenes</p>
							<p className={styles.midbotinnText}><span>{activeRolesDetails?.roleOptions?.length}</span> Options</p>
						</div>
					</div>
					<div className={styles.lastSecHead}>
						{rolesBreakdown?.length > 0 && 
						<div className={styles.lstInnerhead}>
							<div className={styles.lstHeadBtmbtn} onClick={() => {
                setIsOpenSidebar(true);
                setSidebarPageViewType("updated");
								setCreatedRoleType("update");
              }}>Edit</div>
							<div className={styles.lstHeadBtmbtn} onClick={() => {
                setShowDeleteModal(true);
              }}>Delete</div>
						</div>}
					</div>
				</div>
				{rolesBreakdown?.length !== 0 ?
				<>
				<div className={styles.innerCastMainSec}>
          {activeRolesDetails?.role_description !== null && activeRolesDetails?.role_description !== "" &&
					<div className={styles.roleDescription}>
						<p className={styles.roleDescTitle}>Role Description</p>
            <p className={styles.roleDescText}>{activeRolesDetails?.role_description}</p>
					</div>
          }
          <div className={styles.modelImageLineBox}>

            <div className={styles.modelImageBox}>
              <div className={styles.iconTextLines}>
                <div className={styles.lineBorder}></div>
                <div className={styles.iconTextBox}>
                  <CastLogo className={styles.casticonsvg}/>
                  <span className={styles.casticonsvgText}>{activeRolesDetails?.role_name ? activeRolesDetails.role_name : ""}</span>
                </div>
              </div>
              <div className={styles.modelImageContainer} ref={ref} onMouseDown={handleMouseDown} onMouseLeave={handleMouseLeave} onMouseUp={handleMouseUp} onMouseMove={(e) => {
                const slider = ref.current;
                if (e.clientX > startx && dragingFlag ) {
                    slider.scrollLeft -= 40;
                } else {
                  if(dragingFlag) 
                  slider.scrollLeft += 40;
                };
              }} >
                {activeRolesDetails?.roleOptions?.map((item, index) => {
                  optionCountNumber += 1;
                  return (
                  <div className={styles.modelIndivBox} key={index} onClick={() => history.push(`/director/talent-profile/${item.talentOptions.id}`)}>
                    {item?.talentOptions?.images[0]?.title !== undefined ? 
                      <img src={`${process.env.REACT_APP_YOUCAST_USER_IMAGES_PATH}/${process.env.REACT_APP_MODE}/${item?.talent_id}/account/${item?.talentOptions?.images[0]?.title}`} className={styles.SinglModeImg} alt="SingleModelImage"/> : 
                      <img src={Modelpic} className={styles.SinglModeImg} alt="SingleModelImage"/>
                    }
                    <div className={styles.modelSing}>
                      <p className={styles.modleName}>{item?.talentOptions?.name}</p>
                      <p className={styles.modlelabel}>{item?.talentOptions?.modelCategories[0]?.modelCategoriesName?.name}</p>
                      <p className={styles.modlelabel}>{item?.talentOptions?.modelCategories[1]?.modelCategoriesName?.name}</p>
                      <p className={styles.modleOptionNo}>Option <span>{optionCountNumber}</span></p>
												<div className={styles.ancherbtnsbox}>
													<p className={styles.anchrCastbtn}
														onClick={(e) => {
															e.stopPropagation()
															removeCastFromProjectRole(activeRolesDetails?.id, item?.talent_id, activeRolesDetails?.roleOptions?.length)
														}}
													>
														Remove Cast
													</p>
												</div>
                    </div>
                  </div>
                )})}

                <div className={styles.modelIndivBox}>
                  <Link to={rolesBreakdown.length > 0 && `/director/talents?addCast=${activeRoleindex}&roleId=${activeRolesDetails?.id}&projectId=${projectId}&offSet=${params.offSet}`}>
                    <div className={styles.ThirdNocastbox}>
                      <p className={styles.modleNoCast}>No Cast</p>
                      <PersonPlusSvg className={styles.PersonPlusSvg}/> 
                      <div className={styles.modleBtmSecNocast}>
                        <p>Search Talents Now</p>
                        <p className={styles.modleOptionNo}>Option <span>{optionCountNumber + 1}</span></p>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>	
            </div>
          </div>
				</div>
				</>:
				 <div className={styles.noDataMsg} onClick={() => {
					setSidebarPageViewType("created"); 
					setIsOpenSidebar(true);
					setCreatedRoleType("create");
					}}>
				 <NoDataImgCircle className={styles.Noroleimg} />
				 <p className={styles.noRoleText}>
				 No Role Created
				 </p>
			 </div>
			}
			</div>
			<div className={styles.leftRightHands}>
				{rolesBreakdown?.length > 0 && activeRoleindex !== 0 &&
					<div 
						className={styles.leftHandArrow} 
						onClick={() => {
							setActiveRolesDetails(rolesBreakdown[activeRoleindex]); 
							setActiveRoleIndex((prev) => prev !== 0 ? prev - 1 : prev);
						}}
					>
						<LeftHand className={styles.lefthanfSvg} />
						<p className={styles.lefthandtext}>Previous Role</p>
					</div>
				}

				<Footer history={props.history} />
				
				{rolesBreakdown?.length > 0 && activeRoleindex !== rolesBreakdown.length-1 &&
					<div 
						className={styles.rightHandarrow} 
						onClick={() => {
							setActiveRolesDetails(rolesBreakdown[activeRoleindex]); 
							setActiveRoleIndex((prev) => prev < rolesBreakdown?.length - 1 ? prev + 1 : prev);
						}}
					>
						<p className={styles.Righthandtext}>Next Role</p>
						<RightHand className={styles.lefthanfSvg} />
					</div>
				}
			</div>
		</div>

    <DeleteUserModal
        show={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        id={activeRolesDetails?.id}
        heading={"Delete Role?"}
        subHeading={"Are you sure that you want to delete this Role?"}
        handleDelete={deleteProjectRole}
      />

    </>
	);
}

export default ProjectRolesBreakdown;
