import React, { useEffect, useState, useRef } from "react";
import styles from "./sceneDetail.module.scss";
import { ReactComponent as Sidelogo } from "../../../assets/icons/sidelogo.svg";
import { ReactComponent as CastLogo } from "../../../assets/icons/circleArc.svg";
import { ReactComponent as LeftHand } from "../../../assets/icons/LeftHand.svg";
import { ReactComponent as RightHand } from "../../../assets/icons/RightHand.svg";
import { ReactComponent as NoDataImgCircle } from "../../../assets/icons/mainimgWhiteYell.svg";
import SinglModeImg from "../../../assets/images/RoleCast.png";
import ScenesDetailSidebar from "../../../components/director-sidebar/sceneDetailSidebar";
import DeleteUserModal from "../../../components/deleteUserModal";
import Footer from "../../../components/shared/footer";
import { getProjectEpisodeDetails, deleteEpisodesAndSceneById, deleteProjectRoleFromSceneById } from "../../../services/episodeSceneService";
import { deleteCastFromProjectRoleById } from "../../../services/projectRole";
import { getSceneLisingtByEpisodeAndProjectID, setSidebarPageViewType } from "../../../redux/Scenes/actions";
import { defaultLimit } from "../../../constant";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { ReactComponent as PersonPlusSvg } from "../../../assets/icons/NoCastWithPlus.svg";

const ScenesDetail = (props) => {
	const ref = useRef(null);
	const dispatch = useDispatch();
	const location = useParams();
	const pageLocation = useLocation();
	const scenesList = useSelector((state) => state?.Scenes?.data);
	const [isOpenSidebar, setIsOpenSidebar] = useState(false);
	const sceneCountNumber = 1;
	const [activeSceneindex, setActiveSceneIndex] = useState(0);
	const [projectEpisodeDetails, setProjectEpisodeDetails] = useState([]);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [offSet, setOffSet] = useState(0);
	const [createdSceneType, setCreatedSceneType] = useState('');
	const history = useHistory();
	const queryParams = new URLSearchParams(pageLocation.search);
	const activeIndex = queryParams.get("activeSceneIndex") || "";
	const offsetPage = queryParams.get("offSet") || "";

	let optionCountNumber = 0;
	const data = scenesList?.scenesList;
	const episodeName = projectEpisodeDetails?.episodes;
	const projectSceneRoleData = data[activeSceneindex]?.projectSceneRoles;
	const projectId = location?.projectId;
	const episodeId = location?.episodeId ? location?.episodeId : 0;
  
	const params = {
		projectId: projectId,
		episodeId: episodeId !== undefined ? episodeId : 0,
		limit: defaultLimit,
		offSet: offSet
	};

	const sceneData = {
		sceneId: data[activeSceneindex]?.id,
		sceneName: data[activeSceneindex]?.scene_name,
	};

	const getEpisodeProjectDetail = async (projectId, episodeId) => {
		const result = await getProjectEpisodeDetails(projectId, episodeId);
		if (!result.status) {
			toast.error(result.errorMsg);
		} else {
			setProjectEpisodeDetails(result.data);
		}
	};

	const deleteSceneById = async () => {
		const result = await deleteEpisodesAndSceneById(sceneData?.sceneId, "scenes");
		if (!result.status) {
			toast.error(result.errorMsg);
		} else {
			toast.success(`Scene has been deleted successfully`);
			dispatch(getSceneLisingtByEpisodeAndProjectID(params));
			dispatch(setSidebarPageViewType("view"));
			if(data?.length === 1 && params?.offSet >= params?.limit) {
				setOffSet((prev) => prev-params?.limit)
			}
			setActiveSceneIndex(0);
		}
	};

	useEffect(() => {
		getEpisodeProjectDetail(projectId, episodeId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (activeIndex) {
			setActiveSceneIndex(parseInt(activeIndex));
			queryParams.delete("activeSceneIndex");
			queryParams.delete("offSet");
			// Create a new URLSearchParams object with the updated query parameters
			const updatedSearchParams = new URLSearchParams(queryParams.toString());
			// Remove the question mark if there are no query parameters remaining
			const queryString = updatedSearchParams.toString() ? `?${updatedSearchParams.toString()}` : '';
			// Update the query parameters using window.history.replaceState
			const newUrl = `${window.location.pathname}${queryString}`;
			window.history.replaceState({}, "", newUrl);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeIndex]);

  const handleScrollCastAndRole = (event) => {
    const container = ref.current;
    container.scrollTo({
      left: container.scrollLeft + (event.deltaY * 1)
    });
  };

	const removeProjectRoleFromScene = async (sceneId, roleId) => {
		try {
			const result = await deleteProjectRoleFromSceneById(sceneId, roleId);
			if (!result.status) {
				toast.error(result.errorMsg);
			} else {
				toast.success(`Role has been removed successfully.`);
				dispatch(getSceneLisingtByEpisodeAndProjectID(params));
			}
		} catch (error) {
			toast.error(`Some issue occurred while removing roles.`);
		}
	};

	const removeCastFromProjectRole = async (roleId, talentId, optionsLength) => {
		try {
			let removeRoleFromScene = false;
			if (optionsLength === 1) {
				removeRoleFromScene = true;
			}
			const result = await deleteCastFromProjectRoleById(roleId, talentId, sceneData?.sceneId, removeRoleFromScene);
			if (!result.status) {
				toast.error(result.errorMsg);
			} else {
				toast.success(`Cast has been removed successfully.`);
				dispatch(getSceneLisingtByEpisodeAndProjectID(params));
			}
		} catch (error) {
			toast.error(`Some issue occurred while removing cast.`);
		}
	};
	
	useEffect(() => {
		dispatch(setSidebarPageViewType("view"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
  
	return (
	<>
		<div className={isOpenSidebar === false ? styles.collapseSidebar : styles.sceneDetailContainer}>
			<div className={styles.sidebar}>
				<ScenesDetailSidebar
					isOpenSidebar = { isOpenSidebar }
					setIsOpenSidebar = { (value) => setIsOpenSidebar(value) }
					activeSceneindex = { activeSceneindex }
					setActiveSceneIndex = { setActiveSceneIndex }
					sceneData = { sceneData }
					projectEpisodeDetails = { projectEpisodeDetails }
					offsetPage={ offsetPage }
					setOffSet = { setOffSet }
					offSet = {offSet}
					params = { params }
					createdSceneType = { createdSceneType }
					setCreatedSceneType = { setCreatedSceneType }
				/>
			</div>

			<div className={styles.content} ref={ref} onWheel={handleScrollCastAndRole}>
				<div className={styles.headerBox}>
					<div className={styles.firsthead}>
						<div className={styles.sideImgbox}>
							<Sidelogo className={styles.sideimgsvgicon} />
							<p className={styles.sideimgsvgtxt}>Youcast</p>
						</div>
						<div className="logotext">
							{episodeId !== undefined ?
								<h2 className={styles.logotoptext}>{episodeName && `Eps 1 :`} {episodeName && episodeName[0]?.episode_name}</h2> :
								<h2 className={styles.logotoptext}>{projectEpisodeDetails?.project_name}</h2>
							}
							<p className={styles.logobotText}><span>Scene {sceneCountNumber + activeSceneindex} : </span>{data[activeSceneindex]?.scene_name}</p>
						</div>
					</div>
					<div className={styles.midHeader}>
						<p className={styles.midToptxt}>In Scene</p>
						<div className={styles.midbotDiv}>
							<p className={styles.midbotinnText}><span>{projectSceneRoleData?.length}</span> Roles</p>
							<p className={styles.midbotinnText}>
								<span>{projectSceneRoleData?.reduce((acc, item) => (acc + item?.roleData?.roleOptions?.length), 0)}</span> Cast
							</p>
						</div>
					</div>
					<div className={styles.lastSecHead}>
						<div className={styles.lstInnerhead}>
							<div
								className={styles.lstHeadBtmbtn}
								onClick={() => {
									setIsOpenSidebar(true);
									dispatch(setSidebarPageViewType("updated"));
									setCreatedSceneType("update");
								}}
							>
								Edit
							</div>
							<div
								className={styles.lstHeadBtmbtn}
								onClick={() => setShowDeleteModal(true)}
							>
								Delete
							</div>
						</div>
						<div
							className={data.length > 0 ? styles.lstHeadTopbtn : styles.disable}
							onClick={() =>{dispatch(setSidebarPageViewType("insert")); setIsOpenSidebar(true);}}
							
						>
							Insert to Scene
						</div>
					</div>
				</div>

				<div className={styles.innerCastMainContainer}>
					{projectSceneRoleData?.sort((x, y) => y.roleData.scene_count - x.roleData.scene_count)?.map((item, index) => {
						optionCountNumber = 0;
						return (
							<div className={styles.innerCastMainSec} key={index}>
								<div className={styles.iconTextLines}>
									<div className={styles.logolinetext}>
										<CastLogo className={styles.casticonsvg} />
										<p className={styles.casticonsvgText} onClick={() => {history.push(`/director/project-roles-breakdown/${projectId}?roleId=${item.role_id}`)}}>{item?.roleData?.role_name}</p>
									</div>
									<div className={styles.lineBorder}></div>
								</div>
								<div className={styles.modelImageBox}>
									{item?.roleData?.roleOptions?.map((ele, index) => {
										optionCountNumber += 1;
										return (
											<div className={styles.modelIndivBox} key={index} onClick={(e)=> {
												e.stopPropagation()
												history.push(`/director/talent-profile/${ele?.talentOptions?.id}`)
												}}>
												{ele?.talentOptions?.images[0]?.title !== undefined ?
													<img
														src={`${process.env.REACT_APP_YOUCAST_USER_IMAGES_PATH}/${process.env.REACT_APP_MODE}/${ele?.talentOptions?.id}/account/${ele?.talentOptions?.images[0]?.title}`}
														className={styles.SinglModeImg}
														alt="SingleModelImage"
													/> :
													<img src={SinglModeImg} className={styles.SinglModeImg} alt="SingleModelImage" />
												}
												<div className={styles.modelSing}>
													<p className={styles.modelName}>{ele?.talentOptions?.name}</p>
													<div className={styles.modelCategory}>
														<p className={styles.modelLabel}>{ele?.talentOptions?.modelCategories[0]?.modelCategoriesName?.name} </p>
														{ele?.talentOptions?.modelCategories[1]?.modelCategoriesName?.name &&
															<p className={styles.modelLabel}>- {ele?.talentOptions?.modelCategories[1]?.modelCategoriesName?.name}</p>
														}
													</div>
													<p className={styles.modelOptionNo}>Option <span>{optionCountNumber}</span></p>
													<div className={styles.ancherbtnsbox}>
														<p className={styles.anchrCastbtn} 
															onClick={(e) => {
																e.stopPropagation()
																removeCastFromProjectRole(item?.role_id, ele?.talentOptions?.id, item?.roleData?.roleOptions?.length)
															}}
														>
															Remove Cast
														</p>
														<p className={styles.anchrRolebtn} onClick={(e) => {
															e.stopPropagation()
															removeProjectRoleFromScene(sceneData?.sceneId, item?.role_id)
															}}>
															Remove Role
														</p>
													</div>
												</div>
											</div>
										)
									})}
									{item.roleData.roleOptions.length === 0 && 
									 <div className={styles.modelIndivBox} onClick={() => {history.push(`/director/talents?projectId=${projectId}&roleId=${item.role_id}&pageType=scenesDetail&activeSceneIndex=${activeSceneindex}&offSet=${offSet}`)}}>
										 <div className={styles.ThirdNocastbox}>
											  <p className={styles.modleNoCast}>No Talent Added</p>
											  <PersonPlusSvg className={styles.PersonPlusSvg} />
											  <div className={styles.modleBtmSecNocast}>
												 <p>Search Talents Now</p>
												 <p className={styles.modleOptionNo}>Option <span>{optionCountNumber + 1}</span></p>
											  </div>
										 </div>
									 </div>
									}
								</div>
								<div className={styles.epiScenesBox}>
								{ location?.episodeId ?
										<>
											<p className={styles.noScenes}><span>{item?.roleData?.scene_count}</span> Scenes</p>
											<p className={styles.noEpisode}><span>{item?.roleData?.episode_count}</span> Episodes</p>
										</>
									: 
									<p className={styles.noScenes}><span>{item?.roleData?.scene_count}</span> Scenes</p>
								}
								</div>
							</div>
						)
					})}
				</div>
				{projectSceneRoleData?.length === 0 &&
					<div className={styles.noDataMsg} onClick={() =>{dispatch(setSidebarPageViewType("insert")); setIsOpenSidebar(true);}}>
						<NoDataImgCircle className={styles.Noroleimg} />
						<p className={styles.noRoleText}>
							No Role Inserted
						</p>
					</div>
				}
				{scenesList.scenesCount === 0 &&
					<div className={styles.noDataMsg} onClick={() =>{
						dispatch(setSidebarPageViewType("created")); 
						setIsOpenSidebar(true);
						setCreatedSceneType("create");
						}}
						>
						<NoDataImgCircle className={styles.Noroleimg} />
						<p className={styles.noRoleText}>
							No Scene Created
						</p>
					</div>
				}
			</div>



			<div className={styles.leftRightHands}>
				{data?.length > 0 && activeSceneindex !== 0 &&
					<div
						className={styles.leftHandArrow}
						onClick={() => {
							setActiveSceneIndex((prev) => prev !== 0 ? prev - 1 : prev);
						}}
					>
						<LeftHand className={styles.lefthanfSvg} />
						<p className={styles.lefthandtext}>Previous Scene</p>
					</div>
				}

				<Footer history={props.history} />
				{data?.length > 0 && activeSceneindex !== data?.length - 1 &&
					<div
						className={styles.rightHandarrow}
						onClick={() => {
							setActiveSceneIndex((prev) => prev < data?.length - 1 ? prev + 1 : prev);
						}}
					>
						<p className={styles.Righthandtext}>Next Scene</p>
						<RightHand className={styles.lefthanfSvg} />
					</div>
				}
			</div>

			<DeleteUserModal
				show={showDeleteModal}
				handleClose={() => setShowDeleteModal(false)}
				id={data[activeSceneindex]?.id}
				heading={"Delete Scene ?"}
				subHeading={"Are you sure that you want to delete this Scene ?"}
				handleDelete={deleteSceneById}
			/>
		</div>
	</>
	);
}

export default ScenesDetail;