import React, { useEffect, useState } from "react";
import styles from "./userAccounts.module.scss";
import { getAllLoginUserList, deleteLoginuser } from "../../../services/loginUserService";
import { deleteAllProjectByUserId } from "../../../services/projectService";
import { toast } from "react-toastify";
import Button from "react-bootstrap/Button";
import DeleteUserModal from "../../../components/deleteUserModal";
import Modal from "../../../components/modal/index";

const UserAccounts = () => {
  const [existingData, setExistingData] = useState([]);
  const [countOfLoginUser, setCountOfLoginUser] = useState(0);
  const [adminData, setAdmindata] = useState([]);
  const [roleId, setRoleId] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [loginUserData, setLoginUserData] = useState(null);
  const [isLoadMore, setIsLoadMore] = useState(true);
  const [params, setParams] = useState({
    offset: 0,
    limit: 10
  });

  const isAdminOrDirector = roleId === 1 ? "Admin" : "Director";

  const getAllLoginUserListData = async () => {
    const result = await getAllLoginUserList(roleId, params, "list");
    if (result.status) {
      setExistingData(result?.data);
      setCountOfLoginUser(result?.data?.count);
      if (isLoadMore) {
        setAdmindata([...adminData, ...result?.data?.rows]);
      } else {
        setAdmindata([]);
        setAdmindata(result?.data?.rows);
      };
    } else {
      toast.error(result?.msg);
    }
  };

  const handleShow = () => {
    setShowModal(true);
  };
  
  const handleClose = () => {
    setShowModal(false);
  };

  const handleLoadMore = () => {
    setIsLoadMore(true);
    let offSet = params.offSet + params.limit;
    setParams({
      ...params,
      offSet
    });
  };

  useEffect(() => {
      getAllLoginUserListData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleId, params.offSet]);

  const updateLoginUsersList = () => {
    if (params.offSet > 0) {
      setParams({
        ...params,
        offSet: 0
      })
    } else {
      getAllLoginUserListData();
    }
  };
  
  const handleDeleteLoginUser = async() => {
    const result = await deleteLoginuser(loginUserData?.id);
    if (result.status) {
      if (isAdminOrDirector === "Director") {
        deleteAllProjectByUserId(loginUserData?.id);
      }
      toast.success(`${isAdminOrDirector} has been deleted successfully`);
      const index = adminData.findIndex(e => e.id === loginUserData?.id);
      if (index) {
        adminData.splice(index, 1);
      }
      setAdmindata(adminData);
      setCountOfLoginUser((prev) => prev - 1);
    } else {
      toast.success(`Some error occured while deleting ${isAdminOrDirector}`);
    }
  };

	return (
		<>
			<div className={styles.headerTopSec}>
				<p className={styles.topLeftlabel}>Giving Talents a better chance</p>
				<h4 className={styles.topHeadTitle}>Access to Talents</h4>
				<div className={styles.topHeaderbtn}>
          <button 
            className={roleId === 1 ? styles.leftYellowbtnSelected : styles.leftYellowbtn} 
            onClick={() => {
              setParams({...params, offSet: 0});
              setRoleId(1);
              setAdmindata(adminData.filter((adminData) => adminData.role_id === 1));
            }}
          >
            Admins
          </button>
          <button className={roleId === 2 ? styles.leftwhitebtnSelected : styles.leftwhitebtn}
            onClick={() => {
              setAdmindata(adminData.filter((adminData) => adminData.role_id === 2));
              setParams({...params, offSet: 0});
              setRoleId(2);
            }} 
          >
            Directors App
          </button>
				</div>
			</div>
			<div className={styles.adminDetal}>
				<div className={styles.btnTileNav}>
					<p className={styles.adminTotalTitle}>{roleId === 1 ? "Admins" : "Directors"}<span> {countOfLoginUser}</span></p>
            {roleId === 1 ?
              <button className={styles.adminCreateBtn} onClick={() => {
                setLoginUserData(null);
                setIsLoadMore(false);
                handleShow();
              }}>Create New Admin</button> :
              <button className={styles.adminCreateBtn} onClick={() => {
                setLoginUserData(null);
                setIsLoadMore(false);
                handleShow();
              }}>Create New Director</button>
            }
				</div>
				{adminData.map((item, index) => {
         return <div key={index} className={styles.adminDetailbox}>
					<div className={styles.indivualDetail}>
						<div className={styles.labelTexttitle}>
							<p className={styles.nameLabel}>Admin Name</p>
							<p className={styles.nameText}>{item.name}</p>
						</div>
						<div className={styles.labelTexttitle}>
							<p className={styles.nameLabel}>Email</p>
							<p className={styles.nameText}>{item.email}</p>
						</div>
						<div className={styles.labelTexttitle}>
							<p className={styles.nameLabel}>Phone</p>
							<p className={styles.nameText}>{item.phone_number}</p>
						</div>
					</div>
					<div className={styles.editDeleteBtn}>
						<button className={styles.editbtn} onClick={() => {
              handleShow();
              setIsLoadMore(false);
              setLoginUserData(item);
              }}>Edit</button>
						<button className={styles.deletebtn} onClick={() => {
              setShowDeleteModal(true);
              setIsLoadMore(false);
              setLoginUserData(item);
            }} >Delete</button>
					</div>
				</div>
        })}
          {existingData?.rows?.length >= params.limit &&
          <div className=" d-flex justify-content-center p-4">
            <Button variant="warning" onClick={ handleLoadMore }>
              Load more
            </Button>
          </div>}
			</div>
      <Modal
        handleShow = { handleShow }
        handleClose = { handleClose }
        show = { showModal }
        loginUserData = { loginUserData }
        updateLoginUsersList={ updateLoginUsersList }
        roleId = { roleId }
        pageType = { "user-accounts" }
        isAdminOrDirector = { isAdminOrDirector }
      ></Modal>

      <DeleteUserModal
        show={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        heading={ `Delete ${isAdminOrDirector}?` }
        subHeading={ `Are you sure that you want to delete this ${isAdminOrDirector}?` }
        handleDelete={handleDeleteLoginUser}
      />
		</>
	);
}

export default UserAccounts;