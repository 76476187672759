import { createStore, applyMiddleware } from "redux";
import { rootReducer } from "./../redux/reducer";
import thunk from "redux-thunk";
import logger from "redux-logger";

const middlewares = [];
middlewares.push(thunk);

if (process.env.NODE_ENV === "development") {
  middlewares.push(logger);
}

export const store = createStore(rootReducer, applyMiddleware(...middlewares));
