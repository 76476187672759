import moment from "moment";

export function getAge(DOB) {
    const currentYear = moment().format("YYYY");
    const modelBirthYear = moment(DOB).format("YYYY");
    const age = currentYear - modelBirthYear;
    return age;
}

export function calculateOffsetByLimitAndCount(totalCount, limit) {
    let pageNo = parseInt(totalCount / limit);
    if ((totalCount % limit) === 0 && pageNo > 0) { //exact divisible
        pageNo -= 1;
    }
    return pageNo * limit;		
};

export function getActiveRoleIndex(totalCount, limit) {
    return (totalCount - parseInt(totalCount / limit) * limit);
};

  