import React, { useEffect, useState } from "react";
import styles from "./projectsList.module.scss";
import Button from "react-bootstrap/Button";
import { ReactComponent as YellowCircle } from "../../../assets/icons/mainimgWhiteYell.svg";
import { ReactComponent as SearchIcon } from "../../../assets/icons/SearchIcon.svg";
import { ReactComponent as NoDataImgCircle } from "../../../assets/icons/mainimgWhiteYell.svg";
import { getProjectsByID } from "../../../redux/Projects/actions";
import { getAllLoginUserList } from "../../../services/loginUserService";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { debounce } from "lodash";
import Moment from "moment";
import Dropdown from "react-bootstrap/Dropdown";
import { updateProjectStatusById } from "../../../services/projectService";
import { toast } from "react-toastify"; 




const ProjectsList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const projectList = useSelector((state) => state?.Projects?.data?.projectList);
  const projectListData = projectList?.rows;
  const [projectData, setProjectData] = useState([]);
  const [userData, setUserData] = useState();
  const [loadMore , setLoadMore] = useState(false);
  const [params, setParams] = useState({
    userId: 0,
    offset: 0,
    limit: 10,
    searchType: "projectName",
    searchProjectTypeValue: "",
    searchDirectorValue: "",
    searchedValue: ""
  });

  useEffect(() => {
    if (projectListData) {
      if(loadMore) {
        setProjectData((prev) => [...prev, ...projectListData]);
      } else {
        setProjectData(projectListData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectListData]);

  useEffect(() => {
    if (params.offset !== 0) {
      setLoadMore(true);
    }
    dispatch(getProjectsByID(params));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.offset]);

  useEffect(() => {
    (async () => {
      const result = await getAllLoginUserList("2", params, "filter");
      if (result.status) {
        setUserData(result?.data?.rows);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLoadMore = () => {
    let offset = params.offset + params.limit;
    setParams({
      ...params,
      offset
    });
  };

  const searchInput = (value) => {
    let updatedParams = {
      userId: 0,
      offset: 0,
      limit: 10,
      searchType: params.searchType,
      searchProjectTypeValue: params.searchProjectTypeValue,
      searchedValue: value
    };
    setParams(updatedParams);
    setLoadMore(false);
    dispatch(getProjectsByID(updatedParams));
  };
  
  const searchHandler = debounce((value) => {
    searchInput(value);
  }, 1000);

  const onChangeFilter = (e) => {
    setParams({
      ...params,
      searchType: e.target.value
    });
  };

  const onChangeProjectTypeFilter = (e) => {
    let updatedParams = {
      ...params,
      searchProjectTypeValue: e.target.value
    }
    setParams(updatedParams);
    setLoadMore(false);
    dispatch(getProjectsByID(updatedParams));
  };

  const onChangeDirectorFilter = (e) => {
    let updatedParams = {
      ...params,
      searchDirectorValue: e.target.value
    }
    setParams(updatedParams);
    setLoadMore(false);
    dispatch(getProjectsByID(updatedParams));
  };

  const handleProjectStatus = async(projectId, value) => {
    const data = {
      projectStatus: value
    }
    const result = await updateProjectStatusById(projectId, data);
    if (result.status === true) {
      dispatch(getProjectsByID(params));
      toast.success("Project Status Has Been Updated Successfully");
    } else {
      toast.error(result.errorMsg);
    }
  };


  return (
    <>
      <div className={styles.projectTophead}>
        <p className={styles.topLeftlabel}>Giving Talents a better chance</p>
        <div className={styles.projectNumbFilt}>
          <p className={styles.projectNumTitle}>Projects <span> {projectList?.count}</span></p>
          <div className={styles.sideSearchFilter}>
            <select className={styles.optionDrop} value={params.searchDirectorValue} onChange={onChangeDirectorFilter}>
              <option value={''}>Select Director</option>
              {userData?.map((item, index) => (
                <option value={item?.id} key={index}>{item?.name}</option>
              ))}
            </select>

            <select className={styles.optionDrop} value={params.searchProjectTypeValue} onChange={onChangeProjectTypeFilter}>
              <option value={''}>Select Project Type</option>
              <option value={'1'}>Film</option>
              <option value={'2'}>Tv Ads</option>
              <option value={'3'}>Television</option>
            </select>

            <select className={styles.optionDrop} value={params.searchType} onChange={onChangeFilter}>
              <option value={'projectName'}>By Project Name</option>
              <option value={'directorName'}>By Director Name</option>
              <option value={'status'}>By Project Status</option>
            </select>
            <div className={styles.inputIconBox}>
              <input 
                type="text"
                className={styles.searchInput}
                onChange={(e) => searchHandler(e.target.value)}
              />
              <SearchIcon className={styles.iconImg}/>
            </div>
          </div>
        </div>
      </div>

      {projectData?.map((item, index) => {
        return <div className={styles.projectDetailBox} key={index}>
          <div className={styles.imgTextDiv} onClick={() => history.push(`/admin/project-detail/${item?.id}`)}>
            <YellowCircle className={styles.projectImg}/>
            <div className={styles.imgTexts}>
              <p className={styles.imgProTextone}>{item?.project_name}</p>
              <p className={styles.imgProTextTwo}>{item?.director_name}</p>
            </div>
          </div>
          <div className={styles.projectMiddleBox}>
            <div className={styles.upperProjDetail}>
              <div className={styles.accText}>
                <p className={styles.titleHeading}>Account Name</p>
                <p className={styles.accBtmText}>{item?.user?.name ? item?.user?.name : "--"}</p>
              </div>
              <div className={styles.projectTypeHead}>
                <p className={styles.titleHeading}>Project Type</p>
                <p className={styles.projectTypeText}>{item?.projectType?.name}</p>
              </div>
              {item?.projectType?.id === 3 && <div className={styles.episodes}>
              <p className={styles.scenesText}>{item?.episodes?.length} <span>Episodes</span></p>
              </div>}
              <p className={styles.rolesText}>{item?.projectRoles?.length} <span>Roles</span></p>
              <p className={styles.castText}>{item?.projectRoles.reduce((acc, ele) => (acc + ele?.roleOptions?.length), 0)} <span>Cast</span></p>
              <p className={styles.scenesText}>{item?.scenes?.length} <span>Scenes</span></p>
            </div>
            <div className={styles.bottomProjDetail}>
              <div className={styles.shootDate}>
                <p className={styles.shootingHeading}>Shooting Start</p>
                <p className={styles.shootDateText}>{Moment(item?.shooting_start_date).format("MMM DD - YYYY")}</p>
              </div>
              <div className={styles.shootDate}>
                <p className={styles.shootingHeading}>Ends</p>
                <p className={styles.shootDateText}>{Moment(item?.shooting_end_date).format("MMM DD - YYYY")}</p>
              </div>
            </div>
          </div>
          <div className={styles.proStatusBox}>
            <p className={styles.statusLabel}>Status</p><br></br>
            <div className='dropdownSearchAccount'>
            <Dropdown className={`${styles.statusTextDropDown} ${
                item?.project_status === "working-on" ? styles.workingOnStatusColor :
                item?.project_status === "confirmed" ? styles.confirmedStatusColor :
                item?.project_status === "shot" ? styles.shotStatusColor :
                styles.onHoldStatusColor}
              `}>
                <Dropdown.Toggle variant="secondary" size="sm" id="dropdown-basic" data-class={styles.statusDropBox}>
                  {item?.project_status}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item onSelect={() => handleProjectStatus(item?.id, "on-hold")}>On Hold</Dropdown.Item>
                    <Dropdown.Item onSelect={() => handleProjectStatus(item?.id, "shot")}>Shot</Dropdown.Item>
                    <Dropdown.Item onSelect={() => handleProjectStatus(item?.id, "working-on")}>Working On</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
          </div>
        </div>
})}

      { projectData?.length === 0 &&
        <div className={styles.noDataMsg}>
          <NoDataImgCircle className={styles.Noroleimg} />
          <p className={styles.noRoleText}>
            No Project Found
          </p>
        </div>
      }

      {(params.offset !== projectList?.count - 1 && projectListData?.length !== projectList?.count) &&
        <div className={styles.loadMoreButton}>
          <Button variant="warning" onClick={handleLoadMore}>
            Load more
          </Button>
        </div>
      }
    </>
  )
}

export default ProjectsList;