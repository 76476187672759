import React, { useState } from "react";
import styles from "./EpisodeModal.module.scss";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from 'react-bootstrap/Button';
import { saveOrUpdateEpisodeAndSceneDetails } from "../../../services/episodeSceneService";
import { toast } from "react-toastify";
import { useParams } from "react-router";

const AddEpisodeModal = (props) => {
  const location = useParams();
  const type = location.type;
	const [episodeData, setEpisodeData] = useState({
		pId: props.episodeDetail?.pId,
		projectId: props.episodeDetail?.projectId,
		name: props.episodeDetail?.name
	});

	const episodeSceneType = props?.episodeSceneTypeKey || "Episode";
	const saveOrUpdateEpisodes = async () => {
		const result = await saveOrUpdateEpisodeAndSceneDetails(episodeData, type);
		props.hide(false);
		if (result.status) {
			toast.success(type === "episodes" ? "Episode has been saved successfully" : "Scene has been saved successfully");
			return props?.handleCreateSuccessEvent();
		} else {
			toast.error(result.errorMsg);
		}
	};

	return (
		<Modal show={props.show} onHide={props.hide}>
			<Modal.Header closeButton>
				<Modal.Title>{episodeData?.pId === 0 ? `Add ${episodeSceneType}` : `Edit ${episodeSceneType}`}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form.Group>
					<Form.Label>Name :
						<span className={styles.required}> *</span>
					</Form.Label>
					<Form.Control
						type="text"
						name="name"
						placeholder="Name"
						value={episodeData.name}
						onChange={(event) => setEpisodeData({...episodeData, name: event.target.value})}
						required
					/>
				</Form.Group>
				<Button
					className={styles.submitButton}
					variant="primary"
					type="submit"
					disabled={!episodeData.name}
					onClick={saveOrUpdateEpisodes}
				>
					{episodeData?.pId === 0 ? "Create" : "Update"}
				</Button>
			</Modal.Body>
		</Modal>
	)
}

export default AddEpisodeModal;
