import React, { useState, useRef } from "react";
import styles from "./projectsList.module.scss";
import { ReactComponent as MainimgWhiteYell } from "../../assets/icons/mainimgWhiteYell.svg";
import { ReactComponent as LeftHand } from "../../assets/icons/LeftHand.svg";
import { ReactComponent as RightHand } from "../../assets/icons/RightHand.svg";
import { deleteProjectById } from "../../services/projectService";
import PaginationSidebar from "../shared/pagination-sidebar";
import DeleteUserModal from "../deleteUserModal";
import Header from "../shared/header";
import Footer from "../shared/footer";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { removeSelectedProjectFromLocalStorage } from "../../helpers/removeSelectedProject";
import { Spinner } from "react-bootstrap";

const ProjectsListComp = (props) => {
  const data = props?.projectsList;
  const totalCount = props?.projectsCount;
  const limit = props?.limit;
  const showingFrom = props?.offSet + 1;
  const showingTo = showingFrom + data.length - 1;
  const [selectedProject, setSelectedProject] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const myRef = useRef([]);
  const selectedProjectId = localStorage.getItem("selectedProjectId");
  const history = useHistory();
  
  const selectProject = (id, index) => {
    if (data[index]?.selected) {  // already same project is selected just move to next page
      history.push(`/director/project-dashboard/${id}`);
    }

    setSelectedProject(id);
    executeScroll(index);

    data[index].selected = true;

    for (let i = 0; i < data.length; i++) {
      if (data[i].id !== id) {
        data[i].selected = false;
      }
    }
  }

  const executeScroll = (index) => {
		return myRef.current[index]?.scrollIntoView({ behavior: "smooth", block: "end", inline: "start" });
  }

  const handleProject = (id, index) => {
    executeScroll(index);
    selectProject((prev) => prev === id ? 0 : id);
  }

  const handleDeleteModal = () => {
    setShowDeleteModal(true);
  }

  const handleDeleteProject = async (projectId) => {
    const deleteResult = await deleteProjectById(projectId);
    if (deleteResult.status === true) {
      toast.success("Project has been deleted successfully");
      props.getProject({limit, offSet: props?.offSet});
      if (projectId === parseInt(selectedProjectId)) {
        removeSelectedProjectFromLocalStorage();
      };
    } else {
      toast.error("Some error occured while deleting project");
    }
  }

  const handleProjectDoubleClick = async (projectId) => {
    history.push(`/director/project-dashboard/${projectId}`);
  }

  return (
    <>
      <PaginationSidebar 
        data={data}
        isOpenSidebar={props.isOpenSidebar}
        totalCount={totalCount}
        limit={limit}
        offSet = { props?.offSet }
        handlePagination={props.handleProjectPagination}
        selectedProject={selectedProject}
        handleProject={handleProject}
        setIsOpenSidebar={(value) => props.setIsOpenSidebar(value)}
        heading={"My Projects"}
        subHeading={"All Projects"}
        pageType={"projects"}
        subTitleForCount = { "Projects" }
      />
      {props.showSpinner?
        <div className={styles.loaderInSidebar}>
        <Spinner animation="border" variant="primary" className={styles.InnerLoader} />
      </div> :
        <>
      <div className={props.isOpenSidebar === false ? styles.collapseSidebar : styles.expandSidebar}>
        <Header
          heading={"My Projects"}
          subHeading={`You have ${totalCount} projects`}
          isSidebarOpen={props.isOpenSidebar}
        />
        <div className={styles.projrctRep}>
          {data.map((item, index) => (
            <div
              ref={(e) =>  myRef.current[index] = e }
              key={item.id}
              className={`mainSvgContainer ${item.id === selectedProject ? styles.activeMainsec : styles.mainsec}`}
              onClick={() => {selectProject(item.id, index)}}
            >
              <div className={styles.centerimgboc} onDoubleClick={() => handleProjectDoubleClick(item?.id)}>
                <MainimgWhiteYell className={`mainWhiteCricleSvg ${styles.blkWhitehover}`}/>
                {/* {item.id === selectedProject ? <MainimgYellWhite className={styles.yellWhitehover}/> : <MainimgWhiteYell className={styles.blkWhitehover}/>} */}
                <div className={styles.countryEditBox}>
                  <p className={styles.contrytextName}>{ item?.country?.name }</p>
                  <div>
                    <h3 className={styles.centerTxtsec}>{ item.project_name }</h3>
                    <h4 className={styles.centerLableText}>{ item?.director_name }</h4>
                  </div>
                  <div className={styles.EditDeleteBox}>
                    <div className={styles.borderTopLine}></div>
                    <span onClick={(e)=> {
                      e.stopPropagation()
                      history.push(`/director/projects/update/${item?.id}`)
                      }}
                    >
                      Edit
                    </span>
                    <span onClick={(e) => {
                      e.stopPropagation()
                      handleDeleteModal();
                      setSelectedProject(item?.id)
                    }}>
                      Delete
                    </span>
                  </div>
                </div>              
              </div>
            </div>
          ))}    
        </div>
      </div>

      <div className={props.isOpenSidebar === false ? styles.collapseLeftRightHands : styles.leftRightHands}>
        <div className={showingFrom === 1 ? styles.leftDisable : styles.leftHandArrow} onClick={() => props.handleProjectPagination("prev")}>
          <LeftHand className={styles.lefthanfSvg}/>
          <p className={styles.lefthandtext}>Previous Projects</p>
        </div>

        <Footer history={props.history} />

        <div className={(data.length < limit || showingTo === totalCount) ? styles.rightDisable : styles.rightHandarrow} onClick={() => props.handleProjectPagination("next")}>
          <p className={styles.Righthandtext}>More Projects</p>
          <RightHand className={styles.lefthanfSvg}/>
        </div>          
      </div>
      </>}
      <DeleteUserModal
        show={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        id={selectedProject}
        heading={"Delete Project?"}
        subHeading={"Are you sure that you want to delete this project?"}
        handleDelete={handleDeleteProject}
      />
    </>
  )
}

export default ProjectsListComp;