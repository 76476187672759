import {
    GET_FILTER_TYPES,
    GET_FILTER_TYPES_SUCCESS,
    GET_FILTER_TYPES_ERROR
} from './../types'

const Initial_State = {
    list: {
        loaded: false,
        loader: true,
        data: {},
        error: false,
        errorMsg: ''
    },
    
}

export const FilterReducer = (state = Initial_State, action) => {
    switch (action.type) {
        case GET_FILTER_TYPES:
            return { ...state, list: { ...state.list, loader: true, data: {}, error: false, errorMsg: '' } }
        case GET_FILTER_TYPES_SUCCESS:
            return {
                ...state,
                list: {
                    ...state.list,
                    data: action.payload,
                    loading: false,
                    loaded: true
                }
            }
        case GET_FILTER_TYPES_ERROR:
            return { ...state, loading: false, error: action.payload }
        default:
            return state
    }
}