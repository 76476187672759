import {
    SHOW_LOADING,
    SUBSCRIPTIONS_LISTING_SUCCESS,
    SUBSCRIPTIONS_LISTING_ERROR,
    SUBSCRIPTIONS_COUNT_SUCCESS,
    SUBSCRIPTIONS_COUNT_ERROR
} from './../types';

import { SubscriptionsListingApi, subscriptionCountApi } from './api';

const showloading = () => {
    return {
        type: SHOW_LOADING
    }
}

const Error = (error, type) => {
    return {
        type,
        payload: error
    }
}

const Success = (data, type) => {
    return {
        type,
        payload: data.data.data,
    }
}

const countTotal = (data, type) => {
    return {
        type,
        payload: data?.data?.count || 0
    }
}

export const getSubscriptionsListing = (options) => {
    return async (dispatch) => {
        try {
            dispatch(showloading())
            let data = await SubscriptionsListingApi(options)
            dispatch(Success(data, SUBSCRIPTIONS_LISTING_SUCCESS));
            dispatch(countTotal(data, SUBSCRIPTIONS_COUNT_SUCCESS));
        }
        catch (e) {
            Error(e, SUBSCRIPTIONS_LISTING_ERROR)
        }
    }
}

export const getSubscriptionCount = (options) => {
    return async (dispatch) => {
        try {
            dispatch(showloading());
            let data = await subscriptionCountApi();
            dispatch(Success(data, SUBSCRIPTIONS_COUNT_SUCCESS));
        } catch (e) {
            Error(e, SUBSCRIPTIONS_COUNT_ERROR);
        }
    };
};