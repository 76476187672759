import React, { useState } from "react";
import DummUser from "../../../../assets/images/user-dummy.png";
import UserStatusList from "../../../../components/shared/userStatusList";
import Form  from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import moment from "moment";
import styles from "./signupDetails.module.scss";
import { getAge } from "../../../../helpers/commonHelper";
import { updateSpecificUserField } from "../../../../services/userService";
import { nationalityData } from "../../../../constant/nationality-json";

const SignupDetail = ({ data }) => {
	let age = getAge(data.yc_models_model?.birth_date);
  const [notes, setNotes] = useState(data?.yc_models_model?.bio || "");

  const handleNoteChange = (event) => {
    setNotes(event.target.value);
  }

  const saveNotes = () => {
    updateSpecificUserField(data?.id, notes, "bio");
  }
  
	return (
    <React.Fragment>
      <div className={styles.signupDetail}>
        <div className={styles.signupDetailSide}>
          <div className={styles.userImgRow}>
            <div className={styles.userImgCol}>
              <div className={styles.userImgBox}>
                {data?.images?.length > 0 ? <img src={`${process.env.REACT_APP_YOUCAST_USER_IMAGES_PATH}/${process.env.REACT_APP_MODE}/${data.id}/account/thumbnail/th${data?.images[0].title}`} alt="user" /> : <img src={DummUser} alt="user" />}
              </div>
            </div>
          </div>

          <div className={styles.signupDetailRow}>
            <div className={styles.info}>
              <h4 className={styles.label}>Name</h4>
              <p className={styles.labelText}>{data.name}</p>
            </div>

            <div className={styles.info}>
              <h4 className={styles.label}>Email</h4>
              <p className={styles.labelText}>{data.email}</p>
            </div>

            <div className={styles.info}>
              <h4 className={styles.label}>Phone number</h4>
              <p className={styles.labelText}>{data.phone}</p>
            </div>

            <div className={styles.info}>
              <h4 className={styles.label}>Age</h4>
              <p className={styles.labelText}>{age}</p>
            </div>

            <div className={styles.info}>
              <h4 className={styles.label}>Country</h4>
              <p className={styles.labelText}>
                {nationalityData.filter(item => item.code === data?.location_country_iso_code)[0]?.name}
              </p>
            </div>

            <div className={styles.info}>
              <h4 className={styles.label}>Signup date</h4>
              <p className={styles.labelText}>{moment(data?.created_at).format("DD MMM YYYY hh:mm a")}</p>
            </div>

            <div className={styles.info}>
              <h4 className={styles.label}>City</h4>
              <p className={styles.labelText}>{data?.city?.full_city_name}</p>
            </div>

            <div className={styles.info}>
              <h4 className={styles.label}>Status</h4>              
              <UserStatusList userInfo={data} pageType="detailPage" />
            </div>
          </div>
        </div>

        <div className={styles.signupNotesSide}>
          <div className={styles.notesContainer}>
            <Form>
              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label>Notes</Form.Label>
                <Form.Control 
                  as="textarea" 
                  rows={10}
                  value= {notes}
                  onChange= {handleNoteChange}
                  />
              </Form.Group>
              <Button variant="primary" type="button" onClick={saveNotes}>
                Save Notes
              </Button>
              <div className={styles.accountStatus}>
                <h4 className={styles.label}>Yocast Account</h4>
                <p className={styles.labelText}>Activated</p>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default SignupDetail;