import React, { useEffect, useState } from "react";
import styles from "./projectDashboard.module.scss";
import NormalSidebar from "../../../components/director-sidebar/normalSidebar/index";
import Header from "../../../components/shared/header";
import Footer from "../../../components/shared/footer";
import { getProjectDashboardById } from "../../../services/projectService";
import { ReactComponent as MainCirSvg } from "../../../assets/icons/mainCirSvg.svg";
import { ReactComponent as MainCirSvgYell } from "../../../assets/icons/mainimgYellWhite.svg";
import { useParams, useHistory } from "react-router-dom";
import { toast } from "react-toastify";

const ProjectDashboard = (props) => {  
	const location = useParams();
	const history = useHistory();
	const [projectData, setProjectData] = useState();
	localStorage.setItem("selectedProjectName", projectData?.project_name);
	localStorage.setItem("selectedProjectId", location.id);
	const listType = projectData?.project_type_id === 3 ? "episodes" : "scenes";

  const projectDetails = async (projectId) => {
    const result = await getProjectDashboardById(projectId);
    if (!result.data?.status) {
      toast.error(result.msg);
    } else {
      setProjectData(result.data.data);
    }
  };

  useEffect(() => {
    projectDetails(location.id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
	
	return (
		<>
			<div className="normalSidebarcontainer">
				<NormalSidebar  />
			</div>
			<div className={styles.header}> 
				<Header
					heading = { projectData?.project_name }
					subHeading = { "Project Dashboard" }
					isSidebarOpen = { false }
					pageType = { "project-dashboard" }
					data = { projectData }
				/>
			</div>
			<div className={styles.dashboardMainSec}>
				<div className={styles.dashImgTxt} onClick={() => history.push(`/director/project-roles-breakdown/${location.id}`)}>
					<MainCirSvg className={styles.dashPlainImg} />
					<MainCirSvgYell className={styles.dashYellImg} />
					<p className={styles.dashImgInnerTxt}>My <span>Roles</span> </p>
				</div>
					
				<div onClick={() => history.push(`/director/${listType}/lists/${location.id}`)} className={styles.dashImgTxt}>
					<MainCirSvg className={styles.dashPlainImg} />
					<MainCirSvgYell className={styles.dashYellImg} />
					{projectData?.project_type_id > 0 ?
						<p className={styles.dashImgInnerTxt}>My <span>{listType}</span></p> : null
					}
				</div>
			</div>

			<div className="director-footer">
				<Footer history={props.history} />
			</div>
		</>
	);
}

export default ProjectDashboard;
