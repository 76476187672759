import React, { useState } from "react";
import styles from "./AddVideoModal.module.scss";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from 'react-bootstrap/Button';
import { useParams } from "react-router-dom";
import { uploadVideo } from "../../../services/userService"
import { toast } from "react-toastify";
import {uploadUserVideoPath, uploadVideoThumbnail} from "../../../services/s3"

const AddVideoModal = (props) => {
    const location = useParams()
    const [videoFile, setVideoFile] = useState(null)
    const [videoThumbnailFile, setVideoThumbnailFile] = useState(null)
    const currentTime = new Date().getTime()
    const [formValue, setFormValue] = useState({
        user_id: location.id,
        title: '',
        description: '',
        video_thumbnail: '',
        video_url: '',
    });

    const handleChange = (e) => {
            setFormValue({
            ...formValue,
            [e.target.name] : e.target.value,
        });
    };
    
    const changeThumbnail = (event) => {
        let changedName =  currentTime+"."+event.target.files[0].name.split('.')[event.target.files[0].name.split('.').length-1];
        setVideoThumbnailFile(event.target.files[0])
        setFormValue({...formValue, video_thumbnail: changedName})
    }
    const changeVideoUrl = (event) => {
        let changedName =  currentTime+"."+event.target.files[0].name.split('.')[event.target.files[0].name.split('.').length-1];
        setVideoFile(event.target.files[0])
        setFormValue({...formValue, video_url: changedName})
    }
    const handleUploadVideo = async () => {
        props.setShowProgress(true)
        props.setAddVideoModalOpen(false)
        uploadVideoThumbnail("thumbnail/th"+formValue.video_thumbnail, videoThumbnailFile, location.id)
        await uploadUserVideoPath(formValue.video_url, videoFile, location.id, props.setProgress, props.getVideos, props.setShowProgress)
        const data = await uploadVideo(formValue);
        if (!data?.status) {
            toast.error(data.msg);
        }
    };
    
    return (
        <Modal show={props.show} onHide={props.hide}>
            <Modal.Header closeButton>
                <Modal.Title>Add Video</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form
                    className={styles.formClass}
                    onSubmit={async (e) => {
                        e.preventDefault();
                    }}
                >
                    <Form.Group>
                        <Form.Label>Title :
                            <span className={styles.required}> *</span>
                        </Form.Label>
                            <Form.Control
                                type="text"
                                name="title"
                                value={formValue.title}
                                placeholder="Title"
                                onChange={handleChange}
                                required
                            />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Description :</Form.Label>
                            <Form.Control
                                type="text"
                                name="description"
                                value={formValue.description}
                                placeholder="Description"
                                onChange={handleChange}
                            />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            Choose Thumbnail :
                            <span className={styles.required}> *</span>
                        </Form.Label>
                            <Form.Control
                                type="file"
                                name="file"
                                accept="image/*"
                                required
                                onChange={changeThumbnail}
                            />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            Choose Video :
                            <span className={styles.required}> *</span>
                        </Form.Label>
                            <Form.Control
                                type="file"
                                name="file"
                                accept="video/*"
                                required
                                onChange={changeVideoUrl}
                            />
                    </Form.Group>
                    <Button
                        className={styles.submitButton}
                        variant="primary"
                        type="submit"
                        disabled={!formValue.title || !formValue.video_thumbnail || !formValue.video_url}
                        onClick={()=>handleUploadVideo()}
                    >
                        Submit
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default AddVideoModal;
