import React, { useEffect, useRef, useState } from "react";
import styles from "./filterSidebar.module.scss";
import "./ageSlider.custom.scss";
import Form from "react-bootstrap/Form";
import Slider from "@material-ui/core/Slider";
import { ReactComponent as YellowCircle } from "../../../assets/icons/sidelogo.svg";
import { useDispatch, useSelector } from "react-redux";
import { getFiltersList } from "../../../redux/Filters/actions";
import { ReactComponent as ResetIcon } from "../../../assets/icons/reset.svg";
import { useLocation, useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';

const ModelFilters = (props) => {
	const dispatch = useDispatch();
	const [totalCount, setTotalCount] = useState(0);
	const [filters, setFilters] = useState();
	const [selectedFilters, setSelectedFilter] = useState({});
	const [value, setValue] = useState([23, 40]);
	const [sliderValue, setSliderValue] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [selectedCategories, setSelectedCategories] = useState([]);
	const [subCategoriesData, setSubCategoriesData] = useState({});
	const [selectedCategoryName, setSelectedCategoryName] = useState([]);
	const [isInitialRequest, setIsInitialRequest] = useState(true);
	const [selectedValues, setSelectedValues] = useState({});
	const talentState = useSelector((state) => state?.Talent?.data);
	const filterList = useSelector((state) => state?.Filter);
	const dropdownRef = useRef(null);
	const actorCategoryId = 3;
	const location = useLocation();
	const history = useHistory();
	const queryParams = new URLSearchParams(location.search);
	const talentId = queryParams.get("talentId");

	const toggleDropdown = () => {
		setIsOpen(!isOpen);
	};

	const useStyles = makeStyles((theme) => ({
		customSlider: {
			// Your custom CSS styles go here
			color: 'white !important',
		},
		customValueLabel: {
			// Your custom CSS styles for the value label go here
			color: 'white',
		},
		customValueText: {
			// Your custom CSS styles for the text inside the value label go here
			color:'black'
		},
	}));
	const classes = useStyles();

	const handleCheckboxChange = (event) => {
		const { value, checked } = event.target;
		const checkedValue = Number(value);
		const categorySelectedName = filters.categories.filter((item) => {
			return item?.id === checkedValue
		})[0].name
		if (checked) {
			setSelectedCategories([...selectedCategories, checkedValue]);
			updateSubCategoriesData("0", checkedValue, false, false);
			setSelectedCategoryName([...selectedCategoryName, categorySelectedName]);
		} else {
			updateSubCategoriesData("0", checkedValue, true, true);
			setSelectedCategories(selectedCategories.filter(option => option !== checkedValue));			
			setSelectedCategoryName(selectedCategoryName.filter(selectedName => selectedName !== categorySelectedName))
		}
	};

	const updateSubCategoriesData = (value, parentId, hitRequest, deleteKey) => {
		let subCatData = { ...subCategoriesData };
		if (deleteKey) {
			delete subCatData[parentId];
		} else {
			subCatData[parentId] = parseInt(value);
		}
		
		setSubCategoriesData(subCatData);

		if (hitRequest) {
			const hitPropsRequest = !(hitRequest && deleteKey);
			const tempSubCategoryArray = Object.values(subCatData).filter((item) => item !== 0);
			setFilterHandler("subCategory", tempSubCategoryArray.join(',').replace(/^,/, ""), hitPropsRequest);
		}
	}

	const handleSelectedSubCategory = (value, parentId) => {
		updateSubCategoriesData(value, parentId, true, false);
	};
	
	useEffect(() => {
		if (!isInitialRequest) {  // not hit this request on page load
			setFilterHandler("category", selectedCategories);
		}
		setIsInitialRequest(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedCategories]);

	const handleClickOutside = (event) => {
		if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
			setIsOpen(false);
		}
	};

	useEffect(() => {
		window.addEventListener('click', handleClickOutside);
		return () => {
			window.removeEventListener('click', handleClickOutside);
		};
	}, []);

	useEffect(() => {
		dispatch(getFiltersList());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {		
		if (filterList?.list?.loaded) {
      setFilters(filterList?.list?.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterList]);

	useEffect(() => {
		setTotalCount(talentState?.talentCount);
	}, [talentState]);
	
	const setFilterHandler = (type, value, hitProps = true) => {
		if (talentId) {
			queryParams.delete("talentId");
			history.replace({
			search: queryParams.toString(),
			});
		}

		let finalValue = value;
		if (type === "category") {
			finalValue = value.join(',');
		}

		let filterState = { ...selectedFilters };
		filterState[type] = finalValue;
		if (finalValue === "0") {			
			delete filterState[type];			
		}

		if (type === "category" && !value.includes(actorCategoryId)) {
			delete filterState["acting"];
		}

		setSelectedFilter(filterState);
		if (hitProps) {
			props.onFilterChange(filterState);
		}
	}

	const handleSliderChange = (event, newValue) => {
		setSliderValue(true);
		setValue(newValue);
	}

	useEffect(() => {
    if (sliderValue) {
      const callApi = setTimeout(() => {
				setFilterHandler('age', value);
			}, 700);
      return () => clearTimeout(callApi);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

	const valuetext = (value) => {
    return value;
  }

	/**
	* This function is for when we filter some talent and
	* after clicking on cast we back to the talent filter page then
	* in that case we have to retain that selected filters.
	*/ 
	const updateSelectedValues = (fieldName, newValue) => {
    setSelectedValues({
      ...selectedValues,
      [fieldName]: newValue,
    });
  };

	return (
    props.showFilterType === "modalFilter" && 
		<div className={styles.profilePgSidebar}>
			<div className={styles.sideImglogo}>
				<div className={styles.sideImgbox}>
					<YellowCircle  className={styles.sideimgsvgicon} />
					<p className={styles.sideimgsvgtxt}>Youcast</p>
				</div>
				<div className={styles.sideBarlogoTxt}>
					<h4 className={styles.sidelgtxt}>Smart Filter</h4>
					<p className={styles.sidelgsubtxt}>Filter Talents By</p>
				</div>
			</div>
			
			<div className={styles.filterDwnBox}>
				<div className={styles.filtertitle}>
					<span className={styles.fliterLabel}>Category </span>
				</div>

				<div className={styles.iconMainDiv}>
					<div className={styles.dropDown} ref={dropdownRef}>
						<button className={styles.dropDownToggle} type="button" onClick={toggleDropdown}>
							<div className={styles.multipleCategorySelectedName}>
								{selectedCategoryName?.length > 0 && selectedFilters?.category ? (
									selectedCategoryName.map((item, index) => (
										<span key={item}>{item}{index !== selectedCategoryName.length - 1 && ', '}</span>
									))
								) : "Select"}
							</div>
						</button>
						{isOpen && filters && (
							<div className={styles.dropDownMenu}>
								{filters?.categories?.map((item, index) => {
									return item.parent_category_id === null &&
										<label htmlFor={index} key={index}>
											<input
												type="checkbox"
												id={index}
												value={item.id}
												checked={selectedCategories.includes(item.id)}
												onChange={handleCheckboxChange}
											/>
											{item.name}
										</label>
								})}
							</div>
						)}
					</div>
					<div className={styles.iconReset} onClick={() => {
						setSelectedCategories([]);
						setSelectedCategoryName([]);
						setFilterHandler("subCategory", "0");
					}}>
						<ResetIcon />
					</div>

				</div>

				{selectedCategories.length > 0 &&
					selectedCategories.map((item, index) => {
						return item !== actorCategoryId && <div key={index}>
							<div className={styles.filtertitle}>
								<span className={styles.fliterLabel}>{filters.categories.filter((category) => category.id === item)[0].name} Type</span>
							</div>
						<div className={styles.iconMainDiv}>
							{filters &&
								<Form.Control
									as="select"
									name={selectedCategoryName[index]}
									className={`${styles.inputfilter} selectOpt`}
									onChange={(event) => {
										updateSelectedValues(event.target.name, parseInt(event.target.value));
										handleSelectedSubCategory(event.target.value, item);
									}}
									defaultValue={selectedValues[selectedCategoryName[index]]}
								>
									<option value={0} selected={selectedFilters?.subCategory ? false :  true}>Select</option>
									{filters.categories?.map((element, index) => {
										return element.parent_category_id === item && element.sub_section === null &&
											<option key={index} value={element.id} >{element.name}</option>
									})}
								</Form.Control>
							}
							<div className={styles.iconReset} onClick={(event) => {
								setFilterHandler("subCategory", "0")
							}}>
								<ResetIcon />
							</div>
						</div>
						</div>
					})
				}

				{selectedCategories.includes(actorCategoryId) &&
					<>
						<div className={styles.filtertitle}>
							<span className={styles.fliterLabel}>Shooting Type</span>
						</div>
						<div className={styles.iconMainDiv}>
						{filters &&
							<Form.Control
								as="select"
								className={`${styles.inputfilter} selectOpt`}
								onChange={(event) => {
									updateSelectedValues("shootingType",parseInt(event.target.value));
									handleSelectedSubCategory(event.target.value, actorCategoryId)
								}}
								defaultValue={selectedValues.shootingType}
							>
								<option value={0} selected={selectedFilters?.subCategory ? false :  true}>Select</option>
								{filters.categories?.map((item, index) => {
									return item.parent_category_id === actorCategoryId && item.sub_section === "Shooting" &&
										<option key={index} value={item.id} >{item.name}</option>
								})}
							</Form.Control>
						}
						<div className={styles.iconReset} onClick={(event) => {
								setFilterHandler("subCategory", "0")
							}}>
								<ResetIcon />
							</div>
						</div>

						<div className={styles.filtertitle}>
							<span className={styles.fliterLabel}>Acting Type</span>
						</div>
						<div className={styles.iconMainDiv}>
						{filters &&
							<Form.Control
								as="select"
								className={`${styles.inputfilter} selectOpt`}
								onChange={(event) => {
									updateSelectedValues("acting",parseInt(event.target.value));
									setFilterHandler("acting", event.target.value);}
								}
								defaultValue={selectedValues.acting}
							>
								<option value={0} selected={selectedFilters?.acting ? false :  true}>Select</option>
								{filters.categories?.map((item, index) => {
									return item.parent_category_id === actorCategoryId && item.sub_section === "Acting" &&
										<option key={index} value={item.id} >{item.name}</option>
								})}
							</Form.Control>
						}
					<div className={styles.iconReset} onClick={(event) => {
								setFilterHandler("acting", "0")
							}}>
								<ResetIcon />
							</div>
						</div>
					</>
				}

				<div className={styles.filtertitle}>
					<span className={styles.fliterLabel}>Gender</span>
				</div>
				<div className={styles.iconMainDiv}>
				{filters &&
					<Form.Control
						as = "select"
						className={`${styles.inputfilter} selectOpt`}
						onChange={(event) => {
							updateSelectedValues("gender",parseInt(event.target.value));
							setFilterHandler("gender", event.target.value)}
						}
						defaultValue={selectedValues.gender}
					>
						<option value={0} selected={selectedFilters?.gender ? false :  true}>Select</option>
						{filters.gender?.map((item, index) => (
							<option key={index} value={item.id}>{item.title}</option>
						))}
					</Form.Control>
				}
				<div className={styles.iconReset} onClick={(event) => {
						setFilterHandler("gender", "0")
					}}>
						<ResetIcon />
					</div>
				</div>
				
				<div className={styles.filtertitle}>
				<span className={styles.fliterLabel}>Age</span>
					<div className={styles.iconMainDiv}>
					<Slider
						value={value}
            onChange={handleSliderChange}
            valueLabelDisplay="auto"
            aria-labelledby="range-slider"
            getAriaValueText={valuetext}
            type="range"
						classes={{
							root: classes.customSlider, // Apply custom styles to the slider
							valueLabel: classes.customValueLabel, // Apply custom styles to the value label
						}}
						valueLabelFormat={(value) => (
							<span className={classes.customValueText}>{valuetext(value)}</span> // Apply custom styles to the tex value inside slider
						)}			
					/>
					<div className={styles.iconReset} onClick={() => {
						setValue([23, 40]);
					}}>
							<ResetIcon />
						</div>
					</div>
				</div>
				
				<div className={styles.filtertitle}>
					<span className={styles.fliterLabel}>Physical Details</span>
				</div>
				
					<div className={styles.iconMainDiv}>
				<div className={styles.physicalDetailBox}>
					<p className={styles.phsyBoxTxt}>Skin Tone</p>
					{filters && 
						<Form.Control
							as = "select"
							className={`${styles.inputDetailfilter} selectOpt`}
							onChange={(event) => { 
								updateSelectedValues("skinTone", parseInt(event.target.value));
								setFilterHandler("skintone", event.target.value)}
							}
							defaultValue={selectedValues.skinTone}
						>
							<option value={0} selected={selectedFilters?.skintone ? false :  true}>Select</option>
							{filters.skinTone?.map((item, index) => (
								<option key={index} value={item.id}>{item.title}</option>
							))}
						</Form.Control>
					}
				<div className={styles.iconReset} onClick={(event) => {
							setFilterHandler("skintone", "0")
						}}>
							<ResetIcon />
						</div>
					</div>
				</div>
				
					<div className={styles.iconMainDiv}>
				<div className={styles.physicalDetailBox}>
					<p className={styles.phsyBoxTxt}>Eyes Color</p>
					{filters && 
						<Form.Control
							as = "select"
							className={`${styles.inputDetailfilter} selectOpt`}
							onChange={(event) => {
								updateSelectedValues("eyeColor",parseInt(event.target.value));
								setFilterHandler("eyecolor", event.target.value)}
							}
							defaultValue={selectedValues.eyeColor}
						>
							<option value={0} selected={selectedFilters?.eyecolor ? false :  true}>Select</option>
							{filters.eye_color?.map((item, index) => (
								<option key={index} value={item.id}>{item.title}</option>
							))}
						</Form.Control>
					}
						<div className={styles.iconReset} onClick={(event) => {
							setFilterHandler("eyecolor", "0")
						}}>
							<ResetIcon />
						</div>
					</div>
				</div>
					
					<div className={styles.iconMainDiv}>
				<div className={styles.physicalDetailBox}>
					<p className={styles.phsyBoxTxt}>Height</p>
					{filters && 
						<Form.Control
							as = "select"
							className={`${styles.inputDetailfilter} selectOpt`}
							onChange={(event) => {
								updateSelectedValues("height",parseInt(event.target.value));
								setFilterHandler("height", event.target.value)
							}
						}
						defaultValue={selectedValues.height}
						>
							<option value={0} selected={selectedFilters?.height ? false :  true}>Select</option>
							{filters.height?.map((item, index) => (
								<option key={index} value={item.id}>{item.title}</option>
							))}
						</Form.Control>
					}
					<div className={styles.iconReset} onClick={(event) => {
							setFilterHandler("height", "0")
						}}>
							<ResetIcon />
						</div>
					</div>
				</div>
					
					<div className={styles.iconMainDiv}>
				<div className={styles.physicalDetailBox}>
					<p className={styles.phsyBoxTxt}>Weight</p>
					{filters && 
						<Form.Control
							as = "select"
							className={`${styles.inputDetailfilter} selectOpt`}
							onChange={(event) => {
								updateSelectedValues("weight",parseInt(event.target.value));
								setFilterHandler("weight", event.target.value)}
							}
							defaultValue={selectedValues.weight}
						>
							<option value={0} selected={selectedFilters?.weight ? false :  true}>Select</option>
							{filters.weight?.map((item, index) => (
								<option key={index} value={item.id}>{item.title}</option>
							))}
						</Form.Control>
					}
				<div className={styles.iconReset} onClick={(event) => {
							setFilterHandler("weight", "0")
						}}>
							<ResetIcon />
						</div>
					</div>
				</div>
					
					<div className={styles.iconMainDiv}>
				<div className={styles.physicalDetailBox}>
					<p className={styles.phsyBoxTxt}>Hair Color</p>
					{filters && 
						<Form.Control
							as = "select"
							className={`${styles.inputDetailfilter} selectOpt`}
							onChange={(event) => {
								updateSelectedValues("hairColor",parseInt(event.target.value));
								setFilterHandler("haircolor", event.target.value)}
							}
							defaultValue={selectedValues.hairColor}
						>
							<option value={0} selected={selectedFilters?.haircolor ? false :  true}>Select</option>
							{filters.hair_color?.map((item, index) => (
								<option key={index} value={item.id}>{item.title}</option>
							))}
						</Form.Control>
					}
				<div className={styles.iconReset} onClick={(event) => {
							setFilterHandler("haircolor", "0")
						}}>
							<ResetIcon />
						</div>
					</div>
				</div>
					
					<div className={styles.iconMainDiv}>
				<div className={styles.physicalDetailBox}>
					<p className={styles.phsyBoxTxt}>Hair Type</p>
					{filters && 
						<Form.Control
							as = "select"
							className={`${styles.inputDetailfilter} selectOpt`}
							onChange={(event) => {
								updateSelectedValues("hairType",parseInt(event.target.value));
								setFilterHandler("hairtype", event.target.value)}
							}
							defaultValue={selectedValues.hairType}
						>
							<option value={0} selected={selectedFilters?.hairtype ? false :  true}>Select</option>
							{filters.hair_type?.map((item, index) => (
								<option key={index} value={item.id}>{item.title}</option>
							))}
						</Form.Control>
					}
					<div className={styles.iconReset} onClick={(event) => {
							setFilterHandler("hairtype", "0")
						}}>
							<ResetIcon />
						</div>
					</div>
				</div>
					
					<div className={styles.iconMainDiv}>
				<div className={styles.physicalDetailBox}>
					<p className={styles.phsyBoxTxt}>Body Shape</p>
					{filters && 
						<Form.Control
							as = "select"
							className={`${styles.inputDetailfilter} selectOpt`}
							onChange={(event) => {
								updateSelectedValues("bodyShape",parseInt(event.target.value));
								setFilterHandler("bodyshape", event.target.value)}
							}
							defaultValue={selectedValues.bodyShape}
						>
							<option value={0} selected={selectedFilters?.bodyshape ? false :  true}>Select</option>
							{filters.body_shape?.map((item, index) => (
								<option key={index} value={item.id}>{item.title}</option>
							))}
						</Form.Control>
					}
					<div className={styles.iconReset} onClick={(event) => {
							setFilterHandler("bodyshape", "0")
						}}>
							<ResetIcon />
						</div>
					</div>
				</div>

					<div className={styles.iconMainDiv}>
        <div className={styles.physicalDetailBox}>
					<p className={styles.phsyBoxTxt}>Social Status</p>
					{filters && 
						<Form.Control
							as = "select"
							className={`${styles.inputDetailfilter} selectOpt`}
							onChange={(event) => {
								updateSelectedValues("socialStatus",parseInt(event.target.value));
								setFilterHandler("socialStatus", event.target.value)}
							}
							defaultValue={selectedValues.socialStatus}
						>
							<option value={0} selected={selectedFilters?.socialStatus ? false :  true}>Select</option>
							{filters.social_status?.map((item, index) => (
								<option key={index} value={item.id}>{item.name}</option>
							))}
						</Form.Control>
					}
					<div className={styles.iconReset} onClick={(event) => {
							setFilterHandler("socialStatus", "0")
						}}>
							<ResetIcon />
						</div>
					</div>
				</div>

					<div className={styles.iconMainDiv}>
				<div className={styles.physicalDetailBox}>
					<p className={styles.phsyBoxTxt}>Special Talent</p>
					{filters && 
						<Form.Control
							as = "select"
							className={`${styles.inputDetailfilter} selectOpt`}
							onChange={(event) => {
								updateSelectedValues("specialTalent",parseInt(event.target.value));
								setFilterHandler("specialTalent", event.target.value)}
							}
							defaultValue={selectedValues.specialTalent}
						>
							<option value={0} selected={selectedFilters?.specialTalent ? false :  true}>Select</option>
							{filters.special_talents?.map((item, index) => (
								<option key={index} value={item.id}>{item.name}</option>
							))}
						</Form.Control>
					}
					<div className={styles.iconReset} onClick={(event) => {
							setFilterHandler("specialTalent", "0")
						}}>
							<ResetIcon />
						</div>
					</div>
				</div>
			</div>
			
			<div className={styles.filtershowing}>
				<p className={styles.filtershwtext}>
					<span>Your filter showing</span>
					<span className={styles.filtershwtextspan}>{totalCount} Results</span>
				</p>
			</div>			
    </div>
	);
}

export default ModelFilters;
