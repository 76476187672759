import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { debounce } from "lodash";
import Dropdown from "react-bootstrap/Dropdown";
import "./subscriptions.scss";
import SearchInput from "../../../components/searchInput/searchInput";
import UserList from "../../../components/userlist/userlist";
import { getSubscriptionsListing } from "./../../../redux/Subscriptions/actions";
import { deleteSubscriptionsApi } from "../../../redux/Subscriptions/api"
import { toast } from "react-toastify";

class Subscription extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      limit: 10,
      offSet: 0,
      SubscriptionList: [],
      searchedValue: "",
      subSearchTypes: "email"
    };
  }

  componentDidMount() {
    let params = this.getParams();
    this.props.getSubscriptionsListing(params);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.SubscriptionList !== prevProps.SubscriptionList) {
      this.setState({
        ...this.state,
        SubscriptionList: [
          ...this.state.SubscriptionList,
          ...this.props.SubscriptionList,
        ],
      });
    }

    if (prevState.offSet !== this.state.offSet) {
      let params = this.getParams();
      this.props.getSubscriptionsListing(params);
    }
  }

  getParams = () => {
    let params = {
      limit: this.state.limit,
      offset: this.state.offSet,
      paginate: true,
      subSearchTypes: this.state.subSearchTypes
    };

    if (this.state.searchedValue !== "") {
      params["search"] = this.state.searchedValue;
    }
    return params;
  };

  handleLoadMore = () => {
    this.setState({
      ...this.state,
      offSet: this.state.offSet + 10 
    });
  };

  searchInput = (value) => {
    this.setState({
      SubscriptionList: [],
      limit: 10,
      offSet: 0,
      searchedValue: value
    });

    let params = this.getParams();
    this.props.getSubscriptionsListing(params);
  }

  searchHandler = debounce((value) => {
    this.searchInput(value);
  }, 1000);

  handleDeleteSubscriptions = async (id) => {
    try {
      let response = await deleteSubscriptionsApi(id);
      if (response?.status) {
        toast.success("Subscription has been deleted successfully.");
      }
      this.setState({
        SubscriptionList: this.state.SubscriptionList.filter(x => x.id !== id)
      });
      let params = this.getParams();
      this.props.getSubscriptionsListing(params);
    } catch(error) {
      toast.error("Some error occured");
    }
  }

  render() {
    return (
      <div className="">
        <div className="userTopRow">
          <div className="userCount">
            <span>Subscriptions</span> <span>{this.props?.SubscriptionCount}</span>
          </div>
          <div className="btnsCol">
            <div className='dropdownSearchAccount defaultDropdown'>
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic"> {this.state.subSearchTypes}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onSelect={() => this.setState({
                    subSearchTypes: 'email'
                  })}>by email</Dropdown.Item>
                  <Dropdown.Item onSelect={() => this.setState({
                    subSearchTypes: 'name'
                  })}>by name</Dropdown.Item>
                  <Dropdown.Item onSelect={() => this.setState({
                    subSearchTypes: 'phone'
                  })}>by phone</Dropdown.Item>
                  <Dropdown.Item onSelect={() => this.setState({
                    subSearchTypes: 'status'
                  })}>by status</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="userSearch">
              <SearchInput searchHandler={this.searchHandler} searchInput={this.searchInput}/>
            </div>
          </div>
        </div>

        <div>
          <UserList
            data={this.state.SubscriptionList}
            totalCount={this.props?.SubscriptionCount}
            type={"subscriptions"}
            loadMore={this.handleLoadMore}
            handleDeleteSubscriptions={this.handleDeleteSubscriptions}
          />
          {!this.props.SubscriptionList.length &&
            <div className="d-flex justify-content-center p-5">
              <small style={{ color: "white", fontSize: "20px" }}>
                No records found
              </small>
            </div>
          }
        </div>
      </div>
    );
  }
}

const MapStatetoProps = (state) => {
  return {
    SubscriptionList: state.Subscriptions.data.SubscriptionsList,
    SubscriptionCount: state.Subscriptions.data.SubscriptionCount
  };
};

export default connect(MapStatetoProps, { getSubscriptionsListing })(
  Subscription
);
