import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Photos from "../PhotoSection";
import Videos from "../VideoSection";
import PhysicalDetail from "../physicalDetail"

const AccountDetail = (props) => {
  
  return (
    <div>
        { props?.displayState === 'photos' ? <Photos accountDetail={props?.dataAccount} /> : '' }

        { props?.displayState === 'videos' ? <Videos accountDetail={props?.dataAccount}/> : '' }

        { props?.displayState === 'physicalDetail' ? <PhysicalDetail accountDetail={props?.dataAccount} /> : ''}
    </div>
  );
};

export default AccountDetail;
