import React, {useState, useEffect} from 'react';
import styles from "./create-edit-project.module.scss";
import { ReactComponent as YellowCircle } from '../../assets/icons/yellowcircle.svg';
import { ReactComponent as MainCirSvg } from '../../assets/icons/mainCirSvg.svg';
import { ReactComponent as MainCirSvgYell } from '../../assets/icons/mainimgYellWhite.svg';
import { createProject } from '../../services/projectService';
import {useHistory, useParams} from "react-router-dom";
import { getProjectById, updateProjectByTd } from '../../services/projectService';
import { toast } from "react-toastify";
import Form from "react-bootstrap/Form"; 
import Col from "react-bootstrap/Col";
import {monthsList} from "../../constant/index"

const CreateNewProject = (props) => {
  const date = new Date();
  const navigate = useHistory();
  const location = useParams();
  const projectId = location.id
  const [data, setData] = useState();
  const [validated, setValidated] = useState(false);
  const userId = JSON.parse(localStorage.getItem('user')).id;

  const projectDetails = async (projectId) => {
    const result  = await getProjectById(projectId, "edit");
    if (!result.data?.status) {
      toast.error(result.msg);
    } else {
      setData(result.data.data);
    }
  }

  useEffect(() => {
    if (projectId !== undefined) {
      projectDetails(projectId);
    }
  }, [projectId]);

  const [startDate, setStartDate] = useState({
    month: new Date().getMonth()+1,
    year: date.getFullYear(),
    day: new Date().getDate()
  });

  const [endDate, setEndDate] = useState({
    month: new Date().getMonth()+1,
    year: date.getFullYear(),
    day: new Date().getDate()
  });
  
  const [formValue, setFormValue] = useState({
    userId: userId,
    countryCode: "",
    projectName: "", 
    shootingStartDate: startDate.year + "-" + startDate.month + "-" + startDate.day,
    shootingEndDate: endDate.year + "-" + endDate.month + "-" + endDate.day,
    producerName: "",
    producerPhoneNumber: "",
    projectTypeId: 0,
    projectStatus: "working-on",
    directorName: "",
    firstAssistantDirectorName: "",
    firstAssistantDirectorPhoneNumber: "",
    secondAssistantDirectorName: "",
    secondAssistantDirectorPhoneNumber: ""
  });
 
  useEffect(() => {
    if (data) {
      setStartDate({
        month: data?.shooting_start_date.split("-")[1],
        year: parseInt(data?.shooting_start_date.split("-")[0]),
        day: data?.shooting_start_date.split("-")[2],
      });

      setEndDate({
        month: data?.shooting_end_date.split("-")[1],
        year: parseInt(data?.shooting_end_date.split("-")[0]),
        day: data?.shooting_end_date.split("-")[2],
      });

      setFormValue({
        userId: userId,
        countryCode: data?.country_code,
        projectName: data?.project_name,
        producerName: data?.producer_name,
        shootingStartDate:
          parseInt(data?.shooting_start_date.split("-")[0]) +
          "-" +
          data?.shooting_start_date.split("-")[1] +
          "-" +
          data?.shooting_start_date.split("-")[2],

        shootingEndDate:
          parseInt(data?.shooting_end_date.split("-")[0]) +
          "-" +
          data?.shooting_end_date.split("-")[1] +
          "-" +
          data?.shooting_end_date.split("-")[2],
        producerPhoneNumber: data?.producer_phone_number,
        projectTypeId: data?.project_type_id,
        projectStatus: data?.project_status,
        firstAssistantDirectorName: data?.assistant[0]?.name,
        directorName: data?.director_name,
        firstAssistantDirectorPhoneNumber: data?.assistant[0]?.phone_number,
        secondAssistantDirectorName: data?.assistant[1]?.name,
        secondAssistantDirectorPhoneNumber: data?.assistant[1]?.phone_number,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const createNewProject = async () => {
    const result = await createProject(formValue);
    if (!result?.status) {
      toast.error(result.errorMsg);
    } else {
      toast.success("Project has been created Successfully");
      navigate.push("/director/projects");
    }
  };

  const updateProject = async (projectId) => {
    const result = await updateProjectByTd(projectId, formValue);
    if (!result?.status) {
      toast.error(result.errorMsg);
    } else {
      toast.success("Project has been updated Successfully");
      navigate.push("/director/projects");
    };
  };

  const projectStartDate = new Date(formValue.shootingStartDate);
  const projectEndDate = new Date(formValue.shootingEndDate);
  
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      if (projectEndDate < projectStartDate) {
        toast.error("End date should be greater than start date");
        return;
      }
      if (projectId !== undefined) {
        updateProject(projectId);
      } else {
        createNewProject();
      }
    }
    setValidated(true);
  };

  function years(count, startYear) {
    const yearList = [];
    const year = startYear || new Date().getFullYear();
    for (let i = 0; i < count; i += 1) {
      yearList.push(Number(year) - i);
    }
    return yearList.sort((a, b) => a - b);
  }

  const getDaysArray = function(year, month) {
    let monthIndex = month - 1; 
    let date = new Date(year, monthIndex, 1);
    let result = [];
    while (date.getMonth() === monthIndex) {
      result.push(date.getDate());
      date.setDate(date.getDate() + 1);
    }
    return result;
  }

  const handleInputField = (event) => {
    setFormValue({...formValue, [event.target.name]: event.target.value})
  }

  return (
    <div className={styles.createProPage}>
      <Form noValidate validated={validated} onSubmit={handleSubmit} className={styles.validateForm}>
        <div className={styles.formContainerSec}>
        <div className={styles.formBoxOne}>
          <div className={styles.formWithLabel}>
            <YellowCircle className={styles.smallyellowIcon} />
            <h4 className={styles.labeltext}>Country</h4>
          </div>
          <div className={styles.formInputs}>
                
            <Form.Group as={Col} className={styles.colFormcalss} controlId="validationCustom01">
              <Form.Control
                as = 'select'
                required
                name='countryCode'
                className="selectOpt"
                value={formValue.countryCode}
                onChange = {handleInputField}
              >
                  <option value="">Select Country</option>
                  {props.countries.map((item) => {
                    return <option key={item.iso} value={item.iso}>{item.printable_name}</option>
                  })} 
             </Form.Control> 
             <Form.Control.Feedback type="invalid" className={styles.errorFeedback}>
                Please select a Country Name.
              </Form.Control.Feedback>
            </Form.Group>     
          </div>

          <div className={styles.formWithLabel}>
            <YellowCircle className={styles.smallyellowIcon} />
            <h4 className={styles.labeltext}>Project Name</h4>
          </div>
          <div className={styles.formInputs}>
                   
            <Form.Group as={Col} className={styles.colFormcalss} controlId="validationCustom02">
              <Form.Control
                type='text'
                placeholder='Name your project'
                required
                name="projectName"
                value = {formValue.projectName}
                className={styles.inputfieldDes}
                onChange={handleInputField}
              />
              <Form.Control.Feedback type="invalid" className={styles.errorFeedback}>
                  Please enter project name
              </Form.Control.Feedback>
            </Form.Group>            
          </div>

          <div className={styles.formWithLabel}>
            <YellowCircle className={styles.smallyellowIcon} />
            <h4 className={styles.labeltext}>Director Name</h4>
          </div>
          <div className={styles.formInputs}>
          <Form.Group as={Col} className={styles.colFormcalss} controlId="validationCustom03">
            <Form.Control
            type='text'
            placeholder='Project Director'
            required
            name='directorName'
            value={formValue.directorName}
            className={styles.inputfieldDes}
            onChange={handleInputField}
            />
            <Form.Control.Feedback type="invalid" className={styles.errorFeedback}>
              Please enter director name
            </Form.Control.Feedback>
          </Form.Group>            
          </div>

          <div className={styles.formWithLabel}>
            <YellowCircle className={styles.smallyellowIcon} />
            <h4 className={styles.labeltext}>Shooting Start Date</h4>
          </div>
          <div className={styles.ThreeInputBox}>
            <div className={styles.inputInnerBox}>
              <p className={styles.inputparaahd}>Month</p>
              <Form.Control
              as = 'select'
              placeholder='December'
              defaultValue={(startDate.month).toLocaleString("en-US", {
                minimumIntegerDigits: 2,
              })} 
              onChange={(event) => {
                setStartDate({...startDate, month: event.target.value})
                setFormValue({...formValue, shootingStartDate: startDate.year + "-" + event.target.value + "-" + startDate.day})
              }}
              className="selectOpt"
              >
              {monthsList.map((item, index) => (
                <option key={index} value={ (index + 1).toLocaleString('en-US', {minimumIntegerDigits: 2}) } selected={ (index + 1).toLocaleString('en-US', {minimumIntegerDigits: 2}) === data?.shooting_start_date.split('-')[1]
              }>{item}</option>
              ))}
              </Form.Control>  
            </div>
            <div className={styles.inputInnerSMBox}>
              <p className={styles.inputparaahd}>Day</p>
              <Form.Control
              as = 'select'
              placeholder='December'
              defaultValue= {startDate.day}
              className="selectOpt"
              onChange={(event) => {
                setStartDate({...startDate, day: event.target.value})
                setFormValue({...formValue, shootingStartDate: startDate.year + "-" + startDate.month + "-" + event.target.value})
              }}
              >
                {getDaysArray(startDate.year, startDate.month).map((item) => (
                  <option key={item} value={ (item).toLocaleString('en-US', {minimumIntegerDigits: 2}) } selected={item === parseInt(data?.shooting_start_date.split('-')[2])
                } >{(item).toLocaleString('en-US', {minimumIntegerDigits: 2})}</option>
                ))}
              </Form.Control>  
            </div>  
            <div className={styles.inputInnerSMBox}>
              <p className={styles.inputparaahd}>Year</p>
              <Form.Control
              as = 'select'
              placeholder='December'
              defaultValue={new Date().getFullYear()}
              onChange={(event) => {
                setStartDate({...startDate, year: event.target.value})
                setFormValue({...formValue, shootingStartDate: event.target.value + "-" + startDate.month + "-" + startDate.day})
                }
              }
              className="selectOpt"
              >
                {years(date.getFullYear() - 2010, date.getFullYear() + 7).map((item) => (
                  <option key={item} selected={item === parseInt(data?.shooting_start_date.split('-')[0])
                  } >{item}</option>
                )
                )}
              </Form.Control>
            </div>   
          </div>
        </div>

        <div className={styles.formBoxtwo}>
          <div className={styles.formWithLabel}>
            <YellowCircle className={styles.smallyellowIcon} />
            <h4 className={styles.labeltext}>1st Assistant Director</h4>
          </div>
          <div className={styles.formInputs}>
          	<Form.Group as={Col} className={styles.colFormcalss} controlId="validationCustom04">
            	<Form.Control
              type='text'
              placeholder='Assistant Name'
              className={styles.inputfieldDes}
              name = "firstAssistantDirectorName"
              value = {formValue.firstAssistantDirectorName}
              required
              onChange={handleInputField}
            	/> 
              <Form.Control.Feedback type="invalid" className={styles.errorFeedback}>
                Please enter assistant director name
            	</Form.Control.Feedback>
						</Form.Group> 
						<Form.Group as={Col} className={styles.colFormcalss}> 
            	<Form.Control
              	type='text'
              	placeholder='Phone Number'
              	className={styles.inputfieldDes}
              	required
                minLength={10}
                value={formValue.firstAssistantDirectorPhoneNumber}
                name = "firstAssistantDirectorPhoneNumber"
                onChange={handleInputField}
            	/> 
							 <Form.Control.Feedback type="invalid" className={styles.errorFeedback}>
                  Please enter valid phone number
            		</Form.Control.Feedback>
						</Form.Group>           
          </div>

          <div className={styles.formWithLabel}>
            <YellowCircle className={styles.smallyellowIcon} />
            <h4 className={styles.labeltext}>2nd Assistant Director</h4>
          </div>
          <div className={styles.formInputs}>
          	<Form.Group as={Col} controlId="validationCustom05"  className={styles.colFormcalss}>
            	<Form.Control
              	type='text'
              	placeholder='Assistant Name'
              	className={styles.inputfieldDes}
                name="secondAssistantDirectorName"
                value={formValue.secondAssistantDirectorName}
                onChange={handleInputField}
            	/>   
            </Form.Group>
            <Form.Group as={Col} className={styles.colFormcalss}>
            	<Form.Control
              	type='text'
              	placeholder='phone Number'
              	className={styles.inputfieldDes}
                minLength={10}
                value={formValue.secondAssistantDirectorPhoneNumber}
                name="secondAssistantDirectorPhoneNumber"
                onChange={handleInputField}
            	/> 
            	<Form.Control.Feedback type="invalid" className={styles.errorFeedback}>
                  Please enter valid phone number
            		</Form.Control.Feedback>
            </Form.Group>
                 
          </div>

          <div className={styles.formWithLabel}>
            <YellowCircle className={styles.smallyellowIcon} />
            <h4 className={styles.labeltext}>Producer</h4>
          </div>
          <div className={styles.formInputs}>
            <Form.Group as={Col} className={styles.colFormcalss}>
              <Form.Control
            type='text'
            placeholder='Producer Name'
            className={styles.inputfieldDes}
						name="producerName"
            value={formValue.producerName}
            required
						onChange={handleInputField}
            />   
            <Form.Control.Feedback type="invalid" className={styles.errorFeedback}>
                Please enter producer name
            </Form.Control.Feedback> 
          </Form.Group>  
            <Form.Group as={Col} className={styles.colFormcalss}>
              <Form.Control
                type='text'
                placeholder='phone Number'
                className={styles.inputfieldDes}
                name="producerPhoneNumber"
                value={formValue.producerPhoneNumber}
                onChange={handleInputField}
                minLength={10}
                required
              /> 
              <Form.Control.Feedback type="invalid" className={styles.errorFeedback}>
                Please enter valid phone number
              </Form.Control.Feedback>
            </Form.Group>
						
          </div>

          <div className={styles.formWithLabel}>
            <YellowCircle className={styles.smallyellowIcon} />
            <h4 className={styles.labeltext}>Shooting End Date</h4>
          </div>
          <div className={styles.ThreeInputBox}>
            <div className={styles.inputInnerBox}>
              <p className={styles.inputparaahd}>Month</p>
              <Form.Control
                as = 'select'
                placeholder='December'
                className="selectOpt"
                required
                defaultValue={(endDate.month).toLocaleString("en-US", {
                  minimumIntegerDigits: 2,
                })}
                onChange={(event) => {
                  setEndDate({...endDate, month: event.target.value})
                  setFormValue({...formValue, shootingEndDate: endDate.year + "-" + event.target.value + "-" + endDate.day})
                }}
              > 
                {monthsList.map((item, index) => (
                <option key={index} value={ (index + 1).toLocaleString('en-US', {minimumIntegerDigits: 2}) } selected={ (index + 1).toLocaleString('en-US', {minimumIntegerDigits: 2}) === data?.shooting_end_date.split('-')[1]
              }>{item}</option>
              ))}
              </Form.Control> 
            </div>
            <div className={styles.inputInnerSMBox}>
              <p className={styles.inputparaahd}>Day</p>
              <Form.Control
              as = 'select'
              placeholder='December'
              defaultValue={endDate.day}
              className="selectOpt"
              onChange={(event) => {
                setEndDate({...endDate, day: event.target.value})
                setFormValue({...formValue, shootingEndDate: endDate.year + "-" + endDate.month + "-" + event.target.value})
              }}
              >
								{getDaysArray(endDate.year, endDate.month).map((item) => (
                  <option key={item} value={ (item).toLocaleString('en-US', {minimumIntegerDigits: 2}) } selected={item === parseInt(data?.shooting_end_date.split('-')[2])
                } >{(item).toLocaleString('en-US', {minimumIntegerDigits: 2})}</option>
                ))}
							</Form.Control>  
            </div>  
            <div className={styles.inputInnerSMBox}>
              <p className={styles.inputparaahd}>Year</p>
              <Form.Control
              as = 'select'
              placeholder='December'
              defaultValue={endDate.year}
              className="selectOpt"
							onChange={(event) => {
                setEndDate({...endDate, year: event.target.value})
                setFormValue({...formValue, shootingEndDate: event.target.value + "-" + endDate.month + "-" + endDate.day})
                  }
                }
              >
							{years(date.getFullYear() - 2010, date.getFullYear() + 7).map((item) => (
                  <option key={item} selected={item === parseInt(data?.shooting_end_date.split('-')[0])
                  } >{item}</option>
                )
                )}
							</Form.Control>  
            </div>  
          </div>
        </div>

        <div className={styles.formBoxthree}>
          <div className={styles.formWithLabel}>
            <YellowCircle className={styles.smallyellowIcon} />
            <h4 className={styles.labeltext}>Project Type</h4>
          </div>
          <Form.Group as={Col}  controlId="validationCustom08">
          {props.projectTypes.map((item) => (
            <div key={item.id} className={styles.formInputschk}>  
              <Form.Check
                name='thirdcheck'
                type='radio'
                label={item.name}
                className={styles.inputfieldDes}
                id={item.id}
                required
                checked={formValue.projectTypeId === item.id}
                onClick={() => setFormValue({...formValue, projectTypeId: item.id})}
              /> 
            <p htmlFor={item.id} className={styles.thirdBoxSidePara}>{item.description}</p>               
          </div>
          ))}
            <Form.Control.Feedback type="invalid" className={styles.errorFeedback}>
              Project Type should not be empty
            </Form.Control.Feedback>
          </Form.Group> 
{/* 
          <div className={styles.formWithLabel}>
            <YellowCircle className={styles.smallyellowIcon} />
            <h4 className={styles.labeltext}>Project Status</h4>
          </div>
          <div className={styles.formInputs}>
            <Form.Group as={Col} className={styles.colFormcalss} controlId="validationCustom01">
              <Form.Control
                as = 'select'
                name='projectStatus'
                className={`selectOpt ${styles.projectStatusSelect}`}
                value={formValue.projectStatus}
                onChange = {handleInputField}
              >
                <option value="">Select Status</option>
                <option value="confirmed">Confirmed</option>
                <option value="on-hold">On Hold</option>
                <option value="shot">Shot</option>
                <option value="working-on">Working On</option>
              </Form.Control>
            </Form.Group>
          </div> */}
       
          {projectId === undefined ? 
            <div className={styles.thirdBoxSvgImg}>
              <MainCirSvg className={styles.dashPlainImg} />
              <MainCirSvgYell className={styles.dashYellImg} />
              <button type='submit' className={styles.thirdBoxText}>
                Create
              </button>
            </div> : 
            <div className={styles.thirdBoxSvgImg}>
              <MainCirSvg className={styles.dashPlainImg} />
              <MainCirSvgYell className={styles.dashYellImg} />
              <button type='submit' className={styles.thirdBoxText}>
                Update
              </button>
            </div>
          }
        </div>
      </div>
      </Form>
    </div>
  );
};

export default CreateNewProject;