import React from "react";
import { deleteUser } from "./../../redux/Users/actions";
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const DeleteUserModal = (props) => {
  const deleteUserByID = (id) => {
    props.handleDelete(id);
    props.handleClose();
  }

  const heading = props?.heading || "Modal Heading";
  const subHeading = props?.subHeading || "Are you sure that you want to delete this user?"
  return (
    <Modal show={props.show} onHide={props.handleClose}>
      <Modal.Header closeButton>
        <Modal.Title> {heading } </Modal.Title>
      </Modal.Header>
      <Modal.Body>{ subHeading }</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleClose}>
          NO
        </Button>
        <Button variant="primary" onClick={() => deleteUserByID(props.id)}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    User: state.User,
  };
};

export default connect(mapStateToProps, { deleteUser })(DeleteUserModal);
