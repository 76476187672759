import axios from "axios";
import { ApiUrls, axiosConfig } from "../redux/apiUrls";

/**
 * @param {integer} projectId
 * @param {integer} offSet
 * @param {integer} limit
 * @returns: Error or success response
 * Comment: Get project role list based on project Id
 */
export const getProjectRolesByProjectId = async (projectId, offSet, limit) => {
	try {
		const data = await axios.get(ApiUrls.GET_PROJECT_ROLE_BY_PROJECT_ID + "/" + projectId + "?offset=" + offSet + "&limit=" + limit, axiosConfig());
		return {
			status: true,
			data: data?.data?.data
		}
	} catch (error) {
		return {
			status: false,
			errorMsg: error?.response?.data?.msg
		}
	}
};

/**
 * @param {object} roleData
 * @returns: Error or success response
 * Comment: Create the Project Roles
 */
export const createAndUpdateProjectRoles = async (roleData) => {
	try {
		const data = await axios.post(ApiUrls.CREATE_PROJECT_ROLE, roleData, axiosConfig());
		return {
			status: true,
			data: data?.data?.data
		}
	} catch (error) {
		return {
			status: false,
			errorMsg: error?.response?.data?.msg
		}
	}
};

/**
 * @param {integer} roleId
 * @param {integer} talentId
 * @returns: Error or success response
 * Comment: Create the project role options
 */
export const createProjectRoleOptions = async (roleId, talentId) => {
	try {
		const data = await axios.get(`${ApiUrls.CREATE_PROJECT_ROLE_OPTION}/${roleId}/${talentId}`, axiosConfig());
		return {
			status: true,
			data: data?.data?.data
		}
	} catch (error) {
		return {
			status: false,
			errorMsg: error?.response?.data?.msg
		}
	}
};

/**
 * 
 * @param {Object} projectId
 * @returns: Error or success response
 * Comment: Get project role breakdown with talents info
 */
export const getProjectRolesBreakdown = async (params) => {
	try {
		const data = await axios.get(ApiUrls.GET_PROJECT_ROLES_BREAKDOWN + "/" + params.projectId + "?offset=" + params.offSet + "&limit=" + params.limit, axiosConfig());
		return {
			status: true,
			data: data?.data?.data
		}
	} catch (error) {
		return {
			status: false,
			errorMsg: error?.response?.data?.msg
		}
	}
}

export const deleteProjectRoleByRoleId = async (id) => {
  try {
    const data = await axios.delete(ApiUrls.DELETE_PROJECT_ROLE_BY_ID + "/" + id, axiosConfig());
		return {
			status: true,
			data: data?.data?.data
		}
  } catch (error) {
    return {
			status: false,
			errorMsg: error?.response?.data?.msg
		}
  }
}

/**
 * @param {integer} projectId
 * @param {integer} offSet
 * @param {integer} limit
 * @returns: Error or success response
 * Comment: Get project role list based on project Id
 */
export const getProjectRolesBasedOnProjectId = async (params) => {
	try {
		const data = await axios.post(ApiUrls.GET_PROJECT_ROLES_BY_PROJECT_ID + "/" + params.projectId, params, axiosConfig());
		return {
			status: true,
			data: data?.data?.data
		}
	} catch (error) {
		return {
			status: false,
			errorMsg: error?.response?.data?.msg
		}
	}
};

/**
 * @param {integer} roleId
 * @param {integer} talentId
 * @param {integer} sceneId
 * @param {boolean} removeRoleFromScene
 * @returns: Error or success response
 * Comment: Delete the cast from project role by Id
 */
export const deleteCastFromProjectRoleById = async (roleId, talentId, sceneId, removeRoleFromScene) => {
	try {
		const data = await axios.delete(`${ApiUrls.DELETE_CAST_FROM_PROJECT_ROLE_BY_ID}/${roleId}/${talentId}?sceneId=${sceneId}&removeRoleFromScene=${removeRoleFromScene}`,axiosConfig());
		return {
			status: true,
			data: data?.data?.data
		}
	} catch (error) {
		return {
			status: false,
			errorMsg: error?.response?.data?.msg
		}
	}
};

/**
 * @param {string} projectRoleTalentStatus
 * @returns: Error or success response
 * Comment: Update project role talent status
 */
export const updateProjectRoleTalentStatus = async (projectRoleTalentStatusData) => {
	try {
		const data = await axios.put(ApiUrls.UPDATE_PROJECT_ROLE_TALENT_STATUS, projectRoleTalentStatusData, axiosConfig());
		return {
			status: true,
			data: data?.data?.data
		}
	} catch (error) {
		return {
			status: false,
			errorMsg: error?.response?.data?.msg
		}
	}
};
