import React, { useState, useEffect } from "react";
import styles from "./paginationSidebar.module.scss"
import { ReactComponent as Sidelogo } from "../../../assets/icons/sidelogo.svg";
import { ReactComponent as Listicon } from "../../../assets/icons/listicon.svg";
import { ReactComponent as DoubleUpArrow } from "../../../assets/icons/doubleUpArrow.svg";
import { ReactComponent as DoubleDownAroow } from "../../../assets/icons/doubleDownAroow.svg";
import { ReactComponent as NoDataImgCircle } from "../../../assets/icons/mainimgWhiteYell.svg";
import { ReactComponent as PlusIcon } from "../../../assets/icons/PlusIcon.svg";
import CancelArrow from "../../../assets/icons/cancelArrow.png";
import RevCancelArrow from "../../../assets/icons/revCancelArrow.png";
import { Link } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import CreateNewRole from "../../../components/shared/createNewRole";

const PaginationSidebar = (props) => {
	const selectedroleIndex = props?.activeRoleindex;
	const [showingFrom, setShowingFrom] = useState(0);
	const [showingTo, setShowingTo] = useState(0);
  const showListing = props?.sidebarpageViewType === "created" || props.sidebarpageViewType === "updated" ? false : true;
	const toggleSidebar = () => {
		props.isOpenSidebar === false ? props.setIsOpenSidebar(true) : props.setIsOpenSidebar(false);
	};

	useEffect(() => {
		setShowingFrom(props?.offSet + 1);
		const tempCount = props?.offSet + 1 + props?.data?.length - 1;
		setShowingTo(tempCount);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props?.data, showingFrom, showingTo]);

  const roleData = {
    roleId: props?.data[props.activeRoleindex]?.id,
    roleName: props?.data[props.activeRoleindex]?.role_name,
    roleDescription: props?.data[props.activeRoleindex]?.role_description,
  }

	return (
		<>
			<div className={props?.isOpenSidebar === false ? styles.toggleSidebar : styles.sideBigMenu}>
				<div>
					<div className={styles.sideImglogo}>
						<div className={styles.sideImgbox}>
							<Sidelogo className={styles.sideimgsvgicon} />
							<p className={styles.sideimgsvgtxt}>Youcast</p>
						</div>
						<div className={styles.sideBarlogoTxt}>
							<h4 className={styles.sidelgtxt}>{props?.heading}</h4>
							<p className={styles.sidelgsubtxt}>{props?.subHeading}</p>
						</div>
					</div>
					<p className={styles.silelogosubText}>Recently Opened / {props?.totalCount} {props?.subTitleForCount}</p>
					{showListing &&
						<>
							{!props?.showLoader &&
								<ul className={styles.ulListsb}>
									{props?.data?.map((item, index) => (
										<li
											key={item.id}
											className={(item.id === props?.selectedProject || selectedroleIndex === index) ? styles.selectedList : styles.lilistsb}
											onClick = {props?.pageType === "projects" ? () => props?.handleProject(item.id, index) : props?.pageType === "talents" ? () => props?.selectedProjectInfo(item.id, item.project_name) : () => props?.setActiveRoleIndex(index)}
										><Listicon className={styles.listiconsb} /> <span className={styles.listIconName}>
											{item?.role_name ? item.role_name : item.project_name}</span>
										</li>
									))}
								</ul>
							}
						</>
					}

					{props?.showLoader &&
						<div className={styles.loaderInSidebar}>
							<Spinner animation="border" variant="primary" className={styles.InnerLoader} />
						</div>
					}

					{!showListing &&
						<CreateNewRole 
							setPageViewType={ props.setSidebarPageViewType }
              sidebarpageViewType = { props.sidebarpageViewType  }
							setRoleCreatedEvent = { props?.setRoleCreatedEvent }
              roleData = { roleData }
							projectId = { props?.projectId }
						/>
					}
				</div>

				{(props?.pageType === "projects" || props?.pageType === "roles-breakdown") && showListing &&
					<div className={styles.collapseExpandBtn} onClick={toggleSidebar}>
						{props?.isOpenSidebar === false ?
							<img src={RevCancelArrow} alt="revCancelArrow" /> :
							<img src={CancelArrow} alt="cancelArrow" />
						}
					</div>
				}

				{(props?.data?.length === 0 && props.sidebarpageViewType !== "created") &&
					<div className={styles.noDataMsg}>
						<NoDataImgCircle className={styles.Noroleimg} />
						<p className={styles.noRoleText}>
							{props?.pageType === "roles-breakdown" ?
								"No role found" :	"No Project Found"
							}
						</p>
					</div>
				}

				{showListing &&
         <div>
					{props?.data?.length > 0 &&
						<p className={styles.silebotsubText}>Showing {showingFrom} to {showingTo > 0 ? showingTo : ''} {props?.subTitleForCount}</p>
					}
					<ul className={styles.arrowcontainer}>
						<li className={(props?.data?.length < props?.limit || showingTo === props?.totalCount) ? styles.disable : styles.arrowbox} onClick={() => props?.handlePagination("next")}>
							<DoubleDownAroow className={styles.dblarrowSvg} /><span>Move Down</span>
						</li>
						<li className={showingFrom === 1 ? styles.disable : styles.arrowbox} onClick={() => props?.handlePagination("prev")}>
							<DoubleUpArrow className={styles.dblarrowSvg} /><span>Move Up</span>
						</li>

						{props?.pageType === "roles-breakdown" ?
							(
								<li className={`${styles.lastarrowbox} ${styles.arrowbox}`} onClick={() => {
									props.setCreatedRoleType("create");
									props.setSidebarPageViewType("created"); props.setIsOpenSidebar(true)}
									}>
									<PlusIcon className={styles.dblarrowSvg} /><span>Create New Role</span>
								</li>
							)
							:
							(
								<>
									<Link to="/director/projects/create">
										<li className={`${styles.lastarrowbox} ${styles.arrowbox}`}>
											<PlusIcon className={styles.dblarrowSvg} /><span>Create New Project</span>
										</li>
									</Link>
									
									{props?.pageType === "talents" &&
										<li className={`${styles.backButton} ${styles.arrowbox}`} onClick={() => props?.setShowFilterType("modalFilter")}>Back To Filter</li>
									}
								</>
							)
						}
					</ul>
				</div>
        }
			</div>
		</>
	)
}

export default PaginationSidebar;
