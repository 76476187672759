export const SHOW_LOADING = "SHOW_LOADING";
export const USER_LOGOUT = "USER_LOGOUT";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const STOP_LOADING = "STOP_LOADING";
export const USER_LISTING_SUCCESS = "USER_LISTING_SUCCESS";
export const USER_LISTING_ERROR = "USER_LISTING_ERROR";
export const USER_COUNT_SUCCESS = "USER_COUNT_SUCCESS";
export const USER_COUNT_ERROR = "USER_COUNT_ERROR";
export const MODEL_LISTING_ERROR = "MODEL_LISTING_ERROR";
export const MODEL_LISTING_SUCCESS = "MODEL_LISTING_SUCCESS";
export const MODEL_LISTING_COUNT = "MODEL_LISTING_COUNT";

export const USER_CREATE_INITIALIZE = "USER_CREATE_INITIALIZE";
export const USER_CREATE_SUCCESS = "USER_CREATE_SUCCESS";
export const USER_CREATE_FAILURE = "USER_CREATE_FAILURE";

export const USER_DELETE_INITIALIZE = "USER_DELETE_INITIALIZE";
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS";
export const USER_DELETE_FAILURE = "USER_DELETE_FAILURE";

export const GET_USER_BY_ID_INITIALIZE = "GET_USER_BY_ID_INITIALIZE";
export const GET_USER_BY_ID_SUCCESS = "GET_USER_BY_ID_SUCCESS";
export const GET_USER_BY_ID_FAILURE = "GET_USER_BY_ID_FAILURE";

export const UPDATE_USER_BY_ID_INITIALIZE = "UPDATE_USER_BY_ID_INITIALIZE";
export const UPDATE_USER_BY_ID_SUCCESS = "UPDATE_USER_BY_ID_SUCCESS";
export const UPDATE_USER_BY_ID_FAILURE = "UPDATE_USER_BY_ID_FAILURE";

// accounts 
export const ACCOUNT_MULTI_DELETE_SUCCESS = "ACCOUNT_MULTI_DELETE_SUCCESS ";
export const ACCOUNT_MULTI_DELETE_FAiLURE = "CCOUNT_MULTI_DELETE_FAILURE";

// filters 
export const GET_FILTER_TYPES = "GET_FILTER_TYPES";
export const GET_FILTER_TYPES_SUCCESS = "GET_FILTER_TYPES_SUCCESS";
export const GET_FILTER_TYPES_ERROR = "GET_FILTER_TYPES_ERROR";

export const SUBSCRIPTIONS_LISTING_SUCCESS = "SUBSCRIPTIONS_LISTING_SUCCESS";
export const SUBSCRIPTIONS_LISTING_ERROR = "SUBSCRIPTIONS_LISTING_ERROR";

export const UPDATE_SUBSCRIPTIONS_BY_ID_INITIALIZE = "UPDATE_SUBSCRIPTIONS_BY_ID_INITIALIZE";
export const UPDATE_SUBSCRIPTIONS_BY_ID_SUCCESS = "UPDATE_SUBSCRIPTIONS_BY_ID_SUCCESS";
export const UPDATE_SUBSCRIPTIONS_BY_ID_FAILURE = "UPDATE_SUBSCRIPTIONS_BY_ID_FAILURE";
export const SUBSCRIPTIONS_COUNT_SUCCESS = "SUBSCRIPTIONS_COUNT_SUCCESS";
export const SUBSCRIPTIONS_COUNT_ERROR = "SUBSCRIPTIONS_COUNT_ERROR";

export const GET_CITY_SUCCESS = "GET_CITY_SUCCESS";
export const GET_CITY_FAILURE = "GET_CITY_FAILURE";

export const UPDATE_USER_STATUS_SUCCESS = "UPDATE_USER_STATUS_SUCCESS";
export const UPDATE_USER_STATUS_FAILURE = "UPDATE_USER_STATUS_FAILURE";

export const ADMIN_FAV_STATUS_SUCCESS = "ADMIN_FAV_STATUS_SUCCESS";
export const ADMIN_FAV_STATUS_FAILURE = "ADMIN_FAV_STATUS_FAILURE";

export const UPDATE_USER_INFO = "UPDATE_USER_INFO";

export const GET_PROJECT_BY_ID_SUCCESS = "GET_PROJECT_BY_ID_SUCCESS";
export const GET_PROJECT_BY_ID_FAILURE = "GET_PROJECT_BY_ID_FAILURE";
export const GET_EPISODES_LIST_BY_PROJECT_ID_SUCCESS = "GET_EPISODES_LIST_BY_PROJECT_ID_SUCCESS";
export const GET_EPISODES_LIST_BY_PROJECT_ID_ERROR = "GET_EPISODES_LIST_BY_PROJECT_ID_ERROR";

// Talents
export const TALENTS_LISTING_SUCCESS = "TALENTS_LISTING_SUCCESS";
export const TALENTS_LISTING_ERROR = "TALENTS_LISTING_ERROR";

// Scenes
export const GET_SCENES_LIST_BY_EPISODE_ID_SUCCESS = "GET_SCENES_BY_EPISODE_ID_SUCCESS";
export const GET_SCENES_LIST_BY_EPISODE_ID_ERROR = "GET_SCENES_BY_EPISODE_ID_ERROR";
export const SET_SIDEBAR_PAGE_VIEW_TYPE = "SET_SIDEBAR_PAGE_VIEW_TYPE";