import {
  SHOW_LOADING,
  GET_PROJECT_BY_ID_SUCCESS,
  GET_PROJECT_BY_ID_FAILURE,
  GET_EPISODES_LIST_BY_PROJECT_ID_SUCCESS,
  GET_EPISODES_LIST_BY_PROJECT_ID_ERROR,
  STOP_LOADING,
} from "./../types";

const Initial_State = {
  loading: false,
  data: {
    projectList: [],
    episodesData: []
  },
  error: ""
}

export const ProjectReducer = (state = Initial_State, action) => {
  switch (action.type) {
    case SHOW_LOADING:
      return { ...state, data: { ...state.data} }
    case GET_PROJECT_BY_ID_SUCCESS:
      return { ...state, data: { ...state.data, projectList: action.payload }, loading: false }
    case GET_PROJECT_BY_ID_FAILURE:
      return { ...state, loading: false, error: action.payload }
    case STOP_LOADING:
      return { ...state, loading: false }
    case GET_EPISODES_LIST_BY_PROJECT_ID_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          episodesData: action.payload
        },
        error: ""
      }
    case GET_EPISODES_LIST_BY_PROJECT_ID_ERROR:
      return {
        ...state,
        error: action.payload
      }
    default:
      return state
  }
}