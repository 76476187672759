import React, { useEffect, useState } from "react";
import styles from "./projectRoles.module.scss";
import { ReactComponent as YellowCircle } from "../../../assets/icons/sidelogo.svg";
import { ReactComponent as Roleicon } from "../../../assets/icons/RoleIcon.svg";
import { ReactComponent as RoleiconYell } from "../../../assets/icons/roleIconYell.svg";
import { ReactComponent as DoubleDownAroow } from "../../../assets/icons/doubleDownAroow.svg";
import { ReactComponent as DoubleUpArrow } from "../../../assets/icons/doubleUpArrow.svg";
import { ReactComponent as NoDataImgCircle } from "../../../assets/icons/mainimgWhiteYell.svg";
import { ReactComponent as PlusIcon } from "../../../assets/icons/PlusIcon.svg";
import { getProjectRolesByProjectId, createProjectRoleOptions } from "../../../services/projectRole";
import { toast } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";
import CreateNewRole from "../../shared/createNewRole";
import { defaultLimit } from "../../../constant";

const ProjectRoles = (props) => {
	const [projectRoles, setProjectRoles] = useState([]);
	const [pageViewType, setPageViewType] = useState("view");
	const [showLoader, setShowLoader] = useState(false);
	const [projectRolesParamData, setProjectRolesParamData] = useState({
		limit: defaultLimit,
		offSet: 0,
	});

	const getProjectRoles = async (projectId, offSet, limit) => {
    setShowLoader(true);
		const result = await getProjectRolesByProjectId(projectId, offSet, limit);
    setShowLoader(false);
		if (result.status) {
			setProjectRoles(result.data);
		}
	};

	useEffect(() => {
		getProjectRoles(props?.projectId, projectRolesParamData?.offSet, projectRolesParamData?.limit);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [projectRolesParamData?.offSet]);

	const data = projectRoles?.rows;
	const totalCount = projectRoles?.count;
	const showingFrom = projectRolesParamData?.offSet + 1;
	const showingTo = showingFrom + data?.length - 1;

	const handleProjectRolesPagination = (type) => {
		setShowLoader(true);
		let offSet = projectRolesParamData.offSet - projectRolesParamData.limit;
		if (type === "next") {
			offSet = projectRolesParamData.offSet + projectRolesParamData.limit;
		}
		setProjectRolesParamData({
				...projectRolesParamData,
				offSet
		});
	};

	const saveProjectRoleOption = async (roleId) => {
		try {
			const result = await createProjectRoleOptions(roleId, props?.talentId);
			if (!result.status) {
				toast.error(result.errorMsg);
			} else {
				toast.success("Project role has been assigned to talent.");
				props.setShowFilterType("modalFilter");
				getProjectRoles(props?.projectId, projectRolesParamData?.offSet, projectRolesParamData?.limit);
			}
		} catch (error) {
			toast.error("Some issue occurred while assigning roles");
		}
	};

	const setRoleCreatedEvent = () => {
		setPageViewType("view");
		getProjectRoles(props?.projectId, projectRolesParamData?.offSet, projectRolesParamData?.limit);
	}

	return (
    <div className={styles.rolePgSidebar}>
			<div>
				<div className={styles.sideImglogo}>
					<div className={styles.sideImgbox}>
						<YellowCircle  className={styles.sideimgsvgicon} />
						<p className={styles.sideimgsvgtxt}>Youcast</p>
					</div>
					
					<div className={styles.sideBarlogoTxt}>
						<h4 className={styles.sidelgtxt}>Add Cast to Role.</h4>
						<p className={styles.sidelgsubtxt}>Select A role For Your Cast.</p>
					</div>
				</div>

				<div className={styles.castTorole}>
					<p className={styles.rolesLabel}>{props?.projectName} / {totalCount} Roles</p>
					{pageViewType === "view" &&
						<>
							{!showLoader &&
								<div className={styles.RolesSet}>
									{data?.map((item) => (
									<div className={styles.roleBoxSet} key={item.id} onClick={() => saveProjectRoleOption(item?.id)}>
											<Roleicon className={styles.rolesImg}/>
											<RoleiconYell className={styles.rolesImgYell}/>
											<p className={styles.roleLabelOne}>{item?.role_name}</p>
											{item?.roleOptions?.length > 0 &&
												<p className={styles.roleLabelOne}>{item?.roleOptions?.length} Options</p>
											}
											<p className={styles.roleLabelTwo}>Cast Here</p>
										</div>
									))}
								</div>
							}
						</>
					}

					{showLoader &&
						<div className={styles.loaderInSidebar}>
							<Spinner animation="border" variant="primary" className={styles.InnerLoader} />
						</div>
					}

					{pageViewType === "create" &&
							<CreateNewRole
								setPageViewType = {setPageViewType}
								setRoleCreatedEvent = { setRoleCreatedEvent }
								projectId = { props?.projectId }
							/>

					}
				</div>
			</div>
			
			{pageViewType === "view" &&
				data?.length === 0 && (
					<div className={styles.noDataMsg}>
						<NoDataImgCircle className={styles.noRoleimg}/>
						<p className={styles.noRoleText}>No Role Found</p>
					</div>
				)
			}
				
			{pageViewType === "view" &&
				<div className={styles.rolrShows}>
					{data?.length > 0 &&
						<p className={styles.silebotsubText}>Showing {showingFrom} to {showingTo} Roles</p>
					}
					<ul className={styles.arrowcontainer}>
						<li className={(data?.length < projectRolesParamData?.limit || showingTo === totalCount) ? styles.disable : styles.arrowbox} onClick={() => handleProjectRolesPagination("next")}>
						<DoubleDownAroow className={styles.dblarrowSvg} /> Move Down
						</li>
						<li className={showingFrom === 1 ? styles.disable : styles.arrowbox} onClick={() => handleProjectRolesPagination("prev")}>
						<DoubleUpArrow className={styles.dblarrowSvg} /> Move Up
						</li>
						<li className={styles.arrowbox} onClick={() => {
							setPageViewType("create");
						}}>
							<PlusIcon className={styles.dblarrowSvg} /> Create a New Role
						</li>
						<li 
							className={styles.arrowbox} 
							onClick={props?.selectedProject ? () => props?.setShowFilterType("modalFilter") : () => props?.setShowFilterType("projectSidebar")}
						>
							Back To Project
						</li>
					</ul>
				</div>
			}
		</div>
	)
}

export default ProjectRoles;
