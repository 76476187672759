import { getSceneListByEpisodeAndProjectId } from "../../services/episodeSceneService";
import {
  GET_SCENES_LIST_BY_EPISODE_ID_SUCCESS,
  GET_SCENES_LIST_BY_EPISODE_ID_ERROR,
	SET_SIDEBAR_PAGE_VIEW_TYPE
} from "./../types";


//when error is occurred
const Error = (error, type) => {
	return {
		type,
		payload: error,
	};
};

//when successfully fetching data
const Success = (data, type) => {
	return {
		type,
		payload: data.data,
	};
};

export function getSceneLisingtByEpisodeAndProjectID(params) {
	return async (dispatch) => {
		try {
			let data = await getSceneListByEpisodeAndProjectId(params);
			dispatch(Success(data, GET_SCENES_LIST_BY_EPISODE_ID_SUCCESS));
		} catch (e) {
			Error(e, GET_SCENES_LIST_BY_EPISODE_ID_ERROR);
		}
	}
};

export function setSidebarPageViewType(sidebarPageViewType) {
	return {
		type: SET_SIDEBAR_PAGE_VIEW_TYPE,
		payload: sidebarPageViewType
	}
};
