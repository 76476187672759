import { SHOW_LOADING, LOGIN_SUCCESS, LOGIN_ERROR, STOP_LOADING, USER_LOGOUT } from './../types';

const Initial_State = {
    loading: false,
    data: {},
    error: '',
    loggedIn: false
}

export const SignInReducer = (state = Initial_State, action) => {
    if (action.type === LOGIN_SUCCESS && action.payload && action.payload.data && action.payload.data.token && action.payload.data.user && action.payload.data.loginType) {
        localStorage.setItem('accessToken', action.payload.data.token.accessToken);
        localStorage.setItem('refreshToken', action.payload.data.token.refreshToken);
        localStorage.setItem('user', JSON.stringify(action.payload.data.user));
        localStorage.setItem('loginType', action.payload.data.loginType);
    }
    if (action.type === USER_LOGOUT) {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('user');
    }
    switch (action.type) {
        case SHOW_LOADING:
            return { ...state, loading: true }
        case LOGIN_SUCCESS:
            return { ...state, data: action.payload, loading: false, loggedIn: true }
        case LOGIN_ERROR:
            return { ...state, loading: false, error: action.payload }
        case STOP_LOADING:
            return { ...state, loading: false }
        case USER_LOGOUT:
            return { ...state, loading: false, loggedIn: false }
        default:
            return state
    }
}