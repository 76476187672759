import React from 'react'
import './userAnalytics.scss'

export default function userAnalytics(props) {
    return (
        <div className="UserAnalytics">
            <span className="users">{props.users}</span>
            <span>{props.number}</span>
        </div>
    )
}
