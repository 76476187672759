import axios from "axios";
import { ApiUrls, axiosConfig } from "../redux/apiUrls";
import { adminFavStatusByIdApi } from "../redux/Users/api";

/**
 * @param: null
 * @returns: Error or success response
 * Comment: Get list of talents based on filters
 */
export const getTalentsList = async (isFavourite, filters, talentId) => {
	try {
		const data = await axios.get(`${ApiUrls.GET_TALENTS_LIST}/${isFavourite}?talentId=${talentId}`, axiosConfig(filters));
		return {
			status: true,
			data: data?.data?.data
		}
	} catch (error) {
		return {
			status: false,
			errorMsg: error?.response?.data?.msg
		}
	}
};

/**
 * @param {integer} id
 * @param {boolean} favouriteStatus
 * @returns: Error or success response
 * Comment: Get favorite status by id
 */
export const getFavoriteStatus = async (id, favouriteStatus) => {
	try {
		const data = await adminFavStatusByIdApi(id, favouriteStatus);
		return {
			status: true,
			data: data?.data?.data
		}
	} catch (error) {
		return {
			status: false,
			errorMsg: error?.response?.data?.msg
		}
	}
};

/**
 * 
 * @param {integer} id 
 * @returns Error or success response
 * Comment: Get talent profile data by id
 */
export const getTalentProfileByUserId = async (id) => {
	try {
		const data = await axios.get(ApiUrls.GET_TALENT_PROFILE + "/" + id , axiosConfig());
    return {
			status: true,
			data: data?.data?.data
		};
	} catch (error) {
		return {
			status: false,
			errorMsg: error?.response?.data?.msg
		};
	};
};