import axios from "axios";
import { ApiUrls, axiosConfig } from "../apiUrls";

export const UserListingApi = (data) => {
  return axios.get(ApiUrls.GET_USER_LIST, {
    params: {
      limit: data.limit,
      offset: data.offSet,
      activeAccounts: data.activeAccounts,
      searchTypes: data.searchTypes
    },
  });
};

export const ModelListingApi = (data) => {
  return axios.get(ApiUrls.GET_USER_LIST, axiosConfig(data));
};

export const UserCountApi = (data) => {
  return axios.get(ApiUrls.GET_USER_COUNT, axiosConfig());
};

export const createUserApi = (data) => {
  return axios.post(ApiUrls.CREATE_USER, {
    ...data,
  },
    axiosConfig()
  );
};

export const deleteUserApi = (id) => {
  return axios.put(ApiUrls.DELETE_USER + id, {
    deleted: 1
  }, axiosConfig());
};

export const getUserByIDapi = (id) => {
  return axios.get(ApiUrls.GET_USER_BY_ID + id, axiosConfig());
}

export const updateUserByIDapi = (id, data) => {
  return axios.put(ApiUrls.UPDATE_USER + id, data, axiosConfig())
}

export const updateSubscriptionsByIDapi = (id, data) => {
  return axios.put(ApiUrls.UPDATE_SUBSCRIPTIONS + id, data, axiosConfig())
}

export const updateUserStatusByIdApi = (id, data) => {
  return axios.put(ApiUrls.UPDATE_USER_STATUS + id, data, axiosConfig())
}

export const adminFavStatusByIdApi = (id, favouriteStatus) => {
  return axios.get(ApiUrls.ADMIN_FAV_STATUS + '/' + id + '/' + favouriteStatus, axiosConfig())
}