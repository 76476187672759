import axios from 'axios'
import { ApiUrls, axiosConfig } from '../apiUrls';

export const deleteMultiAccountsApi = (items)=> {
    return axios.put(ApiUrls.DELETE_MULTI_ACCOUNTS, { items } , axiosConfig());
    };

export const uploadUserImage = (items) => axios.post(ApiUrls.UPLOAD_USER_IMAGE,items, axiosConfig());

export const getImagePath = (id) => axios.get(ApiUrls.GET_USER_IMAGE+`/${id}`, axiosConfig());

export const uploadUsersVideo = (items) => axios.post(ApiUrls.UPLOAD_USER_VIDEO, items, axiosConfig());

export const getVideoPath = (id) => axios.get(ApiUrls.GET_USER_VIDEOS+`/${id}`, axiosConfig());