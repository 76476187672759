import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import { createAndUpdateProjectRoles } from "../../../services/projectRole";
import styles from "./createNewRole.module.scss";

const CreateNewRole = (props) => {
	const [showError, setShowError] = useState(false);
	const [createRole, setCreateRole] = useState({
		projectId: props?.projectId,
    roleId: props?.sidebarpageViewType === "updated" ? props?.roleData?.roleId : 0,
		roleName: props?.sidebarpageViewType === "updated" ? props?.roleData?.roleName : "",
		roleDescription: props?.sidebarpageViewType === "updated" ? props?.roleData?.roleDescription : ""
	});

	useEffect( () => {
		if (createRole.roleName.trim().length !== 0) {
			setShowError(false);
		} 
	}, [createRole.roleName]);

	const saveAndUpdateProjectRole = async () => {
		try {
			if (createRole.roleName.trim() === "") {
				setShowError(true);
				return false;
			}
			const result = await createAndUpdateProjectRoles(createRole);
			if (!result.status) {
				toast.error(result.errorMsg);
			} else {
				toast.success(`Project role has been ${props?.sidebarpageViewType}.`);
				return props?.setRoleCreatedEvent();
			}
		} catch (error) {
			toast.error(`Some issue occurred while ${props?.sidebarpageViewType} roles`);
		}
	};

	return(
		<>
			{props?.sidebarpageViewType === "updated" ? 
        <h6 className={styles.createRoleHeading}>Update This Role</h6> :
        <h6 className={styles.createRoleHeading}>Create a New Role</h6>
        }
			<Form.Control
				type={"text"}
				placeholder={"Name Your Role"}
				required
				value={createRole.roleName}
				onChange={(event) => setCreateRole({...createRole, roleName: event.target.value})}
				className={ !showError ? styles.createRoleInput : styles.showErrorInput}
			/>
			{showError && 
				<p className={styles.showError}> Please enter role name </p>
			}
			<Form.Control
				as="textarea"
				placeholder={"Descripton"}
				value={createRole.roleDescription}
				onChange={(event) =>
					setCreateRole({ ...createRole, roleDescription: event.target.value })
				}
				className={styles.createRoleDescriptionInput}
			/>
        <div className={styles.createRoleBtn} onClick={saveAndUpdateProjectRole}>
          {props?.sidebarpageViewType === "updated" ? "Update" : "Create"}
			  </div> 

			<div className={styles.createRoleBtn} onClick={() => props?.setPageViewType("view")}>
				Back
			</div>
		</>
	);
}

export default CreateNewRole;
