import React, { useEffect, useState, useRef } from "react";
import styles from "../../components/talent-profile/profileTalent.module.scss";
import { ReactComponent as ArowwRight } from "../../assets/icons/arrorightstrroke.svg";
import { ReactComponent as ArowwLeft } from "../../assets/icons/arrowleftstrroke.svg";
import { ReactComponent as FavoriteIcon } from "../../assets/icons/star-lines.svg";
import { ReactComponent as YellowSmlcircle } from "../../assets/icons/yellowcircle.svg";
import { ReactComponent as LeftHand } from "../../assets/icons/LeftHand.svg";
import { ReactComponent as RightHand } from "../../assets/icons/RightHand.svg";
import { getTalentProfileByUserId } from "../../services/talentsService";
import { getFavoriteStatus } from "../../services/talentsService";
import { getUsersVideos } from "../../services/userService";
// import VideoPlayerModal from "../../components/modal/VideoModal/VideoPlayerModal";
import ImageModal from "../../components/modal/ImageModal/ImageModal";
import { useParams, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Modelpic from "../../assets/images/modelPic.png";
import Modelvid from "../../assets/images/modelvid.png";
// import Playbtn from "../../assets/images/playButton.png";
import Form from "react-bootstrap/Form"; 
import Footer from "../../components/shared/footer";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { createProjectRoleOptions } from "../../services/projectRole";
import ReactPlayer from 'react-player';
import styled from 'styled-components';

const ProfileTalent = (props) => {
  const location = useParams();
	const qParams = useLocation();
	const myRef = useRef([]);
	const paginationRef = useRef([]);
	const [indexOFImage, setIndexOfImage] = useState(1);
	const [talentProfileData, setTalentProfileData] = useState();
	const [videoData, setVideoData] = useState([]);
	const [videoId, setVideoId] = useState();
	// const [videoPlayerModalOpen, setVideoPlayerModal] = useState(false);
	const [isImageModalOpen, setIsImageModalOpen] = useState(false);
	const [modalImage, setModalImage] = useState('');
  const [pageCount, setPageCount] = useState(1);
	const [isFavorite, setIsFavorite] = useState(0);
	// const [videoPath, setVideoPath] = useState({
  //   thumbnail: '',
  //   Video_url: ''
  // });
	const history = useHistory();
  const firstName = talentProfileData?.name.split(" ")[0];
	const queryParams = new URLSearchParams(qParams.search);
	let roleId = queryParams.get("roleId") || "";
	let projectId = queryParams.get("projectId") || "";

	const executeScroll = (index) => {
		return myRef.current[index]?.scrollIntoView({ behavior: "smooth", block: "end", inline: "start" });
  };

  const executeScrollPagination = (index) => {
		return paginationRef.current[index]?.scrollIntoView({ behavior: "smooth", block: "end", inline: "start" });
  };

	const getTalentProfile = async () => {
    const result = await getTalentProfileByUserId(location.id);
    if (!result?.status) {
			toast.error(result.msg);
    } else {
      setTalentProfileData(result.data);
			setIsFavorite(result?.data?.favourite);
    };
	};

	const getUserVideosByUserId = async () => {
		const result = await getUsersVideos(location.id);
		if (!result?.success) {
			toast.error(result.msg);
    } else {
      setVideoData(result?.data?.data);
			setVideoId(result?.data?.data[0]?.id);
    };
	};
	
	const handleChangeVideoTitle = (event) => {
		setVideoId(event.target.value);
	};

  const handleScroll = (e) => {
    if (e.currentTarget.scrollLeft === 0) {
      setPageCount(1);
    } else {
      setPageCount(2);
    };
  };

	useEffect(() => {
		getTalentProfile();
		getUserVideosByUserId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	
	const setTalentAsFavorite = async () => {
		await getFavoriteStatus(talentProfileData?.id, talentProfileData?.favourite === 1 ? 0 : 1);
		setIsFavorite(isFavorite === 1 ? 0 : 1);
	};

	const saveProjectRoleOption = async () => {
		try {
			const result = await createProjectRoleOptions(roleId, talentProfileData?.id);
			if (!result.status) {
				toast.error(result.errorMsg);
			} else {
				toast.success("Project role has been assigned to talent.");
        history.push(`/director/project-roles-breakdown/${projectId}`);
			}
		} catch (error) {
			toast.error("Some issue occurred while assigning roles");
		}
	};

	const VideoWrapper = styled.div`
  	 video {
   		width: 100%;
    	height: 100%;
    	object-fit: cover;
  	}`;

	return (
	<>
		<div className={styles.profileTwoPageAttach} onScroll={handleScroll} >
			<div ref={(e) => paginationRef.current[1] = e} className={styles.modelsProfilePage}>
				<div className={styles.modelImgSec}>
					<ArowwRight className={styles.rightarrClass} onClick={() => {
						executeScroll(indexOFImage);
						talentProfileData?.images.length !== indexOFImage && 
							setIndexOfImage(indexOFImage + 1)
						}}/>
						{talentProfileData?.images.length > 0 ?
							<div className={styles.modelImgSecClass} >
								{talentProfileData?.images.map((item, index) => (
									<img key={index} src={`${process.env.REACT_APP_YOUCAST_USER_IMAGES_PATH}/${process.env.REACT_APP_MODE}/${talentProfileData?.id}/account/${item.title}`} ref={(e) => myRef.current[index] = e} alt="Modelpic" onClick={() => {
										setIsImageModalOpen(true);
										setModalImage(`${process.env.REACT_APP_YOUCAST_USER_IMAGES_PATH}/${process.env.REACT_APP_MODE}/${talentProfileData?.id}/account/${item.title}`);
									}} />
								))
								}
							</div> :
							<div className={styles.modelImgSecClass}>
								<img  src={Modelpic}  alt="Modelpic"/>
							</div>
						}
					<ArowwLeft className={styles.leftarrClass} onClick={() => {
						executeScroll(indexOFImage - 2);
						indexOFImage !== 1 && 
							setIndexOfImage(indexOFImage - 1)
						}} />
				</div>
				<div className={styles.modelsVideoSec}>
					<div className={styles.modelVidLogo}>
						<div className={styles.ModelLogoIcon}>
							<div className={styles.sideBarlogoTxt}>
								<h4 className={styles.sidelgtxt}>Profile Created At</h4>
								<p className={styles.sidelgsubtxt}>Youcast Studios</p>
							</div>
							<div className={styles.sideImgbox} onClick={setTalentAsFavorite}>
								<FavoriteIcon className={isFavorite === 1 ? styles.favIcon : styles.nonFavIcon} />
								<p className={styles.sideimgsvgtxt}>{isFavorite === 1 ? "Remove Favorite" : "Favorite"}</p>
							</div>
						</div>	
						<div className={styles.modelIconBtn}>
							<button onClick={() => {!projectId ? history.push(`/director/talents?talentId=${location.id}`): saveProjectRoleOption()}} className={styles.modlBtn}>Cast {firstName}</button>
						</div>
					</div>
					
					<div className={styles.modelSelectBox}>
						<div className={styles.filterLabel}>
							{firstName} Videos
						</div>
						<div className={styles.filterBox}>
							<Form.Group className={styles.colFormcalss} >
								<Form.Control
									as = 'select'
									required
									name='generalCast'
									className="selectOpt"
									onChange = {handleChangeVideoTitle}
								>
									{videoData?.length > 0 ? 
										videoData.map((item) => (
										<option value={item.id} key={item.id} >{item.title}</option>
									)) : 
									<option>Select</option>
								}
								</Form.Control> 
							</Form.Group>  
						</div>
					</div>
					<div className={styles.ModelVideoSec}>
						<div className={styles.ModelAgeName}>
							<h4 className={styles.AgrNameTxt}>
								{talentProfileData &&
									<span>{new Date().getFullYear()-talentProfileData?.yc_models_model?.birth_date.split("-")[0]}</span>
								}
								{talentProfileData?.name}
							</h4>
							<p className={styles.MdlLabel}>{talentProfileData?.modelCategories[0]?.modelCategoriesName?.name}</p>
							<p className={styles.MdlLabel}>{talentProfileData?.modelCategories[1]?.modelCategoriesName?.name}</p>
						</div>
						{videoData.length > 0 ?
							<div className={styles.modelVideo}>
								{videoData.map((item, index) => {
									return item.id === parseInt(videoId) &&
									<>
										<VideoWrapper>
										<ReactPlayer
											url={`${process.env.REACT_APP_YOUCAST_USER_VIDEOS_PATH}/${process.env.REACT_APP_MODE}/${location.id}/account/${item.video_url}`}
											controls={true}
											light={`${process.env.REACT_APP_YOUCAST_USER_VIDEOS_PATH}/${process.env.REACT_APP_MODE}/${location.id}/account/thumbnail/th${item.video_thumbnail}`}
											className={styles.reactPlayer}
											playing={true}
											muted={true}
										/>
										</VideoWrapper>

									{/* <img key={index} src={`${process.env.REACT_APP_YOUCAST_USER_VIDEOS_PATH}/${talentProfileData?.id}/account/thumbnail/th${item?.video_thumbnail}`} alt={Modelvid} className={styles.modelvideoClass}/>
									<img key={item?.video_thumbnail} src={Playbtn} alt={Playbtn} className={styles.Playbtn} onClick={() => {
										setVideoPlayerModal(true);
										setVideoPath({...videoPath, thumbnail: item.video_thumbnail, Video_url: item.video_url})
									}} /> */}
								</>
								})}
							</div> : 
							<div className={styles.modelVideo}>
								<img src={Modelvid} alt={Modelvid} className={styles.modelvideoClass}/>
						</div>
						}
					</div>
				</div>
			</div>
			<div ref={(e) => paginationRef.current[2] = e} className={styles.DetailAreaBox}>
			  <div className={styles.detailWrap}>
				<div className={styles.detailAreaDivFix}>
					<div className={styles.modelVidLogo}>
						<div className={styles.ModelLogoIcon}>
							<div className={styles.sideBarlogoTxt}>
								<h4 className={styles.sidelgtxt}>Profile Created At</h4>
								<p className={styles.sidelgsubtxt}>Youcast Studios</p>
							</div>
							<div className={styles.sideImgbox} onClick={setTalentAsFavorite}>
								<FavoriteIcon className={isFavorite === 1 ? styles.favIcon : styles.nonFavIcon} />
								<p className={styles.sideimgsvgtxt}>{isFavorite === 1 ? "Remove Favorite" : "Favorite"}</p>
							</div>
						</div>	
						<div className={styles.modelIconBtn}>
						<button onClick={() => {!projectId ? history.push(`/director/talents?talentId=${location.id}`): saveProjectRoleOption()}} className={styles.modlBtn}>Cast {firstName}</button>
						</div>					
					</div>

						<div className={styles.modelTalentDesc}>
							<p className={styles.talentSolaTitle}>{firstName} Talents</p>
							<div className={styles.modelTalentBox}>
								{talentProfileData?.modelCategories.map((item, index) => {
									return item?.modelCategoriesName?.parent_category_id === null &&
										<div key={index} className={styles.talentSecBox}>
											<YellowSmlcircle className={styles.detaiListIcon} />
											<div className={styles.detailTexModel}>
												<p className={styles.DetailLabelHead}>{item?.modelCategoriesName?.name}</p>
												<ul className={styles.DetailOfHead}>
													{talentProfileData?.modelCategories.map((element, index) => {
														return element?.modelCategoriesName?.parent_category_id === item?.model_category_id &&
															<li key={index} className={styles.detailTalentList}>{element?.modelCategoriesName?.name}</li>
													})}
												</ul>
											</div>
										</div>
								})
								}
							</div>
						</div>

					<div className={styles.ModelsAllDetail}>
						<p className={styles.physDetailTitle}>Physical Details</p>
						<div className={styles.physDetailBox}>
							<div className={styles.mdlDetailRgtSD}>
								<YellowSmlcircle className={styles.detaiListIcon}/>
								<div className={styles.detailTexModel}>
									<p className={styles.DetailLabelHead}>Name</p>
									<p className={styles.DetailOfHead}>{talentProfileData?.name}</p>
								</div>
							</div>
							<div className={styles.mdlDetailRgtSD}>
								<YellowSmlcircle className={styles.detaiListIcon}/>
								<div className={styles.detailTexModel}>
									<p className={styles.DetailLabelHead}>Age</p>
									<p className={styles.DetailOfHead}>{new Date().getFullYear()-talentProfileData?.yc_models_model?.birth_date.split("-")[0]}</p>
								</div>
							</div>
							<div className={styles.mdlDetailRgtSD}>
								<YellowSmlcircle className={styles.detaiListIcon}/>
								<div className={styles.detailTexModel}>
									<p className={styles.DetailLabelHead}>City</p>
									<p className={styles.DetailOfHead}>{talentProfileData?.city?.full_city_name}</p>
								</div>
							</div>
							<div className={styles.mdlDetailRgtSD}>
								<YellowSmlcircle className={styles.detaiListIcon}/>
								<div className={styles.detailTexModel}>
									<p className={styles.DetailLabelHead}>Nationality</p>
									<p className={styles.DetailOfHead}>{talentProfileData?.yc_models_model?.country?.printable_name === undefined ? "--" : talentProfileData?.yc_models_model?.country?.printable_name}</p>
								</div>
							</div>
							<div className={styles.mdlDetailRgtSD}>
								<YellowSmlcircle className={styles.detaiListIcon}/>
								<div className={styles.detailTexModel}>
									<p className={styles.DetailLabelHead}>Weight</p>
									<p className={styles.DetailOfHead}>{talentProfileData?.yc_models_model?.weight?.title}</p>
								</div>
							</div>
							<div className={styles.mdlDetailRgtSD}>
								<YellowSmlcircle className={styles.detaiListIcon}/>
								<div className={styles.detailTexModel}>
									<p className={styles.DetailLabelHead}>Height</p>
									<p className={styles.DetailOfHead}>{talentProfileData?.yc_models_model?.height?.title.split("  ")[talentProfileData?.yc_models_model?.height?.title.split("  ").length-1]}</p>
								</div>
							</div>
							<div className={styles.mdlDetailRgtSD}>
								<YellowSmlcircle className={styles.detaiListIcon}/>
								<div className={styles.detailTexModel}>
									<p className={styles.DetailLabelHead}>Hair Color</p>
									<p className={styles.DetailOfHead}>{talentProfileData?.yc_models_model?.hair_color?.title}</p>
								</div>
							</div>
							<div className={styles.mdlDetailRgtSD}>
								<YellowSmlcircle className={styles.detaiListIcon}/>
								<div className={styles.detailTexModel}>
									<p className={styles.DetailLabelHead}>Hair Type</p>
									<p className={styles.DetailOfHead}>{talentProfileData?.yc_models_model?.hair_type?.title}</p>
								</div>
							</div>
							<div className={styles.mdlDetailRgtSD}>
								<YellowSmlcircle className={styles.detaiListIcon}/>
								<div className={styles.detailTexModel}>
									<p className={styles.DetailLabelHead}>Body Shape</p>
									<p className={styles.DetailOfHead}>{talentProfileData?.yc_models_model?.body_shape?.title}</p>
								</div>
							</div>
							<div className={styles.mdlDetailRgtSD}>
								<YellowSmlcircle className={styles.detaiListIcon}/>
								<div className={styles.detailTexModel}>
									<p className={styles.DetailLabelHead}>Skin tone</p>
									<p className={styles.DetailOfHead}>{talentProfileData?.yc_models_model?.skin_tone?.title}</p>
								</div>
							</div>
							<div className={styles.mdlDetailRgtSD}>
								<YellowSmlcircle className={styles.detaiListIcon}/>
								<div className={styles.detailTexModel}>
									<p className={styles.DetailLabelHead}>Eye Color</p>
									<p className={styles.DetailOfHead}>{talentProfileData?.yc_models_model?.eye_color?.title}</p>
								</div>
							</div>
							<div className={styles.mdlDetailRgtSD}>
								<YellowSmlcircle className={styles.detaiListIcon}/>
								<div className={styles.detailTexModel}>
									<p className={styles.DetailLabelHead}>Language</p>
									<p className={styles.DetailOfHead}>{talentProfileData?.yc_models_model?.languages === null ? "--" : talentProfileData?.yc_models_model?.languages}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		</div>
    <div className={styles.leftRightHands}>
        {pageCount !== 1 &&
          <div className={styles.leftHandArrow} onClick={() => {
            executeScrollPagination(1);
            setPageCount(1);
          }}>
            <LeftHand className={styles.lefthanfSvg} />
            <p className={styles.lefthandtext}>Previous Profile Data</p>
          </div>}
        <Footer history={props.history} />
        {pageCount !== 2 &&
          <div className={styles.rightHandarrow} onClick={() => {
            setPageCount(2);
            executeScrollPagination(2);
          }} >
            <p className={styles.Righthandtext}>More Profile Data</p>
            <RightHand className={styles.lefthanfSvg} />
          </div>}
      </div>

		{isImageModalOpen && (
        <ImageModal
          src={ modalImage }
          show={ isImageModalOpen }
          hide={ () => setIsImageModalOpen(false) }
        />
      )}

			{/* {videoPlayerModalOpen &&
				<VideoPlayerModal
					show={ videoPlayerModalOpen }
					hide={ () => setVideoPlayerModal(false) }
					data={ videoPath }
				/>
			} */}
	</>
	)
}

export default ProfileTalent;

