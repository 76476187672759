import React from "react";
import styles from "./normalSidebar.module.scss";
import sidebaricon from '../../../assets/icons/sidebaricon.png';

const NormalSidebar = () => {
    return (
        <div className={styles.sidebar}>
          <div className={styles.sbImgbox}>
            <img src={sidebaricon} alt={sidebaricon} />
            <p className={styles.sbIcontxt}>Youcast</p>
          </div>            
        </div>
    )
}

export default NormalSidebar;