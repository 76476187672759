import React from "react";
import { useDispatch } from 'react-redux';
import inlineLogo from "../../../src/app/images/inline-logo.png";
import userImg from "../../../src/assets/images/default_image.png";
import { Image } from "react-bootstrap";

import {
  Link,
} from "react-router-dom";
import "./sidebar.scss";

const menuItems = [
  { title: "Dashboard", path: "/admin/dashboard" },
  { title: "Users", path: "/admin/users" },
  { title: "Accounts", path: "/admin/accounts" },
  { title: "subscriptions", path: "/admin/subscriptions" },
  { title: "Admin Accounts", path: "/admin/user-accounts"},
  { title: "Projects", path: "/admin/projects-list"}
  // {title: 'calender' , path:'' },
  // {title: 'callbacks' , path:'' },
  // {title: 'favorites' , path:'' },
  // {title: 'posts' , path:'' },
  // {title: 'notification' , path:'' },
];


const Sidebar = (props) => {
  const dispatch = useDispatch();
  return (
    <div className="sidebarWrapper">
      <div className="topRow">
        <div className="topLogo">
          <img src={inlineLogo} alt="inline-logo" />
        </div>
        <div className="userBox">
          <Image src={userImg} roundedCircle />
          <div className="userName">
            <h4>Admin</h4>
            {/* <p>Chief Executive Officer</p> */}
          </div>
        </div>
        <div className="sidebarLinks">
          <ul>
            <SidebarItems
              history={props.history}
            />
          </ul>
        </div>
      </div>
      <div className="bottomRow">
        <div className="logoutBox">
          <span onClick={() => {
            dispatch({ type: 'USER_LOGOUT' })
            props.history.push('/admin/login');
          }}>
            <Link to="/admin/login">Logout</Link>
          </span>
        </div>
      </div>
    </div>
  );
};
const SidebarItems = (props) => {
  let items = menuItems.map((item, i) => {
    return (
      //use daynamicly class for selected menu
      <Link to={item.path} key={i}>
        <li
          className={props.history.location.pathname === item.path || (item.title === 'Projects' && props.history.location.pathname.includes(`/admin/project-detail/`)) ? 'selectedMenu' : ''}
        >
          {item.title}
        </li>
      </Link>
    );
  });
  return <>{items}</>;
};

Sidebar.propTypes = {};

export default Sidebar;
