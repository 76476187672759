import React from "react";
import { connect } from "react-redux";
import {
  Switch,
  Route,
  useRouteMatch,
} from "react-router-dom";
import Dashboard from "./projects/index";
import CreateEditProject from "./create-edit-project/index";
import ProjectDashboard from "./project-dashboard/index";
import ProjectChildList from "./project-child-lists/index";
import CastTalents from "./talents/index";
import TalentProfile from "./talent-profile";
import ProjectRolesBreakdown from "./project-roles-breakdown";
import "./main-layout.scss";
import ScenesDetail from "./scenes-detail";


const DirectorLayoutComponent = (props) => {
  let match = useRouteMatch();

  return (
    <div className="mainWrapper">
			<div className="contentBoxDirector">
        <Switch>
          <Route
            exact
            path={`${match.path}/projects`}
            component={Dashboard}
          />

          <Route
            exact
            path={`${match.path}/projects/create`}
            component={CreateEditProject}
          />

          <Route
            exact
            path={`${match.path}/projects/update/:id`}
            component={CreateEditProject}
            
          />

          <Route
            exact
            path={`${match.path}/project-dashboard/:id`}
            component={ProjectDashboard}
          /> 

          <Route
            exact
            path={`${match.path}/:type/lists/:projectId`}
            component={ProjectChildList}
          />

          <Route
            exact
            path={`${match.path}/talent-profile/:id`}
            component={TalentProfile}
          />

          <Route
            exact
            path={`${match.path}/talents`}
            component={CastTalents}
          />
          
          <Route
            exact
            path={`${match.path}/project-roles-breakdown/:projectId`}
            component={ProjectRolesBreakdown}
          />

          <Route
            exact
            path={`${match.path}/scenes-detail/:projectId/:episodeId?`}
            component={ScenesDetail}
          />

        </Switch>
				
      </div>
    </div>
  );
};

const MapStatetoProps = (state) => {
  return {
    
  };
};

const DirectorLayout = connect(MapStatetoProps, { })(
    DirectorLayoutComponent
);

export default DirectorLayout;
