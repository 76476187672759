import { getTalentsList } from "../../services/talentsService";
import {
	TALENTS_LISTING_SUCCESS,
	TALENTS_LISTING_ERROR
} from "./../types";


//when error is occurred
const Error = (error, type) => {
	return {
		type,
		payload: error,
	};
};

//when successfully fetching data
const Success = (data, type) => {
	return {
		type,
		payload: data.data,
	};
};

export function getTalentsListing(isFavourite, options, talentId) {
	return async (dispatch) => {
		try {
			let data = await getTalentsList(isFavourite, options, talentId);
			dispatch(Success(data, TALENTS_LISTING_SUCCESS));
		} catch (e) {
			Error(e, TALENTS_LISTING_ERROR);
		}
	};
}  
  