import React, { PureComponent } from "react";
import Filters from "../../../components/filters/filters";
import UserList from "../../../components/userlist/userlist";
import UserAnalytics from "../../../components/userAnalytics/userAnalytics";
import "./dashboard.scss";
import { connect } from "react-redux";
import { getModelListing, getUserCount } from "./../../../redux/Users/actions";
import Spinner from "react-bootstrap/Spinner";
import { updateUserStatusByIdApi, adminFavStatusByIdApi } from "../../../redux/Users/api";
class Dashboard extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      limit: 10,
      offSet: 0,
      ModelList: [],
      preferences: '',
      filters: {},
      activeAccounts: 1
    };
  }

  componentDidMount() {
    let params = this.getParams(null);
    this.props.getModelListing(params);
    this.props.getUserCount();
  }

  componentDidUpdate(prevProps, prevState) {
    //compare prev props with current props so that we dont have to render component again to avoid infinite looping
    if (this.props.ModelList !== prevProps.ModelList) {
      this.setState({
        ...this.state,
        ModelList: [...this.state.ModelList, ...this.props.ModelList],
      });
    }

    //For pagination when state is changed call api again
    if (prevState.offSet !== this.state.offSet) {
      let params = this.getParams(this.state.filters);
      this.props.getModelListing(params);
    }
  }

  getParams = (filterParams) => {
    let params = {
      limit: this.state.limit,
      offset: this.state.offSet,
      paginate: true,
      preferences: this.state.preferences,
      activeAccounts: this.state.activeAccounts
    };

    for (let key in filterParams) {
      params[key] = filterParams[key];
    }
    return params;
  };

  handleFilterChange = (filtersData) => {
    this.setState({
      ModelList: [],
      limit: 10,
      offSet: 0,
      filters: filtersData
    });

    let params = this.getParams(filtersData);
    this.props.getModelListing(params);
  };

  handleLoadMore = () => {
    this.setState({ ...this.state, offSet: this.state.offSet + 10 });
  };

  updateUserStatus = async (id) => {
    await updateUserStatusByIdApi(id);
  }

  preferenceCHange = (data) => {
    this.setState({
      ModelList: [],
      limit: 10,
      offSet: 0,
      preferences: data
    })
    let params = this.getParams({ preferences: data });
    this.props.getModelListing(params);
  }

  favBtn = async (id, favouriteStatus) => {
    await adminFavStatusByIdApi(id, favouriteStatus);

    this.setState({
      ModelList: [],
      limit: 10,
      offSet: 0,
      // preferences: data
    })
    let params = this.getParams({ preferences: [] });
    this.props.getModelListing(params);
  }

  render() {
    const { UserCount } = this.props;
    const { activeUsers, newUsers } = UserCount;
    const totalUsers = this.props.modelCount;
    return (
      <div className="contentWrapper">
        <div className="topRow">
          <h4>Giving Talents a better chance</h4>
        </div>
        <div className="contentRow">
          <div className="userDatacol">
            <Filters
              filterParams={this.props.filters}
              onFilterChange={(filtersData) =>
                this.handleFilterChange(filtersData)
              }
              preferences={this.state.preferences}
              preferenceCHange={this.preferenceCHange}
            />
            {!this.props.loading && this.state.ModelList.length && (
              <UserList
                data={this.state.ModelList}
                type={"dashboard"}
                totalCount={totalUsers}
                loadMore={this.handleLoadMore}
                favBtn={this.favBtn}
              />
            )}
            {this.props.loading && (
              <div className="d-flex justify-content-center p-5">
                <Spinner animation="border" role="status">
                  <span style={{ color: "white" }} className="sr-only">
                    Loading...
                  </span>
                </Spinner>
              </div>
            )}
            {!this.props.loading && !this.state.ModelList.length && (
              <div className="d-flex justify-content-center p-5">
                <small style={{ color: "white", fontSize: "20px" }}>
                  No records found
                </small>
              </div>
            )}
          </div>
          <div className="userAnalyticscol">
            <UserAnalytics users={"New Users"} number={newUsers} />
            <UserAnalytics users={"Active Accounts"} number={activeUsers} />
            <UserAnalytics users={"User Count"} number={totalUsers} />
          </div>
        </div>
      </div>
    );
  }
}

const MapStatetoProps = (state) => {
  return {
    ModelList: state.User.data.ModelList,
    UserCount: state.User.data.UserCount,
    filters: state.Filter,
    loading: state.User.loading,
    modelCount: state.User.data.modelCount
  };
};

export default connect(MapStatetoProps, { getModelListing, getUserCount })(
  Dashboard
);
