import {
  SHOW_LOADING,
  USER_LISTING_SUCCESS,
  USER_LISTING_ERROR,
  USER_COUNT_SUCCESS,
  USER_COUNT_ERROR,
  MODEL_LISTING_SUCCESS,
  MODEL_LISTING_COUNT,
  MODEL_LISTING_ERROR,
  USER_CREATE_SUCCESS,
  USER_CREATE_FAILURE,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAILURE,
  GET_USER_BY_ID_SUCCESS,
  GET_USER_BY_ID_FAILURE,
  UPDATE_SUBSCRIPTIONS_BY_ID_SUCCESS,
  UPDATE_SUBSCRIPTIONS_BY_ID_FAILURE,
  UPDATE_USER_STATUS_SUCCESS,
  UPDATE_USER_STATUS_FAILURE,
  ADMIN_FAV_STATUS_SUCCESS,
  ADMIN_FAV_STATUS_FAILURE,
  UPDATE_USER_INFO
} from "./../types";

import {
  UserListingApi,
  UserCountApi,
  ModelListingApi,
  createUserApi,
  deleteUserApi,
  getUserByIDapi,
  updateUserByIDapi,
  updateSubscriptionsByIDapi,
  updateUserStatusByIdApi,
  adminFavStatusByIdApi
} from "./api";

//fetching data
const showloading = () => {
  return {
    type: SHOW_LOADING,
  };
};

//when error is occurred
const Error = (error, type) => {
  return {
    type,
    payload: error,
  };
};

//when successfully fetching data
const Success = (data, type) => {
  return {
    type,
    payload: data.data.data,
  };
};

const countTotal = (data, type) => {
  return {
      type,
      payload: data?.data?.count || 0
  }
}

const UpdateInfo = (data, type) => {
  return {
    type,
    payload: data
  }
}

export function getModelListing(options) {
  return async (dispatch) => {
    try {
      dispatch(showloading());
      let data = await ModelListingApi(options);
      dispatch(Success(data, MODEL_LISTING_SUCCESS));
      dispatch(countTotal(data, MODEL_LISTING_COUNT));
    } catch (e) {
      Error(e, MODEL_LISTING_ERROR);
    }
  };
}

//Get lists of Users
export const getUserListing = (options) => {
  return async (dispatch) => {
    try {
      dispatch(showloading());
      let data = await UserListingApi(options);

      dispatch(Success(data, USER_LISTING_SUCCESS));
    } catch (e) {
      Error(e, USER_LISTING_ERROR);
    }
  };
};

//get user count
export const getUserCount = (options) => {
  return async (dispatch) => {
    try {
      dispatch(showloading());
      let data = await UserCountApi();
      dispatch(Success(data, USER_COUNT_SUCCESS));
    } catch (e) {
      Error(e, USER_COUNT_ERROR);
    }
  };
};

export const createUser = (user) => {
  return async (dispatch) => {
    dispatch(showloading());
    try {
      let data = await createUserApi(user);
      dispatch(Success(data.data, USER_CREATE_SUCCESS));
    } catch (error) {
      dispatch(Error(error.response.data.msg, USER_CREATE_FAILURE));
    }
  };
};

export const deleteUser = (id) => {
  return async (dispatch) => {
    dispatch(showloading());
    try {
      let data = await deleteUserApi(id)
      dispatch(Success(data.data, USER_DELETE_SUCCESS));
    } catch (error) {
      dispatch(Error(error.response.data.msg, USER_DELETE_FAILURE));
    }
  };
};

export const getUserByID = (id) => {
  return async (dispatch) => {
    dispatch(showloading());
    try {      
      let data = await getUserByIDapi(id);
      dispatch(Success(data, GET_USER_BY_ID_SUCCESS));
    } catch (error) {
      dispatch(Error(error, GET_USER_BY_ID_FAILURE));
    }
  };
}

export const updateUserByID = (id, user) => {
  return async (dispatch) => {
    dispatch(showloading());
    try {
      let data = await updateUserByIDapi(id, user);
      return data;
    } catch (error) {
      return error.response.data;
    }
  };
}

export const updateSubscriptionsByID = (id, user) => {
  return async (dispatch) => {
    dispatch(showloading());
    try {
      let data = await updateSubscriptionsByIDapi (id, user);
      dispatch(Success(data.data, UPDATE_SUBSCRIPTIONS_BY_ID_SUCCESS));
    } catch (error) {
      dispatch(Error(error.response.data.msg, UPDATE_SUBSCRIPTIONS_BY_ID_FAILURE));
    }
  };
}

export const updateUserStatusById = (id, userData) => {
  return async (dispatch) => {
    dispatch(showloading());
    try {
      let data = await updateUserStatusByIdApi(id, userData);
      dispatch(Success(data.data, UPDATE_USER_STATUS_SUCCESS));
    } catch (error) {
      dispatch(Error(error.response.data.msg, UPDATE_USER_STATUS_FAILURE));
    }
  };
}

export const adminFavStatusById = (id) => {
  return async (dispatch) => {
    dispatch(showloading());
    try {
      let data = await adminFavStatusByIdApi(id);
      dispatch(Success(data.data, ADMIN_FAV_STATUS_SUCCESS)); 
    } catch (error) {
      dispatch(Error(error.response.data.msg, ADMIN_FAV_STATUS_FAILURE));
      }
    }
  }


export const setUserInfo = (user) => {
  return (dispatch) => {
    try {
      dispatch(UpdateInfo(user, UPDATE_USER_INFO));
    } catch (error) {
      dispatch(Error(error, GET_USER_BY_ID_FAILURE));
    }
  };
};
