import React, { useEffect } from "react";
import { connect } from "react-redux";

import {
  Switch,
  Route,
  useRouteMatch,
} from "react-router-dom";
import Dashboard from "./dashboard/dashboard";
import Users from "./users/users";
import Sidebar from "../../components/sidebar/sidebar";
import Accounts from "./accounts/accounts";
import Subscriptions from "./subsciptions/subscriptions";
import AccountDetail from "./accountDetail/index.js";
import "./main-layout.scss";
import { getFiltersList } from "../../redux/Filters/actions";
import UserAccounts from "./userAccounts";
import ProjectsList from "./projectsList";
import ProjectDetail from "./projectDetail";

const AuthLayoutComponent = (props) => {
  let match = useRouteMatch();

  useEffect(() => {
    // check if filters exists in app, if not call Api to get filters list
    let isFilters = props.isFilters;
    if (!isFilters) {
      props.getFiltersList();
    }
  });

  return (
    <div className="mainWrapper">
      <div className="sideBar">
        <Sidebar history={props.history} />
      </div>

      <div className="contentBox">
        <Switch>
          <Route
            exact
            path={`${match.path}/dashboard`}
            component={Dashboard}
          />
          <Route path={`${match.path}/users`}>
            <Users />
          </Route>
          <Route path={`${match.path}/accounts`}>
            <Accounts />
          </Route>
          <Route path={`${match.path}/subscriptions`}>
            <Subscriptions />
          </Route>
          <Route
            path={`${match.path}/dashboard/:id`}
            component={AccountDetail}
          />
          
          <Route
            path={`${match.path}/user-accounts`}
            component={UserAccounts}
          />

          <Route
            path={`${match.path}/projects-list`}
            component={ProjectsList}
          />

          <Route
            path={`${match.path}/project-detail/:projectId`}
            component={ProjectDetail}
          />
        </Switch>
      </div>
    </div>
  );
};

const MapStatetoProps = (state) => {
  return {
    isFilters: state.Filter.list.loaded,
  };
};

const AuthLayout = connect(MapStatetoProps, { getFiltersList })(
  AuthLayoutComponent
);

export default AuthLayout;
