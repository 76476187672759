import {
  GET_SCENES_LIST_BY_EPISODE_ID_SUCCESS,
  GET_SCENES_LIST_BY_EPISODE_ID_ERROR,
	SET_SIDEBAR_PAGE_VIEW_TYPE
} from "./../types";

const Initial_State = {
	loading: false,
	data: {
		scenesList: [],
		scenesCount: 0,
		sidebarPageViewType: "view"
	},
	error: ''
};

export const SceneReducer = (state = Initial_State, action) => {
  switch (action.type) {
		case GET_SCENES_LIST_BY_EPISODE_ID_SUCCESS:
			return {
				...state,
				data: { 
					...state.data,
					scenesList: action.payload?.rows,
					scenesCount: action.payload?.count
				}
			}
		case GET_SCENES_LIST_BY_EPISODE_ID_ERROR:
			return {
				...state,
				error: action.payload
			}
		case SET_SIDEBAR_PAGE_VIEW_TYPE:
			return {
				...state,
				data: {
					...state.data,
					sidebarPageViewType: action.payload
				}
			}
		default:
			return state
	}
};