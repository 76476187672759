import React, { useState, useEffect } from "react";
import { updateUserByID, getModelListing } from "./../../redux/Users/actions";
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { getCities } from "../../redux/City/actions";
import { createNewUser } from "../../services/userService";
import { createLoginUser, updateLoginUser } from "../../services/loginUserService";
import { toast } from "react-toastify";
import { nationalityData } from "../../constant/nationality-json";

const CustomModal = (props) => {
  const [formData, setFormData] = useState({
    name: "",
    userNameCanonical: "",
    email: "",
    emailCanonical: "",
    password: "",
    phone: "",
    enabled: 1,
    dateOfBirth: new Date(),
    locationCountryISOCode: "EG",
    city_id: "",
    roles: [],
  });
  const loginUserId = props?.loginUserData?.id === undefined ? null : props?.loginUserData?.id;
  const handleUpdate = (e) => {    
    if (e && e.target) {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
  };

  useEffect(() => {
    if (props?.user) {
      const getdata = {
        name: props?.user?.name,
        userNameCanonical: props?.user?.username_canonical,
        phone: props?.user?.phone,
        email: props?.user?.email,
        emailCanonical: props?.user?.email_canonical,
        dateOfBirth: props?.user?.yc_models_model?.birth_date,
        locationCountryISOCode: props?.user?.location_country_iso_code,
        city_id: props?.user?.city_id
      };
      setFormData(getdata);
    } else {
      resetFormData();
    }
    props.getCities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user]);

  useEffect(() => {
    if (props.loginUserData) {
      const getdata = {
        name: props?.loginUserData?.name,
        phone: props?.loginUserData?.phone_number,
        email: props?.loginUserData?.email,
        dateOfBirth: props?.loginUserData?.date_of_birth,
        password: ""
      };
      setFormData(getdata);
    } else {
      resetFormData();
    }
  }, [props.loginUserData]);

  const resetFormData = () => {
    setFormData({
      name: "",
      userNameCanonical: "",
      email: "",
      emailCanonical: "",
      password: "",
      phone: "",
      enabled: 1,
      dateOfBirth: new Date(),
      locationCountryISOCode: "EG",
      city_id: "",
      roles: [],
    });
  }
  
  return (
    <Modal show={props.show} onHide={props.handleClose}>
      <Modal.Header closeButton>
        {props.pageType === "users" ?
          <Modal.Title>User Details</Modal.Title> :
          props.roleId === 1 ? <Modal.Title>Admin Detail</Modal.Title> : <Modal.Title>Director Detail</Modal.Title>
        }
      </Modal.Header>
      <Modal.Body>
        <form
          className="popup-form-cus"
          onSubmit={async (e) => {
            e.preventDefault();
            const data = {
              name: formData.name,
              username: formData.name,
              username_canonical:  formData.userNameCanonical ? formData.userNameCanonical : formData.name,
              email: formData.email,
              email_canonical: formData.emailCanonical ? formData.emailCanonical : formData.email,
              password: formData.password,
              phone: formData.phone,
              enabled: formData.enabled,
              roles: 0,
              super_admin: 0,
              location_country_iso_code: formData.locationCountryISOCode,
              dateOfBirth: formData.dateOfBirth,
              city_id: formData.city_id,
              role_id: props?.roleId
            };

            let saveStatus = false;
            let result;
            let resetForm = false;
            if (props.pageType === "users") {
              if (props.user.id) {
                result = await props.updateUserByID(props.user.id, data);
              } else {
                resetForm = true;
                result = await createNewUser(data);
              }
              if (!result?.status) {
                toast.error(result.msg, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              } else {
                saveStatus = true;
                if (resetForm) {
                  toast.success("User has been created successfully...");
                  resetFormData();
                } else {
                  toast.success("User has been updated successfully...");
                }
              }
            } else {
              if (loginUserId) {
                result = await updateLoginUser(data, loginUserId);
              } else {
                resetForm = true;
                result = await createLoginUser(data);
              };
              if (!result?.status) {
                toast.error(result.errorMsg);
              } else {
                saveStatus = true;
                props.updateLoginUsersList();
                if (resetForm) {
                  toast.success(`${props?.isAdminOrDirector} has been created successfully`);
                  resetFormData();
                } else {
                  toast.success(`${props?.isAdminOrDirector} has been updated successfully`);
                }
              };
            };
            if (saveStatus) {
              props.handleClose();
              props.pageType === "users" && props.refreshUserListing();
            }
          }}
        >
          <label>
            Name:
            <input
              type="text"
              name="name"
              placeholder="name"
              required
              value={formData.name}
              onChange={handleUpdate}
            />
          </label>
          <label>
            Phone:
            <input
              type="text"
              name="phone"
              placeholder="phone"
              required
              value={formData.phone}
              onChange={handleUpdate}
            />
          </label>
          <label>
            Email:
            <input
              type="text"
              name="email"
              placeholder="email"
              required
              value={formData.email}
              onChange={handleUpdate}
            />
          </label>
          <label>
            Password:
            <input
              type="password"
              name="password"
              placeholder="password"
              value={formData.password}
              required = {loginUserId || props.pageType === "users" ? false : true}
              onChange={handleUpdate}
            />
          </label>

          <label>
            Date of Birth:
            <input
              type="date"
              name="dateOfBirth"
              value={formData.dateOfBirth}
              required
              onChange={(date) => handleUpdate(date)}
            />
          </label>

          {props.pageType === "users" &&
            <>
              <label>
                Location Country ISO Code:
                <select name="locationCountryISOCode" onChange={handleUpdate} value={formData.locationCountryISOCode} required>
                  <option value="">Select Country</option>
                  {nationalityData &&
                    nationalityData.length > 0 &&
                    nationalityData.map((data) => (
                      <option key={data.code} value={data.code}>{data.name}</option>
                    ))}
                </select>
              </label>

              <label>
                Select City
                <select name="city_id" onChange={handleUpdate} value={formData.city_id} required>
                  <option value="">Select City</option>
                  {props.Cities &&
                    props.Cities.length > 0 &&
                    props.Cities.map((data) => (
                      <option key={data.id} value={data.id}>{data.full_city_name}</option>
                    ))}
                </select>
              </label>
            </>
          }
          <br/>

          <Button variant="primary" type="submit">
            Submit
          </Button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    User: state.User,
    Cities: state?.CityReducer.data.City,
    ModelList: state.User.data.ModelList,
  };
};

export default connect(mapStateToProps, { updateUserByID, getCities, getModelListing })(CustomModal);
