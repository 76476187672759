import React, { PureComponent } from "react";
import Filters from "../../../components/filters/filters";
import UserList from "../../../components/userlist/userlist";
import Dropdown from 'react-bootstrap/Dropdown'
import SearchInput from "../../../components/searchInput/searchInput";
import "./accounts.scss";
import { connect } from "react-redux";
import { getModelListing } from "./../../../redux/Users/actions";
import Spinner from "react-bootstrap/Spinner";
import { deleteMultiAccountsApi } from '../../../redux/Accounts/api'
import { debounce } from "lodash";
import { deleteUserApi } from "../../../redux/Users/api"

class Accounts extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      limit: 10,
      offSet: 0,
      ModelList: [],
      activeAccounts: 1,
      arrayDelete: [],
      searchTypes: "name",
      filters: {},
      searchedValue: ""
    };
  }

  componentDidMount() {
    let params = this.getParams(null);
    this.props.getModelListing(params);    
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.ModelList !== prevProps.ModelList) {
      this.setState({
        ...this.state,
        ModelList: [...this.state.ModelList, ...this.props.ModelList],
      });
    }

    if (prevState.offSet !== this.state.offSet) {
      let params = this.getParams(this.state.filters);
      this.props.getModelListing(params);
    }
  }

  getParams = (filterParams) => {
    let params = {
      limit: this.state.limit,
      offset: this.state.offSet,
      paginate: true,
      activeAccounts: this.state.activeAccounts,
      searchTypes: this.state.searchTypes
    };

    for (let key in filterParams) {
      params[key] = filterParams[key];
    }

    if (this.state.searchedValue !== "") {
      params["search"] = this.state.searchedValue;
    }
    return params;
  };

  handleFilterChange = (filtersData) => {
    this.setState({
      ModelList: [],
      limit: 10,
      offSet: 0,
      filters: filtersData
    });

    let params = this.getParams(filtersData);
    this.props.getModelListing(params);
  };

  handleLoadMore = () => {
    this.setState({ ...this.state, offSet: this.state.offSet + 10 });
  };

  handleChangeCheckbox = (e, id) => {
    let arrayDelete = this.state.arrayDelete;
    if (arrayDelete.includes(id)) {
      const index = arrayDelete.indexOf(id);
      if (index > -1) {
        arrayDelete.splice(index, 1);
      }
    } else {
      arrayDelete.push(id)
    }
    this.setState({
      arrayDelete: arrayDelete
    })
  }

  allDelete = async () => {
    let items = this.state.arrayDelete;
    await deleteMultiAccountsApi(items);    
    let params = this.getParams(null);
    this.props.getModelListing(params);
  }

  searchInput = (value) => {
    this.setState({
      ModelList: [],
      limit: 10,
      offSet: 0,
      searchedValue: value
    });
    
    let params = this.getParams(this.state.filters);
    this.props.getModelListing(params);
  }
  
  searchHandler = debounce((value) => {
    this.searchInput(value);
  }, 1000);


  handleDeleteAccount = async (id) => {
    await deleteUserApi(id);
    this.setState({
      ModelList: this.state.ModelList.filter(x => x.id !== id)
    });
    let params = this.getParams(null);
    this.props.getModelListing(params);
  }

  render() {
    const activeUsers = this.props.modelCount;
    return (
      <div className="contentWrapper">
        <div className="topRow">
          <h4>Giving Talents a better chance</h4>
        </div>
        <div className="contentRow">
          <div className="userDlatacol">
            <Filters
              filterParams={this.props.filters}
              onFilterChange={(filtersData) =>
                this.handleFilterChange(filtersData)
              }
            />
            <div className="acoountHead">
              <div className="actionCol">
                <div className="deleteAll">
                  <button onClick={() => this.allDelete()}>Delete Selected</button>
                </div>
                <div style={{ color: "white" }}>
                  <h2>Accounts {activeUsers}</h2>
                </div>
              </div>
              <div className="btnsCol">
              <div className='dropdownSearchAccount defaultDropdown'>
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic">
                    {this.state.searchTypes}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onSelect={() => this.setState({
                      searchTypes: 'name'
                    })}>by name</Dropdown.Item>
                    <Dropdown.Item onSelect={() => this.setState({
                      searchTypes: 'phone'
                    })}>by phone</Dropdown.Item>
                    <Dropdown.Item onSelect={() => this.setState({
                      searchTypes: 'age'
                    })}>by age</Dropdown.Item>
                    <Dropdown.Item onSelect={() => this.setState({
                      searchTypes: 'status'
                    })}>by status</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="userSearch">
                <SearchInput searchHandler={this.searchHandler} searchInput={this.searchInput}/>
              </div>
              </div>
            </div>

            {!this.props.loading && this.state.ModelList.length && (
              <UserList
                data={this.state.ModelList}
                type={"accounts"}
                totalCount={activeUsers}
                loadMore={this.handleLoadMore}
                handleChangeCheckbox={this.handleChangeCheckbox}
                handleAlldelete={this.allDelete}
                handleDeleteAccount={this.handleDeleteAccount}
              />
            )}

            {this.props.loading && (
              <div className="d-flex justify-content-center p-5">
                <Spinner animation="border" role="status">
                  <span style={{ color: "white" }} className="sr-only">
                    Loading...
                  </span>
                </Spinner>
              </div>
            )}
            {!this.props.loading && !this.props.ModelList.length && (
              <div className="d-flex justify-content-center p-5">
                <small style={{ color: "white", fontSize: "20px" }}>
                  No records found
                </small>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    ModelList: state.User.data.ModelList,
    UserCount: state.User.data.UserCount,
    filters: state.Filter,
    loading: state.User.loading,
    modelCount: state.User.data.modelCount
  };
};

export default connect(mapStatetoProps, { getModelListing })(
  Accounts
);
