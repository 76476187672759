import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { createBrowserHistory } from 'history';

import AuthLayout from "../app/auth-app/auth-layout";
import MainLayout from "../app/main-app/main-layout";
import DirectorLayout from "../app/director-app/layout";
import DirectorLoginLayout from "../app/auth-app/director-login-layout";

import { PrivateRoute, GeneralRoute } from './privateRoute';

export default function Routes() {
  const history = createBrowserHistory()
  return (
    <BrowserRouter history={history}>
      <Switch>
        <Route path="/" exact
          component={DirectorLoginLayout}
        />
        {/* <Route path="/director-login"
          component={DirectorLoginLayout}
        /> */}
        <GeneralRoute path="/admin/login"
          component={AuthLayout}
        />
        <PrivateRoute path="/admin"
          component={MainLayout} 
        />
        <PrivateRoute path="/director"
          component={DirectorLayout}
        />
      </Switch>
    </BrowserRouter>
  );
}
