import {
    SHOW_LOADING,
    SUBSCRIPTIONS_LISTING_SUCCESS,
    SUBSCRIPTIONS_LISTING_ERROR,
    STOP_LOADING,
    SUBSCRIPTIONS_COUNT_SUCCESS,
    SUBSCRIPTIONS_COUNT_ERROR
} from './../types';

const Initial_State = {
    loading: false,
    data: {
        SubscriptionsList: [],
        SubscriptionCount: '',
    },
    error: ''
}

export const SubscriptionsReducer = (state = Initial_State, action) => {
    switch (action.type) {
        case SHOW_LOADING:
            return { ...state, data: { ...state.data, SubscriptionsList: [] } }
        case SUBSCRIPTIONS_LISTING_SUCCESS:
            return {
                ...state,
                data: { ...state.data, SubscriptionsList: action.payload },
                loading: false
            }
        case SUBSCRIPTIONS_LISTING_ERROR:
            return { ...state, loading: false, error: action.payload }
        case SUBSCRIPTIONS_COUNT_SUCCESS:
            return {
                ...state,
                data: { ...state.data, SubscriptionCount: action.payload },
                loading: false
            }
        case SUBSCRIPTIONS_COUNT_ERROR:
            return { ...state, loading: false, error: action.payload }
        case STOP_LOADING:
            return { ...state, loading: false }
        default:
            return state
    }
}