import React, { useState, useEffect } from "react";
import styles from "./projectChildList.module.scss";
import NormalSidebar from "../../../components/director-sidebar/normalSidebar/index";
import Header from "../../../components/shared/header";
import Footer from "../../../components/shared/footer";
import AddEpisodeModal from "../../../components/modal/EpisodeModal";
import { ReactComponent as MainCirSvg } from '../../../assets/icons/mainCirSvg.svg';
import { ReactComponent as MainCirSvgYell } from '../../../assets/icons/mainimgYellWhite.svg';
import { ReactComponent as MainimgWhiteYell } from '../../../assets/icons/mainimgWhiteYell.svg';
import { ReactComponent as LeftHand } from '../../../assets/icons/LeftHand.svg';
import { ReactComponent as RightHand } from '../../../assets/icons/RightHand.svg';
import { getEpisodeSceneByProjectId, deleteEpisodesAndSceneById } from "../../../services/episodeSceneService";
import DeleteUserModal from "../../../components/deleteUserModal";
import { useParams, useHistory } from "react-router-dom";
import { toast } from "react-toastify"; 
import { useDispatch, useSelector } from "react-redux";
import { getEpisodesSceneListingByProjectId } from "../../../redux/Projects/actions";

const ProjectChildList = (props) => {
	const location = useParams();
	const history = useHistory();
	const type = location.type;
	const dispatch = useDispatch();
	const projectId = location?.projectId;
	const episodeSceneType = location?.type || "episodes";
	const episodeSceneTypeKey = episodeSceneType === "episodes" ? "Episode" : "Scene";
	const [episodeSceneData, setEpisodeSceneData] = useState([]);
	const [episodeSceneList, setEpisodeSceneList] = useState([]);
	const [hitListRequest, setHitListRequest] = useState(false);
	const episodeState = useSelector((state) => state?.Projects);
	const [addEpisodeModalOpen, setAddEpisodeModalOpen] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [episodeCount, setEpisodeCount] = useState(1);
	const [episodeDetail, setEpisodeDetail] = useState({
		pId: 0,
		name: "",
		projectId: projectId
	});

	const [params, setParams] = useState({
		limit: 10,
		offSet: 0,
	});
	
	const getEpisodeProjectDetail = async (projectId) => {
		const result = await getEpisodeSceneByProjectId(projectId);
		if (!result.status) {
      toast.error(result.errorMsg);
    } else {
      setEpisodeSceneData(result.data);
    }
  };

	useEffect(() => {
    getInitialData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

	/**
	 * @param: null
	 * Comment: Hit the initial two requests for list and detail
	 */
	const getInitialData = () => {
		getEpisodeProjectDetail(projectId);
		dispatch(getEpisodesSceneListingByProjectId(projectId, episodeSceneType, params.offSet, params.limit));
		setHitListRequest(true);
	}

	useEffect(() => {
		if (hitListRequest) {
			setEpisodeCount(params.offSet + 1);
			dispatch(getEpisodesSceneListingByProjectId(projectId, episodeSceneType, params.offSet, params.limit))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.offSet]);


	useEffect(() => {
		setEpisodeSceneList(episodeState?.data?.episodesData || []);
		if (episodeState?.error !== "") {
			toast.error(episodeState?.error);
		}
	}, [episodeState])

	const episodeSceneListData = episodeSceneList?.rows;
	const totalCount = episodeSceneList?.count;
	const showingFrom = params?.offSet + 1;
	const showingTo = showingFrom + episodeSceneListData?.length - 1;

	/**
	 * 
	 * @param {string} type
	 * Comment: Click of pagination button hit the request to fetch more records 
	 */
	const handleEpisodeScenePagination = (type) => {
		let offSet = params.offSet - params.limit;
		if (type === "next") {
			offSet = params.offSet + params.limit;
		}
		setParams({
				...params,
				offSet
		});
	};

	const handleAddEditModal = (pId, name) => {
		setAddEpisodeModalOpen(true);
		setEpisodeDetail({...episodeDetail, pId, name});
	};

	const handleDeleteModal = (pId) => {
		setShowDeleteModal(true);
		setEpisodeDetail({...episodeDetail, pId});
	};

	const deleteEpisodeById = async () => {
		const result = await deleteEpisodesAndSceneById(episodeDetail?.pId, type);
		if (!result.status) {
			toast.error(result.errorMsg);
		} else {
			toast.success(`${episodeSceneTypeKey} has been deleted successfully`);
			getInitialData();
		}
	};
	
	return (
		<>
			<div className="normalSidebarcontainer"> 
				<NormalSidebar />
			</div>

			<div className={styles.header}>  
				<Header
					heading = { `My ${episodeSceneTypeKey}s` }
					subHeading = { episodeSceneData?.project_name }
					isSidebarOpen = { false }
					pageType = { "project-episodes-scenes" }
					data = { episodeSceneData }
					setAddEpisodeModalOpen ={ handleAddEditModal }
				/>
			</div>

			<div className={styles.dashboardMainSec}>
				{episodeSceneListData?.map((item, index) => (
					<div 
						key={item.id} 
						className={styles.dashinnerMainsec} 
						onClick={() => episodeSceneTypeKey === "Episode" ? 
								history.push(`/director/scenes-detail/${projectId}/${item?.id}`) :
								history.push(`/director/scenes-detail/${projectId}`)
							}
						>
						<MainCirSvg className={styles.dashImgDeactive}/>
						<MainCirSvgYell className={styles.dashImgactive}/>
						<div className={styles.dashinnImgTxt}>
							<div>
								<p className={styles.dashinnImgTxtOne}>{episodeSceneTypeKey} {episodeCount + index}</p>
								<p className={styles.episodeName}>{item?.name}</p>
							</div>
							{episodeSceneTypeKey === "Episode" &&
								<p className={styles.dashinnImgTxtwo}>{item?.episodeScenes?.length} Scenes</p>
							}
						</div>
						<div className={styles.dashBotBtn}>
							<div className={styles.dashBotBtntext} onClick={(e) => {e.stopPropagation(); handleAddEditModal(item?.id, item?.name)}}>Edit</div>
							<div className={styles.dashBotBtntext} onClick={(e) => {e.stopPropagation(); handleDeleteModal(item?.id)}}>Delete</div>
						</div>
					</div>
				))}

				{episodeSceneListData?.length === 0 &&
					<div className={styles.noDatabox} onClick={() => handleAddEditModal(0)}>
						<div className={styles.noDataMsg}>
							<MainimgWhiteYell className={`mainWhiteCricleSvg ${styles.Noroleimg}`}/>
							<p className={styles.noRoleText}>No {episodeSceneTypeKey} Found</p>
						</div>
					</div>
				}
			</div>
			
			<div className={styles.leftRightHands}>
				<div className={showingFrom === 1 ? styles.leftDisable : styles.leftHandArrow} onClick={() => handleEpisodeScenePagination("prev")}>
					<LeftHand className={styles.lefthanfSvg}/>
					<p className={styles.lefthandtext}>Previous Episodes</p>
				</div>

				<Footer history={props.history} />

				<div className={(episodeSceneListData?.length < params.limit || showingTo >= totalCount) ? styles.rightDisable : styles.rightHandarrow} onClick={() => handleEpisodeScenePagination("next")}>
					<p className={styles.Righthandtext}>More Episodes</p>
					<RightHand className={styles.lefthanfSvg}/>
				</div>
			</div>

			{addEpisodeModalOpen && (
				<AddEpisodeModal
					show = { addEpisodeModalOpen }
					hide = { setAddEpisodeModalOpen }
					episodeDetail = { episodeDetail }
					handleCreateSuccessEvent = { getInitialData }
					episodeSceneTypeKey = { episodeSceneTypeKey }
				/>
			)}

			<DeleteUserModal
				show = { showDeleteModal }
				handleClose = { () => setShowDeleteModal(false) }
				id = { episodeDetail?.pId }
				heading = { `Delete ${episodeSceneTypeKey} ?` }
				subHeading = { `Are you sure that you want to delete this ${episodeSceneTypeKey} ?` }
				handleDelete = { deleteEpisodeById }
			/>
		</>
	);
}

export default ProjectChildList;
