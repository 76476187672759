import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { debounce } from "lodash";
import { getModelListing } from "./../../../redux/Users/actions";
import Button from "react-bootstrap/Button";
import "./users.scss";
import SearchInput from "../../../components/searchInput/searchInput";
import UserList from "../../../components/userlist/userlist";
import Modal from "../../../components/modal/index";
import addIcon from "../../../assets/images/addIcon.png";
import {
  deleteUserApi,
} from "../../../redux/Users/api"
import Dropdown from "react-bootstrap/Dropdown";

// let userArray = [{ name: "john" }, { name: "sohn" }];

class Users extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      limit: 10,
      offSet: 0,
      ModelList: [],
      show: false,
      entityName: "",
      currentUser: {},
      searchTypes: 'email',
      searchedValue: "",
      isloadMore:false
    };
  }

  componentDidMount() {
    let params = this.getParams();
    this.props.getModelListing(params);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.ModelList !== prevProps.ModelList) {
      if (this.state.isloadMore) {
        this.setState({
          ...this.state,
          ModelList: [...this.state.ModelList, ...this.props.ModelList],
          loadMore:false
        });
      } else if(!this.state.isloadMore && this.props.ModelList && this.props.ModelList.length > 0){
        this.setState({
          ...this.state,
          ModelList: this.props.ModelList,
          isloadMore:false
        });
      }      
    }

    if (prevState.offSet !== this.state.offSet) {
      let params = this.getParams();
      this.props.getModelListing(params);
      this.setState({loadMore:true})
    }
  }
  getParams = () => {
    let params = {
      limit: this.state.limit,
      offset: this.state.offSet,
      paginate: true,
      searchTypes: this.state.searchTypes
    };

    if (this.state.searchedValue !== "") {
      params["search"] = this.state.searchedValue;
    }
    return params;
  };

  handleLoadMore = () => {
    this.setState({
      ...this.state,
      offSet: this.state.offSet + 10,
      isloadMore: true
    });
  };

  handleShow = () => {
    this.setState({
      show: true,
    });
  };

  handleEditShow = (data) => {
    this.setState({
      show: true,
      currentUser: data,
    });
  };

  handleClose = () => {
    this.setState({
      show: false,

    });
  };

  handleDelete = async (id) => {
    await deleteUserApi(id);
    this.setState({
      ModelList: this.state.ModelList.filter(x => x.id !== id)
    });
    this.refreshUserListing();
  }

  refreshUserListing()  {
    let params = this.getParams();
    this.props.getModelListing(params);
  }

  searchInput = (value) => {
    this.setState({
      ModelList: [],
      limit: 10,
      offSet: 0,
      searchedValue: value
    });

    let params = this.getParams();
    this.props.getModelListing(params);
  };

  searchHandler = debounce((value) => {
    this.searchInput(value);
  }, 1000);

  render() {
    const usersCount = this.props.modelCount;
    return (
      <div className="">
        <div className="userTopRow">
          <div className="userCount">
            <span>All users</span>{" "}
            {usersCount > 0 &&
              <span>{usersCount}</span>
            }
          </div>
          <div className="createUser">
            <Button variant="warning" onClick={this.handleShow}>
              <img src={addIcon} alt="addIcon" /> <span>Create New</span>
            </Button>
          </div>

          <div>
            <Modal
              handleShow={this.handleShow}
              handleClose={this.handleClose}
              show={this.state.show}
              user={this.state.currentUser}
              refreshUserListing={this.refreshUserListing.bind(this)}
              pageType={ "users" }
            ></Modal>
          </div>
          <div className="btnsCol">
              <div className='dropdownSearchAccount defaultDropdown'>
  
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  {this.state.searchTypes}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onSelect={() => this.setState({
                    searchTypes: 'email'
                  })}>by email</Dropdown.Item>
                  <Dropdown.Item onSelect={() => this.setState({
                    searchTypes: 'name'
                  })}>by name</Dropdown.Item>
                  <Dropdown.Item onSelect={() => this.setState({
                    searchTypes: 'phone'
                  })}>by phone</Dropdown.Item>
                  <Dropdown.Item onSelect={() => this.setState({
                    searchTypes: 'status'
                  })}>by status</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="userSearch">
              <SearchInput searchHandler={this.searchHandler} searchInput={this.searchInput}/>
            </div>
          </div>
        </div>

        <div>
          <UserList
            data={this.state.ModelList}
            type={"users"}
            totalCount={usersCount}
            loadMore={this.handleLoadMore}
            handleEdit={this.handleEditShow}
            handleDelete={this.handleDelete}
          />
          {!usersCount && 
            <div className="d-flex justify-content-center p-5">
              <small style={{ color: "white", fontSize: "20px" }}>
                No records found
              </small>
            </div>
          }
        </div>
      </div>
    );
  }
}

const MapStatetoProps = (state) => {
  return {
    ModelList: state.User.data.ModelList,
    UserCount: state.User.data.UserCount,
    filters: state.Filter,
    // loading: state.User.loading,
    modelCount: state.User.data.modelCount
  };
};

export default connect(MapStatetoProps, { getModelListing })(
  Users
);
