import React, { useState } from "react";
import "./userlist.scss";
import Button from "react-bootstrap/Button";
import moment from "moment";
import Form from "react-bootstrap/Form";
import DeleteUserModal from "../../components/deleteUserModal";
import DeleteAccountModal from '../../components/deleteAccountModal'
import FavoriteIcon from '@material-ui/icons/Favorite';
import { useHistory } from "react-router-dom";
import UserStatusList from "../shared/userStatusList";
import { getAge } from "../../helpers/commonHelper";

const baseUrlForImages = process.env.REACT_APP_YOUCAST_API_ENDPOINT_FOR_IMAGES;
const imagesBaseUrlS3 = `${process.env.REACT_APP_YOUCAST_USER_IMAGES_PATH}/${process.env.REACT_APP_MODE}`;

const UserList = ({ data, type, loadMore, handleEditSubscriptions, handleEdit, handleDelete,
  handleChangeCheckbox, handleDeleteSubscriptions, handleAllDelete, handleAccountsEdit, handleDeleteAccount, favBtn, totalCount }) => {
  
  const history = useHistory();
   
  const goToUserDetailsPage = (id) => {
    history.push(`/admin/dashboard/${id}`);
  }

  const goToAccountDetailPage = (id) => {
    history.push(`/admin/dashboard/${id}?key=account`);
  }
  
  const goToSubscriptionDetailPage = (id) => {
    history.push(`/admin/dashboard/${id}?key=subscription`);
  }

  let showLoadMoreBtn = false;
  if (data && data.length > 0 && totalCount && totalCount > data.length) {
    showLoadMoreBtn = true;    
  }

  return (
    <div className="userListcontainer one">
      {data &&
        data.length > 0 &&
        type === "dashboard" &&
        data.map((ele, i) => (
          <div className="userListrow dashUserlist a" key={i}>
            <DashboardList user={ele} goToUserDetailsPage={goToUserDetailsPage} favBtn={favBtn} />
          </div>
        ))}
      {data &&
        data.length > 0 &&
        type === "users" &&
        data.map((ele, i) => (
          <div className="userListrow" key={i}>
            <UsersList user={ele} handleEditClick={handleEdit} goToUserDetailsPage={goToUserDetailsPage} handleDelete={handleDelete} />
          </div>
        ))}
      {data &&
        data.length > 0 &&
        type === "accounts" &&
        data.map((ele, i) => (
          <div className="userListrow c" key={i}>
            <Accounts user={ele} handleChangeCheckbox={handleChangeCheckbox} handleAllDelete={handleAllDelete} handleDeleteAccount={handleDeleteAccount} goToAccountDetailPage={goToAccountDetailPage} />
          </div>
        ))}
      {data &&
        data.length > 0 &&
        type === "subscriptions" &&
        data.map((ele, i) => (
          <div className="userListrow" key={i}>
            <Subscriptions user={ele} handleDeleteSubscriptions={handleDeleteSubscriptions} goToSubscriptionDetailPage={goToSubscriptionDetailPage} />
          </div>
        ))}

      {showLoadMoreBtn &&  
        <div className="userListload d-flex justify-content-center p-4">
          <Button variant="warning" onClick={loadMore}>
            Load more
          </Button>
        </div>
      }
    </div>
  );
};

const DashboardList = ({ user, goToUserDetailsPage, favBtn }) => {
  let age = getAge(user.yc_models_model?.birth_date);
  const userImage = user?.images || [];
  let imageUrl = "";
  if (userImage?.length > 0 && userImage[0]?.title) {
    imageUrl = `${imagesBaseUrlS3}/${user?.id}/${userImage[0].image_for}/thumbnail/th${userImage[0].title}`;
  }
  
  return (
    <React.Fragment>
      <div className="userImg" onClick={() => goToUserDetailsPage(user.id)}>
        <div className="imgCol">
          <img src={imageUrl} className="user_image" alt="" onError={(event) => event.target.style.display = 'none'} />
        </div>
      </div>
      <div >
        <FavoriteIcon style={{ color: user.favourite === 1 ? 'red' : 'white' }} onClick={() => favBtn(user.id, user.favourite === 1 ? 0 : 1)} />
      </div>
      <div className="userDetail" onClick={() => goToUserDetailsPage(user.id)}>
        <div className="userDetailCol">
          <div>
            <p>Name</p>
            <h4>{user?.name}</h4>
          </div>
        </div>
        <div className="userDetailCol">
          <p>Age</p>
          <h4>{age}</h4>
        </div>
        <div className="userDetailCol">
          <p>Phone number</p>
          <h4>{user?.phone}</h4>
        </div>
        <div className="userDetailCol">
          <p style={{ textAlign: 'end' }}> Followers {user?.follower_count}</p>
        </div>
      </div>
    </React.Fragment>
  );
};

const UsersList = ({ user, goToUserDetailsPage, handleEditClick, handleDelete }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let signUpDate = moment(user?.created_at).format('DD MMMM YYYY');
  // let imageUrl = user?.yc_models_model && user?.yc_models_model?.cover_image ? `http://youcast.media/data/uploads/${user?.yc_models_model?.cover_image}` : "https://you.appwrk.eu/static/media/default_image.cf89e8e6.png";
  let imageUrl = user?.yc_models_model && user?.yc_models_model?.cover_image ? `${baseUrlForImages}/${user?.yc_models_model?.cover_image}` : "https://you.appwrk.eu/static/media/default_image.cf89e8e6.png";
  
  return (
    <React.Fragment>
      <DeleteUserModal
        show={show}
        handleClose={handleClose}
        handleShow={handleShow}
        heading={ "Delete User?" }
        id={user.id}
        handleDelete={handleDelete}
      />
      <div className="userImg">
        <div className="imgCol">
          <img src={imageUrl} className="user_image" alt="user" onError={(event) => event.target.style.display = 'none'} />
        </div>
      </div>
      <div className="allUserRow">
        <div className="addedUserDetail" onClick={() => goToUserDetailsPage(user.id)}>
          <div className="userDetailCol">
            <div>
              <p>Name</p>
              {/* <h4>{user?.user?.name}</h4> */}
              <h4>{user?.name}</h4>
            </div>
          </div>
          <div className="userDetailCol">
            <p>Phone Number</p>
            {/* <h4>-</h4> */}
            <h4>{user?.phone}</h4>
          </div>
          <div className="userDetailCol">
            <p>Email</p>
            {/* <h4>{user?.user?.phone}</h4> */}
            <h4>{user?.email}</h4>
          </div>
          <div className="userDetailCol">
            <p>Signup Date</p>
            {/* <h4>{user?.user?.phone}</h4> */}
            <h4>{signUpDate}</h4>
          </div>
        </div>
        <div className="userSubs">
          <div>
            {/* use daynamic clases for Subscription notsent="not-sent-subs" and sent="sent-subs" */}
            <h6>Subscription</h6><br></br>
            <h6>Not Sent</h6>
          </div>
        </div>
        <div className="statusCol">
          <div>
            {/* use daynamic clases for status notviewed="not-view-status" and callback="call-back-status" noanswer="no-ans-status" */}
            <h6>Status</h6><br></br>
            <UserStatusList userInfo={user} pageType="listPage" />
          </div>
        </div>

        <div className="userActioncol">
          <div className="edit">
            <h6 variant="warning" onClick={() => handleEditClick(user)}>
              Edit
            </h6>
            <br></br>
            <div className="Userdelete">
              <h6 onClick={handleShow}>Delete</h6>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const Accounts = ({ user, handleChangeCheckbox, handleDeleteAccount, goToAccountDetailPage }) => {
  let age = getAge(user.yc_models_model?.birth_date);
  const userImage = user?.images || [];
  let imageUrl = "";
  if (userImage?.length > 0 && userImage[0]?.title) {
    imageUrl = `${imagesBaseUrlS3}/${user?.id}/${userImage[0].image_for}/thumbnail/th${userImage[0].title}`;
  }
  
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleAcc = () => setShow(true);

  let planStatus = "Unpaid";
  if (user?.subscription && Object.keys(user?.subscription).length > 0) {    
    planStatus = user.subscription.payment_status.charAt(0).toUpperCase() + user.subscription.payment_status.slice(1);
  }
  
  return (
    <React.Fragment>
      <DeleteAccountModal
        show={show}
        handleClose={handleClose}
        handleAcc={handleAcc}
        handleDeleteAccount={handleDeleteAccount}
        id={user.id}
      />
      <Form.Group className="customCheckBox" controlId="formBasicCheckbox " style={{ display: 'flex', alignItems: 'center' }}>
        <Form.Check type="checkbox"
          id={user.id}
          onChange={(e) => handleChangeCheckbox(e, user.id)}
        />
      </Form.Group>
      <div className="userImg">
        <div className="imgCol">          
          <img src={imageUrl} className="user_image" alt="user" onError={(event) => event.target.style.display = 'none'} />
        </div>
      </div>
      <div className="allUserRow">
        <div className="userDetail">
          <div className="userDetailCol">
            <div>
              <p>Name</p>
              <h4>{user?.name}</h4>
            </div>
          </div>
          <div className="userDetailCol">
            <p>Age</p>
            <h4>{age}</h4>
          </div>
          <div className="userDetailCol">
            <p>Phone number</p>
            <h4>{user?.phone}</h4>
          </div>
        </div>
        <div className="statusCol">
          <div>
            <h6>Status</h6><br></br>
            {planStatus === "Unpaid" ? 
              (<h4 style={{ color: 'red' }}>{planStatus}</h4>)
              :
              (<h4 style={{ color: 'green' }}>{planStatus}</h4>)
            }
          </div>
        </div>

        <div className="userActioncol">
          <div>
            <div className="edit">
              <h6 onClick={() => goToAccountDetailPage(user.id)}>Edit</h6>
            </div><br></br>
            <div className="Userdelete">
              <h6 onClick={handleAcc} >Delete</h6>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const Subscriptions = ({ user, handleDeleteSubscriptions, goToSubscriptionDetailPage }) => {

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleShow = () => setShowDeleteModal(true);
  let imageUrl = user?.yc_models_model && user?.yc_models_model?.cover_image ? `${baseUrlForImages}/${user?.yc_models_model?.cover_image}` : "https://you.appwrk.eu/static/media/default_image.cf89e8e6.png"
  let subscriptionStartDate = new Date(user.started_from).toString().slice(3, 15)
  return (
    <React.Fragment>
      <DeleteUserModal
        show={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        id={user.id}
        heading= { "Delete Subscription?" }
        subHeading = { "Are you sure that you want to delete this subscription?" }
        handleDelete={handleDeleteSubscriptions}
      />

      <div className="userImg">
        <div className="imgCol">
          <img src={imageUrl} className="user_image" alt="user" onError={(event) => event.target.style.display = 'none'} />
        </div>
      </div>
      <div className="allUserRow">
        <div className="addedUserDetail">
          <div className="userDetailCol">
            <div>
              <p>Name</p>
              <h4>{user?.user?.name}</h4>
            </div>
          </div>
          <div className="userDetailCol">
            <p>Phone Number</p>
            <h4>{user?.user?.phone}</h4>
          </div>
          <div className="userDetailCol">
            <p>Email</p>
            <h4>{user?.user?.email}</h4>
          </div>
          <div className="userDetailCol">
            <p>Subscription Date</p>
            <h4>{subscriptionStartDate}</h4>
          </div>
        </div>
        <div className="userSubs">
          <div>
            <h6>Subscription</h6><br></br>
            <h6>{user?.subscriptionPlan?.title}</h6>
            <h5>{user?.subscriptionPlan?.cost}</h5>
          </div>
        </div>
        <div className="statusCol">
          <div>
            <h6>Status</h6><br></br>
            <h6>{user.confirmed === 1 ? 'Active' : 'Not Active'}</h6>
          </div>
        </div>

        <div className="userActioncol">
          <div>
            <div className="edit">
              <h6 onClick={() => goToSubscriptionDetailPage(user?.user?.id)}>Edit</h6>
            </div><br></br>
            <div className="Userdelete">
              <h6 onClick={handleShow}>Delete</h6>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

UserList.propTypes = {};

export default UserList;
