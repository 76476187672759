import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import DetailsPage from './detailsPage/index';
import { getUserByID, updateUserStatusById } from "../../../redux/Users/actions";
import { useLocation } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { getUserByIDapi } from "../../../redux/Users/api";


const AccountDetail = (props, id, updateUserStatus) => {
    const location = useLocation();
    const history = useHistory();
    const [key, setKey] = useState('signup');
		const [detail, setDetail] = useState({});
    const [subscriptionType, setSubscriptionType] = useState({});
		const [subscriptionPlan, setSubscriptionPlan] = useState({});

    const pageKeyValue = location.search.split("=")[1];
    
    useEffect(() => {        
			const keyType = pageKeyValue === "account" ? "account" : pageKeyValue === "subscription" ? "subscription" : "signup";
			setKey(keyType);
    }, [pageKeyValue]);


		useEffect(() => {
			getUserDataById(props?.match?.params?.id);
			// eslint-disable-next-line react-hooks/exhaustive-deps 
		}, []);

		const getUserDataById = async(userId) => {
			const apiData = await getUserByIDapi(userId);
			const finalData = apiData?.data?.data;

			setDetail(finalData?.userData);
			setSubscriptionType(finalData?.subscriptionType);
			setSubscriptionPlan(finalData?.subscriptionPlan);
		}

    let eventKey = "subscription";

    if (props?.user?.data?.userDetail?.modelCategories?.length === 0) {
			eventKey = "";
    };

    const changeUrlParam = (key) => {
        if (key === "") {
            toast.error("Please select atleast one model category to check subscription details");
            history.push(`?key=account`);
        } else {
            history.push(`?key=${key}`);
        }
    }
   
    return (
        <div className="contentWrapper">
            <div className="topRow">
                <h4>Giving Talents a better chance</h4>
            </div>
            <div className="contentRow">
                <div className="aud-userDetail">
                    <div className="user-tab">
                        <Tabs
                            id="controlled-tab-example"
                            activeKey={key}
                            onSelect={(key) => changeUrlParam(key)}
                        >
                            <Tab eventKey="signup" title="Signup Details" />
                            <Tab eventKey="account" title="Account Details" />
                            <Tab eventKey={eventKey} title="Subscription Details" />
                        </Tabs>                        
                    </div>
                    {detail?.id &&
                        <DetailsPage
                            displayState = { key }
                            userDetails = { detail }
                            subscriptionPlan = { subscriptionPlan }
                            subscriptionType = { subscriptionType }
                        />
                    } 
                </div>
            </div>
        </div>

    );
};

const MapStatetoProps = (state) => {
    return {
        user: state.User
    };
};

const AuthLayoutPage = connect(MapStatetoProps, { getUserByID, updateUserStatusById })(
    AccountDetail
);

export default AuthLayoutPage;
