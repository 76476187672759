
import React, { useEffect, useState } from "react";
import moment from "moment";
import DatePicker from "react-datepicker"
import DummUser from "../../../../assets/images/user-dummy.png";
import Form  from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { updateSubscriptionsByID, saveOrUpdateSubscription } from "../../../../services/subscriptionService";
import styles from "./subscriptionDetail.module.scss";
import { ReactComponent as EditIcon } from '../../../../icons/editIcon.svg';
import { ReactComponent as DeleteIcon } from '../../../../icons/delete.svg';
import { ReactComponent as CancelButton } from '../../../../assets/icons/cancelButton.svg';
import { ReactComponent as SaveButton } from '../../../../assets/icons/saveDetailButton.svg';
import { toast } from "react-toastify";
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { setUserInfo } from "../../../../redux/Users/actions";
import { deleteUserApi } from "../../../../redux/Users/api";
import DeleteUserModal from "../../../../components/deleteUserModal";

const SubscriptionDetail = ({
    dataSubscription,
    subscriptionPlan,
    subscriptionType
  }) => {    
  const subsData = dataSubscription?.subscription;
  const [pageViewType, setPageViewType] = useState("view");
  const [tempSubPlanValue, setTempSubPlanValue] = useState("");
  const [subsPlan, setSubsPlan] = useState("");
  const [subsType, setSubsType] = useState("");
  const [activateStatus, setActivateStatus] = useState(0);
  const userId = dataSubscription?.id;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    setActivateStatus(subsData?.confirmed || 0);
    setSubscriptionPlanLabels();    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSubscription]);
  
  const setSubscriptionPlanLabels = () => {
    if (subsData?.subscription_plan_id) {
      for (let i = 0; i < subscriptionPlan.length; i++) {
        if(subscriptionPlan[i].id === subsData.subscription_plan_id) {
          setSubsPlan(subscriptionPlan[i].title + " " + subscriptionPlan[i].period);
          setTempSubPlanValue(subscriptionPlan[i].id + "-" + subscriptionPlan[i].cost);
          break;
        }
      }
    }
  
    if (subsData?.subscription_type_id) {
      for (let i = 0; i < subscriptionType.length; i++) {
        if(subscriptionType[i].id === subsData.subscription_type_id) {
          setSubsType(subscriptionType[i].title);
          break;
        }
      }
    }
  }

  const updateSubscriptionStatus = (subscriptionId) => {
    let accountActivated = activateStatus === 1 ? 0 : 1;
    setActivateStatus(accountActivated);
    updateSubscriptionsByID(subscriptionId, accountActivated);
  };

  const [getSubsData, updateSubsData] = useState({
    id: subsData?.id ? subsData.id : 0,
    user_id: userId,
    subscription_plan: subsData?.subscription_plan_id ? subsData?.subscription_plan_id : '',
    start_date: subsData?.started_from ? moment(subsData?.started_from).format("YYYY-MM-DD") : '',
    subscription_type: subsData?.subscription_type_id ? subsData?.subscription_type_id : '',
    end_date: subsData?.end_in ? moment(subsData?.end_in).format("YYYY-MM-DD") : '',
    cost: subsData?.cost ? subsData?.cost : '0',
    payment_status: subsData?.payment_status ? subsData.payment_status : 'unpaid'
  });

  const handleUpdate = (event) => {
    let value = event.target.value;
    if (event.target.name === "subscription_type") {
      value = parseInt(value);
    }
    updateSubsData({
      ...getSubsData,
      [event.target.name]: value,
    });
  };

  const handleDateUpdate = (date , type) => {
    updateSubsData({
      ...getSubsData,
      [type]: moment(date).format("YYYY-MM-DD"),
    });
  }

  const handleSubscriptionPlan = (event) => {
    if (event.target.value) {
      setTempSubPlanValue(event.target.value);
      const subscriptionDataArr = event.target.value.split("-");
      updateSubsData({
        ...getSubsData,
        "subscription_plan": parseInt(subscriptionDataArr[0]),
        "cost": subscriptionDataArr[1],
      });
    }
  }

  /**
   * @param: null
   * Comment: Update or add user subscription based on subscription id
   */
  const saveSubscriptionDetail = async() => {   
    const saveResult = await saveOrUpdateSubscription(getSubsData);
    if (!saveResult.success) {
      toast.error(saveResult.msg);
    } else {
      toast.success("Subscription details has been updated");
      dispatch(setUserInfo(saveResult.data))
    }
  }

  /**
   * 
   * @param {integer} userId
   * Comment: delete the user account and redirect to users listing page 
   */
  const handleUserDelete = async (userId) => {
    await deleteUserApi(userId);
    toast.success("User account has been deleted");
    history.push(`/admin/users`);
  }

  return (
    <React.Fragment>
      <div className={styles.userActionBtn}>
        {pageViewType === 'view' ? (
          <div className={styles.actionButton}>
            <Button className={styles.userEditBtn} onClick={() => setPageViewType("edit")}><EditIcon />Edit Details</Button>
            <Button className={styles.userDeleteBtn} onClick={() => setShowDeleteModal(true)}><DeleteIcon />Delete Account</Button>
          </div>
        ) : 
          <div className={styles.actionButton}>
            <Button className={styles.userEditBtn} onClick={saveSubscriptionDetail}><SaveButton />Save Details</Button>
            <Button className={styles.userDeleteBtn} onClick={() => setPageViewType("view")}><CancelButton />Cancel</Button>
          </div>
        }
      </div>

      <div className={styles.subscriptionDetail}>
        <div className={styles.subsImgSide}>
          <div className={styles.modalimgCol}>
            <div className={styles.userImgBox}>
                {dataSubscription?.images.length > 0 ? <img className={styles.userImg} src={`${process.env.REACT_APP_YOUCAST_USER_IMAGES_PATH}/${process.env.REACT_APP_MODE}/${dataSubscription.id}/account/thumbnail/th${dataSubscription?.images[0].title}`} alt="DummUser" /> : <img src={DummUser} className={styles.userImg}alt="DummUser" />}
            </div>
            <p>{dataSubscription.name}</p>
          </div>
        </div>

        <div className={styles.subsDetailSide}>
            {pageViewType === "view" &&
              <>
                {subsData && activateStatus === 1 ? (
                  <div className={styles.subsdetailRow}>
                    <div className={styles.subsDetailCol}>
                      <h4 className={styles.labels}>Subscription Plan</h4>
                      <p className={styles.viewText}>{subsPlan}</p>
                    </div>

                    <div className={styles.subsDetailCol}>
                      <h4 className={styles.labels}>Start Date</h4>
                      {subsData?.started_from &&
                        <p className={styles.subsDateMainTag}>
                          <span className={styles.subsDate}>{moment(subsData?.started_from).format("YYYY")}</span>
                          <span className={styles.subsDate}>{moment(subsData?.started_from).format("MMM")}</span>
                          <span className={styles.subsDate}>{moment(subsData?.started_from).format("DD")}</span>
                        </p>
                      }                    
                    </div>

                    <div className={styles.subsDetailCol}>
                      <h4 className={styles.labels}>Subscription Type</h4>                 
                      <p className={styles.viewText}>{subsType}</p>
                    </div>

                    <div className={styles.subsDetailCol}>
                      <h4 className={styles.labels}>End Date</h4>
                      {subsData?.end_in && 
                        <p className={styles.subsDateMainTag}>
                          <span className={styles.subsDate}>{moment(subsData?.end_in).format("YYYY")}</span>
                          <span className={styles.subsDate}>{moment(subsData?.end_in).format("MMM")}</span>
                          <span className={styles.subsDate}>{moment(subsData?.end_in).format("DD")}</span>
                        </p>
                      }
                    </div>
                    
                    <div className={styles.subsDetailCol}>
                      <h4 className={styles.labels}>Cost</h4>
                      <p className={styles.viewText}>
                        {subsData?.cost}
                        {subsData?.cost && " EGP"}
                      </p>                  
                    </div>

                    <div className={styles.subsDetailCol}>
                      <h4 className={styles.labels}>Payment Status</h4>
                        <p className={styles.viewText}>{ subsData?.payment_status} </p>                    
                    </div>
                  </div>
                ) : (
                  <div className={styles.subsdetailRowBox}>
                    <div className={styles.notActivatedMsg}>
                      This account is not activated
                    </div>
                  </div>
                )}
              </>
            }

            {pageViewType === "edit" && 
              <>
                <div className={styles.subsdetailRow}>
                  <div className={styles.subsDetailCol}>
                    <h4 className={styles.labels}>Subscription Plan</h4>
                    <div className={styles.editDiv}>
                      <Form.Control 
                        as="select"
                        className={styles.editPageDetail}
                        name="subscription_plan"
                        value={tempSubPlanValue}
                        onChange={handleSubscriptionPlan}
                      >
                      <option value="">Select Subscription plan</option>
                      {subscriptionPlan.map((item) => (
                        <option 
                          key={item.id}
                          value={item.id + "-" + item.cost}
                          data-cost={item.cost}
                          >{item?.title} {item.period}</option>
                      ))}
                      </Form.Control>
                    </div>
                  </div>

                  <div className={styles.subsDetailCol}>
                    <h4 className={styles.labels}>Start Date</h4>              
                    <div className={styles.editDiv}>
                      <DatePicker
                        selected={getSubsData.start_date ? new Date(getSubsData.start_date) : ""}
                        onChange={(date) => handleDateUpdate(date , 'start_date')} 
                        />
                    </div>
                  </div>

                  <div className={styles.subsDetailCol}>
                    <h4 className={styles.labels}>Subscription Type</h4>
                    <div className={styles.editDiv}>          
                      <Form.Control 
                        as="select" 
                        className={styles.editPageDetail}
                        value={getSubsData.subscription_type}
                        name="subscription_type"
                        onChange={handleUpdate}
                      >
                      <option value="">Select Subscription Type</option>
                      {subscriptionType.map((item) => (
                        <option
                          key={item.id} 
                          value={item.id}
                          >{item?.title}</option>
                      ))}
                      </Form.Control>
                    </div>
                  </div>

                  <div className={styles.subsDetailCol}>
                    <h4 className={styles.labels}>End Date</h4>
                    <div className={styles.editDiv}>
                      <DatePicker 
                        selected={getSubsData.end_date ? new Date(getSubsData.end_date) : ""}
                        onChange={(date) => handleDateUpdate(date , 'end_date')} 
                        />
                    </div>
                  </div>
                  
                  <div className={styles.subsDetailCol}>
                    <h4 className={styles.labels}>Cost</h4>
                    <div className={styles.editDiv}>
                      <input type="number" name="cost" disabled={true} className={styles.editPageDetail} value={getSubsData.cost} onChange={handleUpdate} />
                    </div>
                  </div>

                  <div className={styles.subsDetailCol}>
                    <h4 className={styles.labels}>Payment Status</h4>
                    <div className={styles.editDiv}>
                      <Form.Control 
                        as="select"
                        value={getSubsData.payment_status}
                        className={styles.editPageDetail}
                        name="payment_status"
                        onChange={handleUpdate}
                      >
                        <option value="paid">Paid</option>
                        <option value="unpaid">Unpaid</option>
                      </Form.Control>
                    </div>
                  </div>
                  
                  {subsData &&
                    <div className={styles.deactivateBtn}>
                      <Button
                        onClick={() => updateSubscriptionStatus(subsData?.id)}
                      >
                        {activateStatus === 1 ? 'Deactivate Account' : 'Activate Account'}
                      </Button>
                    </div>
                  }
                </div>
              </>
            }
        </div>
      </div>

      {subsData &&
        <>
          <div className={styles.signupDetail}>
            <h4 className={styles.title}>Yocast Account</h4>
            <p className={styles.text}>
              {activateStatus === 1 ? 'Activated' : 'Deactivated'}
            </p>
          </div>
        </>
      }

      <DeleteUserModal
        show={showDeleteModal}
        heading={"Delete User?"}
        handleClose={() => setShowDeleteModal(false)}
        id={userId}
        handleDelete={handleUserDelete}
      />
    </React.Fragment>
  );
};

export default SubscriptionDetail;
