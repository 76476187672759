import {
  SHOW_LOADING,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  USER_LOGOUT,
} from "./../types";
import { loginApi } from "./api";
import { toast } from "react-toastify";

const showloading = () => {
  return {
    type: SHOW_LOADING,
  };
};

const loginSucces = (data) => {
  return {
    type: LOGIN_SUCCESS,
    payload: data,
  };
};

const loginFailure = (error) => {
  return {
    type: LOGIN_ERROR,
    payload: error,
  };
};

const showErrorMsg = (msg) => {
  toast.error(msg, {
    position: toast.POSITION.TOP_RIGHT,
  });
};

export const login = (Credentials, loginType) => {
  return async (dispatch) => {
    dispatch(showloading());
    try {
      let data = await loginApi(Credentials, loginType);
      dispatch(loginSucces(data.data));
    } catch (error) {
      dispatch(loginFailure(error?.response?.data?.error));
      showErrorMsg("Please enter valid credentials");
    }
  };
};

export const logout = () => {
  return {
    type: USER_LOGOUT,
  };
};
