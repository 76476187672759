import {
    SHOW_LOADING,
    STOP_LOADING,
    GET_CITY_SUCCESS,
    GET_CITY_FAILURE
} from './../types'

const Initial_State = {
    loading: false,
    data: {
        City: [],
    },
    error: ''
}

export const CityReducer = (state = Initial_State, action) => {
    switch (action.type) {
        case SHOW_LOADING:
            return { ...state, data: { ...state.data, City: [] } }

        case GET_CITY_SUCCESS:
            return {
                ...state, data: { ...state.data, City: action.payload },
                laoding: false
            }

        case GET_CITY_FAILURE:
            return { ...state, loading: false, error: action.payload }

        case STOP_LOADING:
            return { ...state, loading: false }
        default:
            return state
    }
}