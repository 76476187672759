import React from "react";
import styles from "./footer.module.scss";
import CameraLog from "../../../assets/icons/video-camera.png";
import { ReactComponent as Footstar } from "../../../assets/icons/star-dash.svg";
import { ReactComponent as SelectedProject } from "../../../assets/icons/projectLogo.svg";
import circleincir from "../../../assets/icons/circleincir.png";
import { ReactComponent as LogOutIcon } from "../../../assets/icons/logout.svg";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { removeSelectedProjectFromLocalStorage } from "../../../helpers/removeSelectedProject";

const Footer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const projectName = localStorage.getItem("selectedProjectName");
  const projectId = localStorage.getItem("selectedProjectId");

	return (
		<div className={styles.footerContent}>
      {projectName && projectName !== undefined ?
        <div className={styles.footImgbox}>
          <Link to={`/director/project-dashboard/${projectId}`} className={styles.linkLogo}>
            <SelectedProject className={styles.SelectedProject} />
            <p className={styles.footIcontxt}>{projectName}</p>
          </Link>
        </div> :  null
        }

      <div className={`${styles.footImgbox} ${styles.CameraLog}`}>
        <Link to="/director/projects" className={styles.linkLogo}>
          <img src={CameraLog} alt={CameraLog} height="28"/>
          <p className={styles.footIcontxt}>My Projects</p>
        </Link>
      </div>

      <div className={styles.footImgbox}>
        <Link to="/director/talents" className={styles.linkLogo}>
          <img src={circleincir} alt="talents" />
          <p className={styles.footIcontxt}>Talent Filter</p>
        </Link>
      </div>

      <div className={styles.footImgbox}>
        <Link to="/director/talents?favourite=1" className={styles.linkLogo}>
          <Footstar />
          <p className={styles.footIcontxt}>My Favorite</p>
        </Link>  
      </div>

      <div className={styles.footImgbox} onClick = {() => {
        dispatch({ type: 'USER_LOGOUT' });
        history.push('/');
        removeSelectedProjectFromLocalStorage();
      }}>
        <LogOutIcon />
        <p className={styles.footIcontxt}>Log out</p>
      </div>
    </div>	
	);
}

export default Footer;