import React, { useState, useEffect } from "react";
import { login } from "./../../redux/SignIn/actions";
import { connect } from "react-redux";
import Button from "react-bootstrap/Button";
import { InputGroup } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import styles from "./director-login.module.scss";
import logoLeft from "../../../src/assets/images/login-logo.png";
import leftBottomImage from "../../../src/assets/images/Two-circle.png";
import bgGirlImg from "../../../src/assets/images/bg-girl.png";
import yellowFlatImg from "../../../src/assets/images/bg-bottom.png";
import userIcon from "../../../src/assets/images/login-user-icon1.png";
import passIcon from "../../../src/assets/images/login-pass-icon1.png";
import { removeSelectedProjectFromLocalStorage } from "../../helpers/removeSelectedProject";
import { toast } from "react-toastify";
import Animation from "../../components/animation/index";

const DirectorLoginLayout = (props) => {
  const authToken = localStorage.getItem("accessToken");
  const loginUserType = localStorage.getItem("loginType");
  const screenWidth = window.innerWidth;
  let [email, setEmail] = useState("");
  let [password, setPassword] = useState("");
  const [showAnimation, setShowAnimation] = useState(true);
  let loginType = "director"

  useEffect(() => {
    if (props.SignIn.loggedIn === true && props.history && localStorage.hasOwnProperty("user") && localStorage.getItem("loginType") === "director") {
      props.history.push("/director/projects");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.SignIn.loggedIn]);

  useEffect(() => {
    if (authToken) {
      if (loginUserType === 'director') {
        props.history.push("/director/projects");
      } else if (loginUserType === 'admin') {
        props.history.push("/admin/dashboard");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken]);

  const handleLoginRequest = (event) => {
    event.preventDefault();
    if (screenWidth < 1360) {
      toast.error("You cannot login with small screen resolution less than 1360");
    } else {
      props.login({ email, password }, loginType)
      removeSelectedProjectFromLocalStorage();
    }
  }
  
  const showAnimationOnPageLoad = () => {
    setShowAnimation(false);
  }
  
  return (
    <>
    {showAnimation && loginType === "director" ? <Animation showAnimationOnPageLoad={showAnimationOnPageLoad}/> :<div className={`${styles.loginDirector} login-wrapper }`}>
        <div className={styles.leftLoginSection}>
            <div className={styles.formTopLogo}>              
              <img className={styles.logoPic} src={logoLeft} alt="Logo" />
              <p>Youcast</p>
            </div>
            <div className={styles.formCol}>
              <h5 className={styles.signText}>Login</h5>
              <Form className={styles.loginForm}>
                <Form.Group className={`field-group ${styles.emailGrpFld}`}>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        <img src={userIcon} alt="userIcon" />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      type="email"
                      placeholder="Email"
                      onChange={(event) => {
                        setEmail(event.target.value);
                      }}
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group className={`field-group ${styles.passwrdGrpFld}`}>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        <img src={passIcon} alt="userIcon" />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      onChange={(event) => {
                        setPassword(event.target.value);
                      }}
                    />
                  </InputGroup>
                </Form.Group>
                <div className={styles.helperRow}>
                  <Form.Group
                    controlId="formBasicCheckbox"
                    className={styles.rememberCheck}
                  >
                    <Form.Check type="checkbox" label="Remember Me" />
                  </Form.Group>
                  <div>
                    <span className={styles.forgotText}>Forgot Your Password ?</span>
                  </div>
                </div>
                  <Button
                    onClick={(event) => handleLoginRequest(event)}
                    type="submit"
                    variant="primary"
                    className={styles.dirLoginBtm}
                    disabled={!email || !password}
                  >
                    Login
                  </Button>
              </Form>
            </div>
            <div className={styles.dirLogBottomSec}>
              <img src={yellowFlatImg} alt=""/>
              <div className={styles.dirBotBox}>
                <label>Youcast</label>
                <h4>Directors Platform</h4>
              </div>
            </div>
        </div>
        <div className={styles.sideRhtImg}>
          <div className={styles.titleHead}>
            <h2>Giving <span>Talents</span><br></br>A Better Chance</h2>
          </div>
          <div className={styles.fieldText}>
            <div className={styles.fldMainBox}>
              <label>Category</label>
              <p>Actor</p>
              <label>Gender</label>
              <p>Male</p>
              <label>Age</label>
              <aside></aside>
            </div>
            <h3>More Than<br></br><span>20 Filters</span> Ready<br></br>to use</h3>
          </div>
          <div className={styles.bottomImgText}>
            <h3><span>Cast</span> Your <span>Roles</span><br></br>With just a few<br></br>Clicks</h3>
            <img src={leftBottomImage} alt=""/>
          </div>
          <img src={bgGirlImg} alt="" className={styles.bgFreeImg}/>
        </div>
      </div>}
      </>
  );
};

DirectorLoginLayout.propTypes = {};

const mapStateToProps = (state) => {
  return {
    SignIn: state.SignIn
  };
};

export default connect(mapStateToProps, { login })(DirectorLoginLayout);
