import React, { useState } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import DetailsPage from "../secondTab/index";
import '../../../../../node_modules/react-datepicker/dist/react-datepicker.css';
import SubscriptionDetail from "../subscriptionDetail/index";
import SignupDetail from "../signupDetails";

const AccountDetail = (props) => {
  return (
    <div>
      {props.displayState === "signup" ? (
        <SignupDetail data={props?.userDetails} />
      ) : (
        ""
      )}
      {props.displayState === "account" ? (
        <Account dataAccount={props?.userDetails} />
      ) : (
        ""
      )}
      {props.displayState === "subscription" ? (
        <SubscriptionDetail 
          dataSubscription={props?.userDetails}
          subscriptionPlan={props?.subscriptionPlan}
          subscriptionType={props?.subscriptionType}
        />
      ) : (
        ""
      )}
    </div>
  );
};

const Account = (props) => {
  const [key, setKey] = useState("physicalDetail");
  return (
    <React.Fragment>
      <div className="accountData-tab">
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
        >
          <Tab eventKey="photos" title="Photos" />
          <Tab eventKey="videos" title="Videos" />
          <Tab eventKey="physicalDetail" title="Physical Detail" />
          {/* <Tab eventKey="callback" title="Portfolio" /> */}
        </Tabs>
        <DetailsPage displayState={key} dataAccount={props.dataAccount} />
      </div>
    </React.Fragment>
  );
};

export default AccountDetail;
