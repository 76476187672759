import React from "react";
import NormalSidebar from "../../../components/director-sidebar/normalSidebar";
import ProfileTalent from "../../../components/talent-profile";

const TalentProfile = () => {

  return(
    <>
        <div>
          <div className="normalSidebarcontainer">
            <NormalSidebar />
          </div>
          <ProfileTalent />
        </div>
    </>
  );
}

export default TalentProfile