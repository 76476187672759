import {
    TALENTS_LISTING_SUCCESS,
    TALENTS_LISTING_ERROR
} from './../types'

const Initial_State = {
	loading: false,
	data: {
		talentList: [],
		talentCount: 0
	},
	error: ''
}

export const TalentReducer = (state = Initial_State, action) => {
  switch (action.type) {
		case TALENTS_LISTING_SUCCESS:
			return {
				...state,
				data: { 
					...state.data,
					talentList: action.payload?.rows,
					talentCount: action.payload?.count
				}
			}
		case TALENTS_LISTING_ERROR:
			return {
				...state,
				error: action.payload
			}
		
		default:
			return state
	}
}
