import React, { useState } from 'react';
import styles from './header.module.scss';
import { ReactComponent as CameraLogo } from '../../../assets/icons/cameraLogo.svg';
import { ReactComponent as Pluscreate } from "../../../assets/icons/pluscreate.svg";
import { toast } from "react-toastify";
import { deleteProjectById, getSavedPdf } from "../../../services/projectService";
import { useHistory, useParams } from "react-router-dom";
import DeleteUserModal from "../../deleteUserModal";
import DownloadPDFModal from '../../modal/DownloadPdfModal';
import { removeSelectedProjectFromLocalStorage } from "../../../helpers/removeSelectedProject";
import Spinner from 'react-bootstrap/Spinner';

const Header = (props) => {
  const isSidebarOpen = props?.isSidebarOpen;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [pdfModal, setPdfModal] = useState(false);
  const pageType = props?.pageType || "";
  const headingClass = props?.headingColorClass;
  const history = useHistory();
  const location = useParams();
  const [showSpinner, setShowSpinner] = useState(false);

  const handleDeleteProjectModal = () => {
    setShowDeleteModal(true);
  };

  const deleteProjectbyId = async () => {
    const deleteResult = await deleteProjectById(location.id);
    if (deleteResult.status === true) {
      toast.success("Project has been deleted successfully");
      history.push("/director/projects");
      removeSelectedProjectFromLocalStorage();
    } else {
      toast.error("Some error occured while deleting project");
    }
  };

  const openSavedPdf = async (projectId, episodeId) => {
    setPdfModal(false);
    setShowSpinner(true);
    const result = await getSavedPdf(projectId, episodeId);
    if (result.status) {
      fetch(result?.data?.file).then(response => {
        response.blob().then(blob => {
            // Creating new object of PDF file
            const fileURL = window.URL.createObjectURL(blob);
            // Setting various property values
            let alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = result?.data?.file?.split("/")[result?.data?.file?.split ("/")?.length-1];
            alink.click();
        });
      });
      // window.open(result?.data?.file, '_blank');
      setShowSpinner(false);
    }
  };
  return (
    <>
      <div className={styles.headerBox}>
        <div className={isSidebarOpen === false ? styles.collapseSidebar : styles.header}>
          <div className={styles.firsthead}>
            {pageType === "create-project" ? 
              <Pluscreate />
              : 
              <CameraLogo />
            }
            <div className="logotext">
              <h2 className={`${styles.logotoptext} ${headingClass}`}>{ props.heading }</h2>
              {props.subHeading !== "" &&
                <p className={styles.logobotText}>{ props.subHeading }</p>
              }
            </div>
          </div>

          {pageType === "project-dashboard" &&
            <>
              <div className={styles.midHeader}>
                <p className={styles.midToptxt}>{ props.data?.projectType?.name }</p>
                <div className={styles.midbotDiv}>
                  <p className={styles.midbotinnText}>{ props.data?.projectRoles?.length} Roles</p>
                  {props.data?.projectType?.id === 3 &&
                    <p className={styles.midbotinnText}>{ props.data?.episodes?.length } Episodes</p>
                  }
                  <p className={styles.midbotinnText}>{ props.data?.scenes?.length} Scenes</p>
                </div>
              </div>
              <div className={styles.lastSecHead}>
                {(props.data?.episodes?.length || props.data?.scenes?.length > 0) && <div 
                  className={styles.lstHeadTopbtn} 
                  onClick={props.data?.projectType?.id === 3 ? () => setPdfModal(true) : () => openSavedPdf(location.id, 0)}
                >
                  Download PDF 
                  {showSpinner && <div className={styles.spinnerContainer}><Spinner className={styles.loadingSpinner} animation="border"/></div>}
                </div>}
                { props?.data?.projectRoles?.length > 0  && 
                <div className={styles.lstInnerhead}>
                  <div className={styles.lstHeadBtmbtn} onClick={() => history.push(`/director/projects/update/${location.id}`)}>Edit</div>
                  <div className={styles.lstHeadBtmbtn} onClick={handleDeleteProjectModal}>Delete</div>
                </div> 
                }
              </div>
            </>
          }

          {pageType === "project-episodes-scenes" &&
            <>
              <div className={styles.midHeader}>
                <p className={styles.midToptxt}>{ props.data?.projectType?.name }</p>
                <div className={styles.midbotDiv}>
                  <p className={styles.midbotinnText}><span className={styles.countLength}>{ props.data?.episodes?.length }</span> Episodes</p>
                  <p className={styles.midbotinnText}><span className={styles.countLength}>{ props.data?.scenes?.length }</span> Scenes</p>
                </div>
              </div>
              
              <div className={`${styles.lastSecHead} ${styles.createNewEpisode}`}>
                <div className={styles.lstHeadTopbtn} onClick={() => props.setAddEpisodeModalOpen(0)}>Create New</div>
              </div>
            </>
          }
        </div>
      </div>
    
      <DeleteUserModal
        show={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        id={location.id}
        heading={"Delete Project?"}
        subHeading={"Are you sure that you want to delete this project?"}
        handleDelete={deleteProjectbyId}
      />

      <DownloadPDFModal
        show = { pdfModal }
        hide = { () => setPdfModal(false) }
        episodeDetail = { props.data?.episodes }
        openSavedPdf = { openSavedPdf }
      />
    </>
  );
}

export default Header;
