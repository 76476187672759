import React, { useRef, useEffect } from 'react'
import styles from './VideoPlayerModal.module.scss';
import Modal from 'react-bootstrap/Modal';
import ReactPlayer from 'react-player';
import { useParams } from 'react-router';

const VideoPlayerModal = (props) => {
    const location = useParams();
    const videoRef = useRef();

    useEffect(() => {
      if (!videoRef?.current) return;

      setTimeout(() => {
      const playerPreview = videoRef.current.querySelector('.react-player__preview');
      if (playerPreview) {
          playerPreview.click();
        }
    }, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [videoRef])

    return (
        <Modal
            show={props.show}
            onHide={props.hide}
            centered={true}
            dialogClassName={styles.modalMainContainer}
        >
            <Modal.Header closeButton>
                <Modal.Title>Video Player</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div 
                ref={videoRef}>
                <ReactPlayer
                    url={`${process.env.REACT_APP_YOUCAST_USER_VIDEOS_PATH}/${process.env.REACT_APP_MODE}/${location.id}/account/${props.data.Video_url}`}
                    controls={true}
                    light={`${process.env.REACT_APP_YOUCAST_USER_VIDEOS_PATH}/${process.env.REACT_APP_MODE}/${location.id}/account/thumbnail/th${props.data.thumbnail}`}
                    width='650px'
                    height='400px'
                    playing={true}
                    className={styles.videoPlayer}
                    muted={true}
                />
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default VideoPlayerModal;
