import * as S3 from 'aws-sdk/clients/s3';
import { DEFAULT_CHUNK_SIZE } from '../constant';

const bucket = new S3({
  region: process.env.REACT_APP_BUCKET_REGION,
  endpoint: process.env.REACT_APP_BUCKET_ENDPOINT,
  accessKeyId: process.env.REACT_APP_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_SECRET_KEY,
});

const uploadImage = function (filename, file, id, type) {
  const params = {
    Bucket: process.env.REACT_APP_BUCKET_NAME,
    Key: `${type}/${process.env.REACT_APP_MODE}/${id}/account/` + filename,
    Body: file,
    ContentType: file.type,
    ACL: 'public-read',
  };
  return bucket.putObject(params, function (err) {
    if (err) {
      return false;
    }
    return true;
  });
}

export const uploadUserImage = function (filename, file, id, setProgress, setShowProgress, getUserImages, inputFile) {
  uploadImage(filename, file, id, "images").on("httpUploadProgress", function (progress) {
    let progressPercentage = Math.round(
      (progress.loaded / progress.total) * 100
    );
    setProgress(parseInt(20 + progressPercentage * (4 / 5)))
    if (progressPercentage === 100) {
      setTimeout(() => {
        setShowProgress(false)
        setProgress(0)
        getUserImages()
      }, 1000)
      inputFile.current.value = null

    }
  });
};

export const uploadThumbnailImage = function (filename, file, id, thFileName, setProgress, setShowProgress, getUserImages, inputFile, resizeImage) {
  uploadImage(thFileName, resizeImage, id, "images").on("httpUploadProgress", function (progress) {
    let progressPercentage = Math.round(
      (progress.loaded / progress.total) * 100
    );
    setProgress(progressPercentage / 5)
    if (progressPercentage === 100) {
      uploadUserImage(filename, file, id, setProgress, setShowProgress, getUserImages, inputFile)
    }
  })
};

export const uploadUserVideoPath = async function (filename, file, id, setProgress, getVideos, setShowProgress, setAddVideoModalOpen) {
  const params = {
    Bucket: process.env.REACT_APP_BUCKET_NAME,
    Key: `videos/${process.env.REACT_APP_MODE}/${id}/account/` + filename,
    Body: file,
    ContentType: file.type,
    ACL: 'public-read',
    ContentDisposition: "inline"
  };

  if (file.size > DEFAULT_CHUNK_SIZE) {
    handleMultiPartUpload(filename, file, id, setProgress, getVideos, setShowProgress, setAddVideoModalOpen)
    return
  }

  const managedUpload = bucket.upload(params, function (err) {
    if (err) {
      return false;
    }
    return true;
  });
  managedUpload.on('httpUploadProgress', (progress) => {
    const uploadedBytes = progress.loaded;
    const totalBytes = progress.total;
    const percentage = Math.round((uploadedBytes / totalBytes) * 100);

    setProgress(percentage);
    if (percentage >= 100) {
      setTimeout(() => {
        setShowProgress(false);
        setProgress(0);
        getVideos();
      }, 1000);
    }
  });

  await managedUpload.promise();

  return true;
};

async function handleMultiPartUpload(filename, file, id, setProgress, getVideos, setShowProgress, setAddVideoModalOpen) {
  const params = {
    Bucket: process.env.REACT_APP_BUCKET_NAME,
    Key: `videos/${process.env.REACT_APP_MODE}/${id}/account/` + filename,
    ContentType: file.type,
    ACL: 'public-read',
  };

  const initResponse = await bucket.createMultipartUpload(params).promise();
  const uploadId = initResponse.UploadId;
  const partSize = DEFAULT_CHUNK_SIZE
  let partNumber = 1;
  let offset = 0;
  const fileParts = [];

  while (offset < file.size) {
    const chunk = file.slice(offset, offset + partSize);
    const partParams = {
      Bucket: process.env.REACT_APP_BUCKET_NAME,
      Key: `videos/${process.env.REACT_APP_MODE}/${id}/account/` + filename,
      UploadId: uploadId,
      PartNumber: partNumber,
      Body: chunk,
    };

    const partResponse = await bucket.uploadPart(partParams).promise();

    fileParts.push({ PartNumber: partNumber, ETag: partResponse.ETag });
    setProgress(parseFloat((offset / file.size) * 100).toFixed(1));
    partNumber++;
    offset += partSize;
  }

  // Complete the multipart upload
  const completeParams = {
    Bucket: process.env.REACT_APP_BUCKET_NAME,
    Key: `videos/${process.env.REACT_APP_MODE}/${id}/account/` + filename,
    UploadId: uploadId,
    MultipartUpload: {
      Parts: fileParts,
    }
  };

  await bucket.completeMultipartUpload(completeParams).promise();

  setTimeout(() => {
    getVideos();
    setShowProgress(false);
    setProgress(0);
  }, 1000);
}

export const uploadVideoThumbnail = function (filename, file, id) {
  uploadImage(filename, file, id, "videos")
};

