import {
  GET_CITY_SUCCESS,
  GET_CITY_FAILURE,
  SHOW_LOADING,
} from "../types"

import { getCitiesApi } from './api'

const showloading = () => {
  return {
    type: SHOW_LOADING,
  };
};
const Success = (data, type) => {
  return {
    type,
    payload: data.data.data,
  };
};

const Error = (error, type) => {
  return {
    type,
    payload: error,
  };
};


export const getCities = () => {
  return async (dispatch) => {
    dispatch(showloading());
    try {
      let data = await getCitiesApi()
      dispatch(Success(data, GET_CITY_SUCCESS));
    } catch (error) {
      dispatch(Error(error, GET_CITY_FAILURE));
    }
  };
}