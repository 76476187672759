import React, { PureComponent } from "react";
import styles from "./projects.module.scss";
import { connect } from "react-redux";
import { getProjectsByID } from "../../../redux/Projects/actions";
import { ReactComponent as MainimgWhiteYell } from '../../../assets/icons/mainimgWhiteYell.svg';
import NormalSidebar from "../../../components/director-sidebar/normalSidebar/index";
import ProjectsListComp from "../../../components/projectsList";
import { Link } from "react-router-dom";
import Header from "../../../components/shared/header";
import Footer from "../../../components/shared/footer/index";
import Animation from "../../../components/animation";

class ProjectsList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      limit: window.innerWidth > 1600 ? 8 : 6,
      offSet: 0,
      projectList: [],
      isOpenSidebar: false,
      showVideo: true,
      showSpinner: false
    };
  };
  
  showAnimationOnPageLoad = () => {
    this.setState({
      showVideo: false
    })
  }

  componentDidMount() {
    let params = this.getParams();
    this.getProjectApi(params);
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.projectList !== prevProps.projectList) {
      this.setState({
        ...this.state,
        projectList: [
          ...this.state.projectList,
        ],
      });
    }

    if (prevState.offSet !== this.state.offSet) {
      let params = this.getParams();
      this.getProjectApi(params);
    }
  };

  getParams = () => {
    let params = {
      limit: this.state.limit,
      offSet: this.state.offSet
    };
    return params;
  };

  handleProjectPagination = (type) => {
    let offSet = this.state.offSet - this.state.limit;
    if (type === "next") {
      offSet = this.state.offSet + this.state.limit;
    }
    this.setState({
      ...this.state,
      offSet
    });
  };

  handleSpinner = () => {
      this.setState({
        showSpinner: false
      })
  }

  getProjectApi = (params) => {
    this.setState({
      showSpinner: true
    })
    let userData = localStorage.getItem("user");
    userData = JSON.parse(userData);
    this.props.getProjectsByID({
      userId: userData.id,
      offset: params.offSet,
      limit: this.state.limit,
      handleSpinner: this.handleSpinner
    });
  };

  render() {
    const getProjects = this?.props?.ProjectList;
    const projectsList = getProjects?.rows || [];
    const projectsCount = getProjects?.count || 0;
    return (
    <>
     { this.state.showVideo ? 
      <Animation showAnimationOnPageLoad={this.showAnimationOnPageLoad}/> 
      : 
      <div className={styles.mainSecbox}>
        {projectsCount === 0 ?
          (
            <>
              <div className={`${styles.mainSecInner} sidebar-director`}>
                <NormalSidebar />
              </div>
              
              <div className={styles.projects}>
                <div className={styles.header}> 
                  <Header
                    heading={"My Projects"}
                    subHeading={"You Don't Have Any Projects Yet"}
                    isSidebarOpen={false}
                  />
                </div>

                <div className={styles.mainsec}>
                  <Link to="/director/projects/create">
                    <div className={styles.centerimgboc}>
                      <MainimgWhiteYell className={`mainWhiteCricleSvg ${styles.blkWhitehover}`}/>
                      <h3 className={styles.centerTxtsec}>Create Now</h3>
                    </div>
                  </Link>
                </div>
              </div>
              
              <Footer history={this.props.history} />
            </>
          ) : (
            <>
              <ProjectsListComp 
                projectsList = { projectsList }
                projectsCount = { projectsCount }
                getProject = { this.getProjectApi }
                handleProjectPagination={ this.handleProjectPagination }
                isOpenSidebar = { this.state.isOpenSidebar }
                setIsOpenSidebar = { (value)=>this.setState({isOpenSidebar: value}) }
                limit = { this.state.limit }
                offSet = { this.state.offSet }
                showSpinner = {this.state.showSpinner}
              />
            </>
          )}
     </div>}
    </>
    );
  }
}

const MapStatetoProps = (state) => {
  return {
		ProjectList: state.Projects.data.projectList,
  };
};

const Projects = connect(MapStatetoProps, { getProjectsByID })(
  ProjectsList
);

export default Projects;
