import React, { useState, useEffect } from "react";
import { login } from "./../../redux/SignIn/actions";
import { connect } from "react-redux";
import Button from "react-bootstrap/Button";
import { InputGroup } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import "./auth-layout.scss";
import logo from "../images/main-logo.png";
import userIcon from "../images/login-user-icon.png";
import passIcon from "../images/login-pass-icon.png";
import { removeSelectedProjectFromLocalStorage } from "../../helpers/removeSelectedProject";
import { toast } from "react-toastify";

const AuthLayout = (props) => {
  const screenWidth = window.innerWidth;

  let [email, setEmail] = useState("");
  let [password, setPassword] = useState("");
  let loginType = "admin";

  useEffect(() => {
    if (props.SignIn.loggedIn === true && props.history && localStorage.hasOwnProperty("user") && localStorage.getItem("loginType") === "admin") {
      props.history.push("/admin/dashboard");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.SignIn.loggedIn]);

  const handleLoginRequest = (event) => {
    event.preventDefault();
    if (screenWidth < 1360) {
      toast.error("You cannot login with small screen resolution less than 1360");
    } else {
      props.login({ email, password }, loginType)
      removeSelectedProjectFromLocalStorage();
    }
  }
  
  return (
    <>
    <div className={"login-wrapper login-admin"}>
        <div className="loginCol">
          <img src={logo} alt="Logo" />
        </div>
        <div className="loginCol ">
          <div className="form-col">
            <h5 className="signText">Sign In</h5>
            <Form className="login-form">
              <Form.Group className="field-group">
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      <img src={userIcon} alt="userIcon" />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    className="inputFieldAdmin"
                    type="email"
                    placeholder="Email"
                    onChange={(event) => {
                      setEmail(event.target.value);
                    }}
                  />
                </InputGroup>
              </Form.Group>

              <Form.Group className="field-group">
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      <img src={passIcon} alt="userIcon" />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    className="inputFieldAdmin"
                    type="password"
                    placeholder="Password"
                    onChange={(event) => {
                      setPassword(event.target.value);
                    }}
                  />
                </InputGroup>
              </Form.Group>
              <div className="helperRow">
                <Form.Group
                  controlId="formBasicCheckbox"
                  className="rememberCheck"
                >
                  <Form.Check type="checkbox" label="Remember Me" />
                </Form.Group>
                <div>
                  <span className="forgotText">Forgot Your Password ?</span>
                </div>
              </div>
                <Button
                  onClick={(event) => handleLoginRequest(event)}
                  type="submit"
                  variant="primary"
                  className="primary-btn"
                  disabled={!email || !password}
                >
                  Sign In
                </Button>
            </Form>
          </div>
        </div>
      </div>
      </>
  );
};

AuthLayout.propTypes = {};

const mapStateToProps = (state) => {
  return {
    SignIn: state.SignIn
  };
};

export default connect(mapStateToProps, { login })(AuthLayout);
