import {
  GET_FILTER_TYPES_SUCCESS,
  GET_FILTER_TYPES_ERROR,
} from "./../types";

import { GetAllFilterTypes } from "./api";

//fetching data
// const showloading = () => {
//     return {
//         type: SHOW_LOADING
//     }
// }

//when error is occurred
const Error = (error, type) => {
  return {
    type,
    payload: error,
  };
};

//when successfully fetching data
const Success = (data, type) => {
  return {
    type,
    payload: data,
  };
};

export const getFiltersList = () => {
  return async (dispatch) => {
    try {
      // dispatch(showloading())
      let data = await GetAllFilterTypes();
      // if(data.data.status){
      dispatch(Success(data.data.data, GET_FILTER_TYPES_SUCCESS));
      // } else {
      // Error("Error", GET_FILTER_TYPES_ERROR)
      // }
    } catch (e) {
      Error(e, GET_FILTER_TYPES_ERROR);
    }
  };
};
