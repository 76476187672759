import React, { useState, useEffect } from "react";
import styles from "./videos.module.scss";
import { ReactComponent as PlayButton } from "../../../../assets/icons/playButton.svg";
import AddVideoModal from "../../../../components/modal/VideoModal/AddVideoModal";
import VideoPlayerModal from "../../../../components/modal/VideoModal/VideoPlayerModal";
import {getUsersVideos, deleteUserVideo} from "../../../../services/userService";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { toast } from "react-toastify";
import { useParams } from "react-router";
import removeIcon from "../../../../assets/icons/remove.png";
import DeleteUserModal from "../../../../components/deleteUserModal";
import Spinner from 'react-bootstrap/Spinner';

const Videos = ({ accountDetail }) => {
  const [addVideoModalOpen, setAddVideoModalOpen] = useState(false);
  const [videoPlayerModalOpen, setVideoPlayerModal] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false)
  const [userVideo, setUserVideo] = useState([])
  const [showProgress, setShowProgress] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [videoDeleteData, setVideoDeleteData] = useState({});
  const [progress, setProgress] = useState(0)
  const [videoData, setVideoData] = useState({
    thumbnail: '',
    Video_url: ''
  })

  const location = useParams()
  const getVideos = async() => {
    const result = await getUsersVideos(location.id)
    if (!result.data?.status) {
      toast.error(result.msg);
    } else {
      setUserVideo(result.data.data)
    }
  }

  const handleModal = (thumbnail, Video_url) => {
    setVideoPlayerModal(true)
    setVideoData({
      thumbnail,
      Video_url
    })
  }
  
  const handleDeleteVideo = async(id) => {
    setShowSpinner(true)
    try {
      const result = await deleteUserVideo(id, videoDeleteData);
      if (result.success) {
        toast.success("Video has been deleted successufully");
        getVideos();
        setShowSpinner(false)
      }
    } catch(error) {
        toast.error("Some error occured while deleteing Video"); 
    }
  }
  
  const handlevideoModal = () => {
    if(!showProgress){
      setAddVideoModalOpen(true)
    }
  }

  useEffect(() => {
    getVideos()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    <React.Fragment>
      <div className={styles.videosTab}>
        <div className={styles.videoPlayerSide}>
        {userVideo.length > 0 && <div className={styles.videoPlayer}>
            <div
              className={styles.playButton}
              onClick={() => handleModal(userVideo[0]?.video_thumbnail, userVideo[0]?.video_url)}
            >
              <PlayButton />
            </div>
            <div className={styles.modalVideoDetail}>
              <h4>{userVideo[0]?.title}</h4>
              <h5>{userVideo[0]?.description}</h5>
            </div>
            <img
              src={`${process.env.REACT_APP_YOUCAST_USER_VIDEOS_PATH}/${process.env.REACT_APP_MODE}/${userVideo[0]?.user_id}/account/thumbnail/th${userVideo[0]?.video_thumbnail}`}
              alt="user"
              width="650px"
              height="400px"
            />
          </div>
        }
        </div>
        <div className={styles.multiVideoSide}>
          <div className={styles.multiVideoContainer}>
            {userVideo.map((item) => (
              <div key={item.id} className={styles.multiVideoItem}>
                <div className={styles.videoItem}>
                  <div
                    className={styles.playButton}
                    onClick={() =>
                      handleModal(item.video_thumbnail, item.video_url)
                    }
                  >
                    <PlayButton />
                  </div>
                  <div className={styles.modalVideoDetail}>
                    <h5>{item.title}</h5>
                    <p>{item.description}</p>
                  </div>
                  <img
                    src={`${process.env.REACT_APP_YOUCAST_USER_VIDEOS_PATH}/${process.env.REACT_APP_MODE}/${item.user_id}/account/thumbnail/th${item.video_thumbnail}`}
                    alt="user"
                  />
                </div>
                <img
                  className={styles.remove}
                  src={removeIcon}
                  alt="remove"
                  onClick={() => {
                    setShowDeleteModal(true);
                    setVideoDeleteData(item);
                  }}
                />
              </div>
            ))}
            {videoPlayerModalOpen && (
              <VideoPlayerModal
                show={videoPlayerModalOpen}
                hide={() => setVideoPlayerModal(false)}
                data={videoData}
              />
            )}
            <div className={styles.multiVideoItem}>
              <div
                className={styles.videoItem}
                onClick={handlevideoModal}
              >
                {showProgress ? (
                  <CircularProgressbar
                    value={progress}
                    color={"#000000"}
                    className={styles.ProgressBar}
                    text={`${progress}%`}
                  />
                ) : (
                  <>
                    {!showSpinner? <span className={styles.addVideo}>Add Video</span>
                    : <Spinner animation="border" variant="primary" />}
                  </>
                )}
              </div>
            </div>
            {addVideoModalOpen && (
              <AddVideoModal
                show={addVideoModalOpen}
                hide={() => setAddVideoModalOpen(false)}
                getVideos={getVideos}
                setProgress={setProgress}
                setShowProgress={setShowProgress}
                setAddVideoModalOpen={setAddVideoModalOpen}
              />
            )}
            <DeleteUserModal
              show={showDeleteModal}
              handleClose={() => setShowDeleteModal(false)}
              id={videoDeleteData?.id}
              heading={"Delete Video?"}
              subHeading={"Are you sure that you want to delete this Video?"}
              handleDelete={handleDeleteVideo}
            />
          </div>
          <div className={styles.modalDetail}>
            <h4> {accountDetail?.name} </h4>
            <p> Actor/Modal </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Videos;
