import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import ImageModal from "../../../../components/modal/ImageModal/ImageModal";
import { uploadImage, getUserImage, deleteUserImage } from "../../../../services/userService";
import { toast } from "react-toastify";
import "bootstrap/dist/css/bootstrap.min.css";
import styles from "./photos.module.scss";
import removeIcon from "../../../../assets/icons/remove.png";
import DeleteUserModal from "../../../../components/deleteUserModal";
import { uploadThumbnailImage } from "../../../../services/s3"
import { CircularProgressbar } from 'react-circular-progressbar';
import Spinner from 'react-bootstrap/Spinner';
import 'react-circular-progressbar/dist/styles.css';
import imageCompression from 'browser-image-compression';

const Photos = ({accountDetail}) => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    centerPadding: '0px',
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const location = useParams();
  const inputFile = useRef(null);
  const [userImages, setUserImage] = useState(accountDetail?.images);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false)
  const [imageDeleteData, setImageDeleteData] = useState({});
  const [showProgress, setShowProgress] = useState(false);
  const [progress, setProgress] = useState(0)

  async function handleImageUpload(file) {

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 400,
      useWebWorker: true,
    }
    try {
      const compressedFile = await imageCompression(file, options);
      return compressedFile;
    } catch (error) {
      console.log(error);
    }
  
  }
  
  const openFileInput = async (event) => {
    let filesOfUser = Object.values(event.target.files)
      for (let i = 0; i < filesOfUser.length; i++) {
      setShowProgress(true)
      const currentTime = new Date().getTime()
      let changedName =  currentTime+"."+filesOfUser[i].name.split('.')[filesOfUser[i].name.split('.').length-1]
      const resizeImage = await handleImageUpload(filesOfUser[i]);
      let ChangeThumbnail = currentTime + "." + resizeImage.name.split('.')[resizeImage.name.split('.').length-1]
      uploadThumbnailImage(changedName, filesOfUser[i], location.id ,  "thumbnail/th" + ChangeThumbnail, setProgress, setShowProgress, getUserImages , inputFile, resizeImage )
      let formValue = {
        file: changedName,
        id: location.id
      }
      const result = await uploadImage(formValue);
      if (!result?.status) {
        toast.error(result.msg);
      }
    }
  };

  const getUserImages = async() => {
      const result = await getUserImage(location.id);
      if (!result.data?.status) {
      toast.error(result.msg);
    } else {
      setUserImage(result.data.data);
    }
  }
  
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState('');

  const openImageModal = (src) => {
    setIsImageModalOpen(true);
    setModalImage(src);
  }

  const handleFiles = () => {
    if(!showProgress){
      inputFile.current.click()
    }
  }
  
  const handleDeleteImage = async(id) => {
    setShowSpinner(true)
    try {
      const result = await deleteUserImage(id, imageDeleteData);
      if (result.success) {
        toast.success("Image has been deleted successfully");
        getUserImages();
        setShowSpinner(false);
      }
    } catch(error) {
        toast.error("Some error occured while deleting image");
    }
  }
  
  return (
    <>
      <div className={styles.photosTab}>
        <div className={styles.singleSlideTab}>
          <Slider {...settings} className="slickSlider">
            {userImages.map((item) => {
              return (
                <div key={item.id} className={styles.singleSlideCol}>
                  <div className={styles.slideImgBox}>
                    <img
                      src={`${process.env.REACT_APP_YOUCAST_USER_IMAGES_PATH}/${process.env.REACT_APP_MODE}/${accountDetail?.id}/account/${item.title}`}
                      alt="user"
                    />
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
        <div className={styles.multiSlideSide}>
          <div className={styles.multiSlideSideContainer}>
            {userImages.map((item) => {
              return (
                <div key={item.id} className={styles.multislideItem}>
                  <div className={styles.photoImgBox}>
                    <img
                      className={styles.userImage}
                      src={`${process.env.REACT_APP_YOUCAST_USER_IMAGES_PATH}/${process.env.REACT_APP_MODE}/${accountDetail?.id}/account/thumbnail/th${item.title}`}
                      alt="user"
                      onClick={() =>
                        openImageModal(
                          `${process.env.REACT_APP_YOUCAST_USER_IMAGES_PATH}/${process.env.REACT_APP_MODE}/${accountDetail?.id}/account/${item.title}`
                        )
                      }
                    />
                    <img
                      className={styles.remove}
                      src={removeIcon}
                      alt="remove"
                      onClick={() => {
                        setShowDeleteModal(true);
                        setImageDeleteData(item);
                      }}
                    />
                  </div>
                </div>
              );
            })}
            <div className={styles.multislideItem}>
              <div
                className={styles.addPhotoContainer}
                onClick={handleFiles}
              >
                <div className={styles.photoClickBox}>
                  <form encType="multipart/form-data">
                    <input
                      type="file"
                      onChange={openFileInput}
                      ref={inputFile}
                      accept="image/*"
                      className={styles.inputFile}
                      multiple
                    />
                  </form>
                  {showProgress ? (
                    <CircularProgressbar value={progress} color=
                    {'#000000'} className={styles.ProgressBar} text={`${progress}%`} />
                   ) : (
                    <>
                      {!showSpinner? <span className={styles.addPhoto}>Add Photo</span>
                      : <Spinner animation="border" variant="primary" />}
                    </>
                   )} 
                </div>
              </div>
            </div>
          </div>
          <div className={styles.modalDetail}>
            <h4>{accountDetail?.name}</h4>
            <p>Actor/Modal</p>
          </div>
        </div> 
      </div>

      {isImageModalOpen && (
        <ImageModal
          src={modalImage}
          show={isImageModalOpen}
          hide={() => setIsImageModalOpen(false)}
        />
      )}

      <DeleteUserModal
        show={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        id={imageDeleteData?.id}
        heading={"Delete Image?"}
        subHeading={"Are you sure that you want to delete this image?"}
        handleDelete={handleDeleteImage}
      />
    </>
  );
}

export default Photos;
