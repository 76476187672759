import axios from 'axios'

const baseUrl = process.env.REACT_APP_YOUCAST_API_END_POINT;

const ApiUrls = {
  LOGIN: baseUrl + "/login",
  GET_FILTERS: baseUrl + "/filters",
  GET_MODEL_LIST: baseUrl + "/users",
  GET_USER_LIST: baseUrl + "/users",
  GET_USER_COUNT: baseUrl + "/users/count",
  GET_SUBSCRIPTIONS_COUNT: baseUrl + "/subscriptions/count",
  CREATE_USER: baseUrl + "/users/createUser",
  DELETE_USER: baseUrl + "/users/delete/",
  UPDATE_USER: baseUrl + "/users/update/",
  GET_USER_BY_ID: baseUrl + "/users/",
  GET_SUBSCRIPTIONS_LIST: baseUrl + "/subscriptions",
  DELETE_SUBSCRIPTIONS: baseUrl + "/subscriptions/delete/",
  DELETE_MULTI_ACCOUNTS: baseUrl + "/accounts/deleteMany",
  UPDATE_SUBSCRIPTIONS: baseUrl + "/subscriptions/update/",
  ADD_OR_UPDATE_SUBSCRIPTIONS: baseUrl + "/subscriptions/addOrUpdate",
  ADD_OR_UPDATE_USERDETAILS: baseUrl + "/users/addOrUpdate",
  ACTIVATE_SUBSCRIPTION: baseUrl + "/subscriptions/activate/",
  GET_CITY: baseUrl + '/filters/city',
  UPDATE_USER_STATUS: baseUrl + '/users/userstatus/',
  ADMIN_FAV_STATUS: baseUrl + '/users/favourite',
  UPDATE_USER_MODEL_STATUS:  baseUrl + '/users/update-status',
  UPLOAD_USER_IMAGE: baseUrl + '/users/uploadfile',
  GET_USER_IMAGE: baseUrl + '/users/user-images',
  UPDATE_USER_SPECIFIC_FIELD: baseUrl + "/users/update-specific-field",
  UPLOAD_USER_VIDEO: baseUrl + '/users/upload-video',
  DELETE_USER_IMAGE: baseUrl + "/users/delete-image",
  GET_USER_VIDEOS: baseUrl + "/users/get-videos",
  DELETE_USER_VIDEO: baseUrl + "/users/delete-video",
  GET_PROJECTS_BY_ID: baseUrl + "/projects",
  DELETE_PROJECT_BY_ID: baseUrl + "/projects",
  CREATE_USER_PROJECT: baseUrl + "/projects/create-project",
  GET_PROJEECT_BY_ID: baseUrl + "/projects/get-project",
  GET_TALENTS_LIST: baseUrl + "/talents",
  UPDATE_USER_PROJECT: baseUrl + "/projects/update-project",
  GET_PROJECT_ROLE_BY_PROJECT_ID: baseUrl + "/project-roles",
  GET_PROJECT_DASHBOARD_BY_ID: baseUrl + "/projects/project-dashboard",
  CREATE_PROJECT_ROLE: baseUrl + "/project-roles/createAndUpdateRole",
  CREATE_PROJECT_ROLE_OPTION: baseUrl + "/project-roles/add-talent-to-role",
  GET_EPISODES_AND_SCENES_BY_PROJECT_ID: baseUrl + "/episode-scene",
  GET_EPISODES_AND_SCENES_LIST_BY_PROJECT_ID: baseUrl + "/episode-scene/list",
  GET_PROJECT_ROLES_BREAKDOWN: baseUrl + "/project-roles/breakdown",
  GET_TALENT_PROFILE: baseUrl + "/talents/talent-profile", 
  ADD_OR_UPDATE_EPISODES: baseUrl + "/episode-scene/addOrUpdateEpisode",
  DELETE_EPISODES_BY_ID: baseUrl + "/episode-scene",
  DELETE_PROJECT_ROLE_BY_ID: baseUrl + "/project-roles/delete-role",
  GET_ALL_LOGIN_USER: baseUrl + "/login-user",
  GET_SCENES_LIST_BY_EPISODE_ID: baseUrl + "/episode-scene/scenes",
  CRETATE_LOGIN_USER: baseUrl + "/login-user/create",
  UPDATE_LOGIN_USER: baseUrl + "/login-user/update",
  GET_PROJECT_ROLES_BY_PROJECT_ID: baseUrl + "/episode-scene/roles",
  CREATE_PROJECT_ROLE_INTO_SCENE: baseUrl + "/episode-scene/insert-role-into-scene",
  GET_PROJECT_EPISODE_DETAIL_BY_ID: baseUrl + "/episode-scene/project-episode-details",
  DELETE_LOGIN_USER: baseUrl + "/login-user/delete",
  DELETE_PROJECT_ROLE_FROM_SCENE_BY_ID: baseUrl + "/episode-scene/remove-role",
  DELETE_CAST_FROM_PROJECT_ROLE_BY_ID: baseUrl + "/project-roles/remove-cast",
  GET_DOWNLOAD_PDF: baseUrl + "/projects/dashboard/downloadPdf",
  DELETE_ALL_PROJECT_BY_USER_ID: baseUrl + "projects/delete-all-project",
  UPDATE_PROJECT_STATUS_BY_ID:  baseUrl + "/projects/update-project-status",
  UPDATE_PROJECT_ROLE_TALENT_STATUS: baseUrl + "/project-roles/update-project-role-talent/status"
};

const axiosConfig = (data) => {
  return {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    },
    params: data,
  };
}

// Add a response interceptor
axios.interceptors.response.use(
  response => {
    return response
  },
  function (error) {
    if (error.response.status === 401) {
      localStorage.setItem("accessToken", "");
      const loginType = localStorage.getItem("loginType");
      if (loginType === "director") {
        window.location.href = window.location.origin;   
      } else {
        window.location.href = window.location.origin + "/admin/login";   
      }
      return Promise.reject(error)
    }
    return Promise.reject(error)
  }
)
export { ApiUrls, axiosConfig };
