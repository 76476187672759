import axios from 'axios'
import { ApiUrls, axiosConfig } from '../apiUrls';

export const SubscriptionsListingApi = (data) => {
  let newData = {
    limit: data.limit,
    offset: data.offset,
    paginate: data.paginate,
    search: data.search,
    subSearchTypes: data.subSearchTypes
  }

  return axios.get(ApiUrls.GET_SUBSCRIPTIONS_LIST, axiosConfig(newData))
}

export const deleteSubscriptionsApi = (id) => {
  return axios.delete(ApiUrls.DELETE_SUBSCRIPTIONS + id, axiosConfig());
};

export const subscriptionCountApi = (data) => {
  return axios.get(ApiUrls.GET_SUBSCRIPTIONS_COUNT, axiosConfig());
};