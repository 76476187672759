import axios from "axios";
import { ApiUrls, axiosConfig } from "../redux/apiUrls";

/**
 * 
 * @param {integer} roleId 
 * @param {Object} params 
 * @returns Error or success response
 * Comment: Get All admins and directors by role id
 */
export const getAllLoginUserList = async (roleId, params, requestType) => {
  try {
    const data = await axios.get(`${ApiUrls.GET_ALL_LOGIN_USER}/${roleId}?offSet=${params.offset}&limit=${params.limit}&requestType=${requestType}`, axiosConfig());
    return {
      status: true,
      data: data?.data?.data
    }
  } catch (error) {
    return {
      status: false,
      errorMsg: error?.response?.data?.msg
    }
  }
};

/**
 * 
 * @param {Object} loginUserData 
 * @returns Error or success response
 * Comment: Create login user
 */
export const createLoginUser = async(loginUserData) => {
  try {
    const data = await axios.post(ApiUrls.CRETATE_LOGIN_USER, loginUserData, axiosConfig());
    return {
			status: true,
			data: data?.data?.data
		}
  } catch (error) {
    return {
      status: false,
      errorMsg: error?.response?.data?.msg
    }
  }
};

/**
 * 
 * @param {object} loginUserData 
 * @returns Error or success response
 * Comment: Update login user
 */
export const updateLoginUser = async(loginUserData, id) => {
  try {
    const data = await axios.put(ApiUrls.UPDATE_LOGIN_USER + "/" + id, loginUserData, axiosConfig());
    return {
			status: true,
			data: data?.data?.data
		}
  } catch (error) {
    return {
      status: false,
      errorMsg: error?.response?.data?.msg
    }
  }
}

/**
 * 
 * @param {integer} id 
 * @returns Error or success response
 * Comment: Delete login user by id
 */
export const deleteLoginuser = async(id) => {
  try {
    const data = await axios.delete(ApiUrls.DELETE_LOGIN_USER + "/" + id, axiosConfig());
    return {
			status: true,
			data: data?.data?.data
		}
  } catch (error) {
    return {
      status: false,
      errorMsg: error?.response?.data?.msg
    }
  }
};