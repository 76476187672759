import {
    SHOW_LOADING,
    USER_LISTING_SUCCESS,
    USER_LISTING_ERROR,
    STOP_LOADING,
    USER_COUNT_SUCCESS,
    USER_COUNT_ERROR,
    MODEL_LISTING_SUCCESS,
    MODEL_LISTING_ERROR,
    GET_USER_BY_ID_SUCCESS,
    GET_USER_BY_ID_FAILURE,
    UPDATE_USER_BY_ID_SUCCESS,
    UPDATE_USER_STATUS_SUCCESS,
    UPDATE_USER_STATUS_FAILURE,
    ADMIN_FAV_STATUS_SUCCESS,
    MODEL_LISTING_COUNT,
    UPDATE_USER_INFO
} from './../types'

const Initial_State = {
    loading: false,
    data: {
        UserList: [],
        UserCount: {},
        ModelList: [],
        subscriptionType: [],
        subscriptionPlan: [],
        userDetail: {},
        modelCount: 0
    },
    error: ''
}

export const UserReducer = (state = Initial_State, action) => {
    switch (action.type) {
        case SHOW_LOADING:
            return { ...state, data: { ...state.data, UserList: [], ModelList: [] } }
        case USER_LISTING_SUCCESS:
            return {
                ...state,
                data: { ...state.data, UserList: action.payload },
                loading: false
            }
        case USER_LISTING_ERROR:
            return { ...state, loading: false, error: action.payload }
        case STOP_LOADING:
            return { ...state, loading: false }
        case USER_COUNT_SUCCESS:
            return {
                ...state,
                data: { ...state.data, UserCount: action.payload },
                loading: false
            }
        case USER_COUNT_ERROR:
            return { ...state, loading: false, error: action.payload }
        case MODEL_LISTING_SUCCESS:
            return {
                ...state,
                data: { ...state.data, ModelList: action.payload },
                loading: false
            }
        case MODEL_LISTING_COUNT:
            return {
                ...state,
                data: { ...state.data, modelCount: action.payload}
            }
        case MODEL_LISTING_ERROR:
            return { ...state, loading: false, error: action.payload }
        case GET_USER_BY_ID_SUCCESS:
            return {
                ...state,
                data: { 
                    ...state.data, 
                    subscriptionPlan: action.payload.subscriptionPlan,
                    subscriptionType: action.payload.subscriptionType,
                    userDetail: action.payload.userData,
                },
                loading: false
            }
        case GET_USER_BY_ID_FAILURE:
            return { ...state, loading: false, error: action.payload }

        case UPDATE_USER_BY_ID_SUCCESS:
            return { ...state, data: {...state.data, ModelList: action.payload}}
        case UPDATE_USER_STATUS_SUCCESS:
                return {
                    ...state,
                    data: { ...state.data, userDetail: action.payload },
                    loading: false
                }
        case UPDATE_USER_STATUS_FAILURE:
                return { ...state, loading: false, error: action.payload }

        case ADMIN_FAV_STATUS_SUCCESS:
            return {
                 ...state,
                 data: { ...state}
             }
        case UPDATE_USER_INFO:
            return {
                ...state,
                data: { 
                    ...state.data,
                    userDetail: action.payload,
                }
            }
        default:
            return state
    }
}