import React from 'react'
import {
    Route,
    Redirect,
} from 'react-router-dom';

export const PrivateRoute = ({ component: Component, ...rest }) => {
    const token = localStorage.getItem('accessToken');
    const loginType = localStorage.getItem('loginType');
    const jwt = require('jsonwebtoken');
    const user = jwt.decode(token);
    let isValidToken = false;
    if (user && user.data.id) {
        isValidToken = true
    }
    return (<Route { ...rest } render={(props) => {
        if (isValidToken) {
            if (loginType === "director" && props.match.path === "/admin") {
                return <Redirect to="/director/projects" />
            } else {
                return <Component { ...props } />
            }
        } else {
            return <Redirect from="/admin" to="/admin/login" />
        }
    }} />)
}

export const GeneralRoute = ({ component: Component, ...rest }) => {
    const token = localStorage.getItem('accessToken');
    const jwt = require('jsonwebtoken');
    const user = jwt.decode(token);
    let isValidToken = false;
    if (user && user.data.id) {
        isValidToken = true
    }
    return (<Route { ...rest } render={ (props) =>
        isValidToken === false ? <Component { ...props } /> : <Redirect from="/admin/login" to="/admin/dashboard" />
    } />)
}
