import React, { useState, useEffect, useRef } from "react";
import styles from "./projectDetail.module.scss";
import { ReactComponent as LogoCamera } from "../../../assets/icons/cameraLogo.svg";
import { ReactComponent as Listicon } from "../../../assets/icons/listicon.svg";
import { ReactComponent as CircleArc } from "../../../assets/icons/circleArc.svg";
import { ReactComponent as DoubleDownAroow } from "../../../assets/icons/doubleDownAroow.svg";
import { ReactComponent as DoubleUpArrow } from "../../../assets/icons/doubleUpArrow.svg";
import { ReactComponent as YelloCircle } from "../../../assets/icons/yellowcircle.svg";
import { ReactComponent as PersonPlusSvg } from "../../../assets/icons/NoCastWithPlus.svg";
import userIcon from "../../../assets/images/RoleCast.png";
import { getProjectRolesBreakdown, updateProjectRoleTalentStatus } from "../../../services/projectRole";
import { getProjectById } from "../../../services/projectService";
import { defaultLimitAdminProjectRoleDetail } from "../../../constant";
import { useParams, useHistory } from "react-router-dom";
import Moment from "moment";
import Dropdown from 'react-bootstrap/Dropdown';

const ProjectDetail = () => {
  let roleOptionCount = 0;
  const ref = useRef(null);
  const location = useParams();
  const [activeRoleIndex, setActiveRoleIndex] = useState(0);
  const [roleBreakdown, setRolesBreakdown] = useState([]);
  const [activeRolesDetails, setActiveRolesDetails] = useState([]);
  const [showingFrom, setShowingFrom] = useState(0);
	const [showingTo, setShowingTo] = useState(0);
  const [rolesBreakdownCount, setRolesBreakdownCount] = useState(0);
  const [projectData, setProjectData] = useState();
  const [projectPageType, setProjectPageType] = useState("projectDetail");
  const history = useHistory();

  const [params, setParams] = useState({
		projectId: location.projectId,
		limit: defaultLimitAdminProjectRoleDetail,
		offSet: 0
	});

  const fetchProjectRolesBreakdown = async () => {
    const result = await getProjectRolesBreakdown(params);
		if (result.status) {
      setRolesBreakdown(result?.data?.rows);
      setActiveRolesDetails(result?.data?.rows[0]);
			setRolesBreakdownCount(result?.data?.count);
		}
	};

  const projectDetails = async () => {
    const result  = await getProjectById(location.projectId, "show");
    if (result?.data?.status) {
      setProjectData(result.data.data);
    }
  };

  useEffect(() => {
    if (projectPageType === "projectDetail"){
      projectDetails();
    }
    fetchProjectRolesBreakdown();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params.offSet]);

  useEffect(() => {
    setActiveRolesDetails(roleBreakdown[activeRoleIndex]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeRoleIndex]);

  useEffect(() => {
		setShowingFrom(params.offSet + 1);
		const tempCount = params.offSet + 1 + roleBreakdown?.length - 1;
		setShowingTo(tempCount);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [roleBreakdown, showingFrom, showingTo]);
  
  const handleScrollCastAndRole = (event) => {
    const container = ref.current;
    container.scrollTo({
      left: container.scrollLeft + (event.deltaY * 1)
    });
  };
  
  const handleProjectRolePagination = (type) => {
		let offSet = params.offSet - params.limit;
		if (type === "next") {
			offSet = params.offSet + params.limit;
		}
		setParams({
			...params,
			offSet
		});
	};

  const handleProjectRoleTalentStatus = async (projectRoleId, talentId, talentStatus) => {
    const data = {
      projectRoleId: projectRoleId,
      talentId: talentId,
      talentStatus: talentStatus
    };
    await updateProjectRoleTalentStatus(data);
    const updatedRoleOptions = activeRolesDetails.roleOptions.map((roleOption)=> (
      roleOption.talent_id === talentId ? {...roleOption, talent_status: talentStatus} : roleOption
    ));
    setActiveRolesDetails({...activeRolesDetails, roleOptions: updatedRoleOptions});
  };

  return (
    <>
    <div className={styles.mainHeader}>
      <p className={styles.toptext}>Giving Talents a better chance</p>
      <div className={styles.logoBox}>
        <LogoCamera className={styles.logoImg} />
        <div className={styles.logotext}>
          <p className={styles.logotopText}>{activeRolesDetails?.projectRoles?.project_name}</p>
          <p className={styles.logobottomText}>Project Dashboard</p>
        </div>
      </div>
      <div className={styles.detailBreakDwn}>
        <p className={projectPageType === "projectDetail" ? styles.selectedProBtn : styles.proBtn} onClick={() => setProjectPageType("projectDetail")}>Project Details</p>
        <p className={projectPageType === "breakDown" ? styles.selectedBreakBtn : styles.breakBtn} onClick={() => setProjectPageType("breakDown")}>Break Down</p>
      </div>
    </div>
      {projectPageType === "breakDown" ?
        <div className={styles.breakDownPage}>
        <div className={styles.leftSideDesign}>
          <div className={styles.roleTotalNum}>
            Total {rolesBreakdownCount} Roles
          </div>
          <ul className={styles.rolesList}>
            {roleBreakdown.map((item, index) => (
              <li key={item.id} className={styles.innerRolesList} onClick={() => setActiveRoleIndex(index)}>
                <Listicon className={styles.listicons} />
                <p className={activeRolesDetails?.id === item.id ? styles.selectedListIcontext : styles.listIcontext}>{item.role_name}</p>
              </li>
            ))}
          </ul>
          {roleBreakdown?.length > 0 &&
          <ul className={styles.rolePagination}>
              <p className={styles.silebotsubText}>Showing {showingFrom} to {showingTo > 0 ? showingTo : ''} Roles</p>
              <li className={(roleBreakdown?.length < params.limit || showingTo === rolesBreakdownCount) ? styles.disable : styles.arrowbox} onClick={() => handleProjectRolePagination("next")}>
                <DoubleDownAroow className={styles.dblarrowSvg} /><span>Move Down</span>
              </li>
              <li className={showingFrom === 1 ? styles.disable : styles.arrowbox} onClick={() => handleProjectRolePagination("prev")}>
                <DoubleUpArrow className={styles.dblarrowSvg}/><span>Move Up</span>
              </li>
          </ul>
          }
        </div>
            <div className={styles.rightSideDesign}>
                <div className={styles.castBrkBox}>
                    <p className={styles.castTitle}>Cast Breakdown</p>
                    <div className={styles.detailNumbBox}>
                        <p className={styles.detailtxt}><span>{activeRolesDetails?.episode_count} </span>Episodes</p>
                        <p className={styles.detailtxt}><span>{activeRolesDetails?.scene_count} </span>Scenes</p>
                        <p className={styles.detailtxt}><span>{activeRolesDetails?.roleOptions?.length} </span>Options</p>
                    </div>
                </div>
                <div className={styles.iconTileImgContainer}>
                    <div className={styles.iconBoardBox}>
                        <div className={styles.icontext}>
                            <CircleArc className={styles.iconSvg}/>
                            <p className={styles.iconLabel}>{activeRolesDetails?.role_name}</p>
                        </div>
                        <div className={styles.iconborder}></div>
                    </div>

                    <div className={styles.modelProfileBox} ref={ref} onWheel={handleScrollCastAndRole}>
                        {activeRolesDetails?.roleOptions?.length > 0 ? 
                        activeRolesDetails?.roleOptions?.map((item, index) => {
                          roleOptionCount += 1;
                          return (
                        <div className={styles.indivModel} key={index}>
                          <div className={styles.indivsinglebox} onClick={() => history.push(`/admin/dashboard/${item?.talentOptions?.id}?key=account`)}>
                            {item?.talentOptions?.images[0]?.title !== undefined ? 
                            <img src={`${process.env.REACT_APP_YOUCAST_USER_IMAGES_PATH}/${process.env.REACT_APP_MODE}/${item?.talent_id}/account/${item?.talentOptions?.images[0]?.title}`} alt="Logo" className={styles.imgIndvi}/> :
                            <img src={userIcon} className={styles.imgIndvi} alt="SingleModelImage"/>
                            }
                            <div className={styles.modelTextDetail}>
                                <p className={styles.mdlName}>{item?.talentOptions?.name}</p>
                                <p className={styles.mdlLabel}>{item?.talentOptions?.modelCategories[0]?.modelCategoriesName?.name}
                                </p>
                                <p className={styles.mdlLabel}>{item?.talentOptions?.modelCategories[1]?.modelCategoriesName?.name}
                                </p>
                                <p className={styles.mdlOption}>Option <span> {roleOptionCount}</span></p>
                            </div>
                          </div>
                              <div className={styles.proStatusBox}>
                                <p className={styles.statusLabel}>Status</p>
                                <div className='dropdownSearchAccount'>
                                  <Dropdown className={`${styles.statusTextDropDown} ${
                                      item?.talent_status === "busy" ? styles.busyColor :
                                      item?.talent_status === "confirmed" ? styles.confirmedStatusColor : 
                                      styles.noAnswerColor
                                      }`}
                                    >
                                    <Dropdown.Toggle variant="secondary" size="sm" id="dropdown-basic" data-class={styles.statusDropBox}>
                                      {item?.talent_status}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      <Dropdown.Item onSelect={() => handleProjectRoleTalentStatus(item.project_role_id, item.talent_id, "busy")}>Busy</Dropdown.Item>
                                      <Dropdown.Item onSelect={() => handleProjectRoleTalentStatus(item.project_role_id, item.talent_id, "confirmed")}>Confirmed</Dropdown.Item>
                                      <Dropdown.Item onSelect={() => handleProjectRoleTalentStatus(item.project_role_id, item.talent_id, "no-answer")}>No Answer</Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </div>
                        </div>
                        )}) :
                        <div className={styles.modelIndivBox}>
                          <div className={styles.ThirdNocastbox}>
                            <p className={styles.modleNoCast}>No Cast</p>
                            <PersonPlusSvg className={styles.PersonPlusSvg} />
                            <div className={styles.modleBtmSecNocast}>
                              <p>No Role Option</p>
                            </div>
                        </div>
                        </div>
                        }
                    </div>
                </div>
            </div>
        </div> :
        <div className={styles.projectDetailPage}>
          <div className={styles.projectDetail}>
            <div className={styles.icontextTitle}>
              <YelloCircle className={styles.yellowcircle} />
              <p className={styles.proDetailLabel}>User Name</p>
            </div>
            <p className={styles.valuePro}>{projectData?.user?.name}</p>
          </div>
          <div className={styles.projectDetail}>
            <div className={styles.icontextTitle}>
              <YelloCircle className={styles.yellowcircle} />
              <p className={styles.proDetailLabel}>Email</p>
            </div>
            <p className={styles.valuePro}>{projectData?.user?.email}</p>
          </div>
          <div className={styles.projectDetail}>
            <div className={styles.icontextTitle}>
              <YelloCircle className={styles.yellowcircle} />
              <p className={styles.proDetailLabel}>Project Type</p>
            </div>
            <div className={styles.valuewrappro}>
              <p className={styles.valuePro}>{projectData?.projectType?.name}</p>
              <p className={styles.valuePro}>{projectData?.projectType?.description}</p>
              </div>
          </div>
          <div className={styles.contactDetails}>
            <div className={styles.icontextTitle}>
              <YelloCircle className={styles.yellowcircle} />
              <p className={styles.proDetailLabel}>Contact</p>
            </div>
            <div className={styles.contactDetail}>
              <p className={styles.mobileDigitlabel}>1st Assistant </p>
              <p className={styles.mobileDigit}>{`${projectData?.assistant[0]?.name ? projectData?.assistant[0]?.name : '' } ${projectData?.assistant[0]?.phone_number ? `(${projectData?.assistant[0]?.phone_number})`: ''}`}</p>
            </div>
            <div className={styles.contactDetail}>
              <p className={styles.mobileDigitlabel}>2nd Assistant</p>
              <p className={styles.mobileDigit}>{`${projectData?.assistant[1]?.name ? projectData?.assistant[1]?.name : '' } ${projectData?.assistant[1]?.phone_number ? `(${projectData?.assistant[1]?.phone_number})`: ''}`}</p>
            </div>
            <div className={styles.contactDetail}>
              <p className={styles.mobileDigitlabel}>Producer</p>
              <p className={styles.mobileDigit}>{`${projectData?.producer_name ? projectData?.producer_name : '' } ${projectData?.producer_phone_number ? `(${projectData?.producer_phone_number})`: ''}`}</p>
            </div>
          </div>
          <div className={styles.proStartEndDate}>
            <div className={styles.proStartEndDatechild}>
              <div className={styles.icontextTitle}>
                <YelloCircle className={styles.yellowcircle} />
                <p className={styles.proDetailLabel}>Shooting Start Date</p>
              </div>
              <div className={styles.innerDateSec}>
                <div className={styles.dateBoxone}>
                  <p>Month</p>
                  <p className={styles.selectbox}>{Moment(projectData?.shooting_start_date).format("MMMM")}</p>
                </div>
                <div className={styles.dateBoxtwo}>
                  <p>Day</p>
                  <p className={styles.selectbox}>{Moment(projectData?.shooting_start_date).format("DD")}</p>
                </div>
                <div className={styles.dateBoxthree}>
                  <p>Year</p>
                  <p className={styles.selectbox}>{Moment(projectData?.shooting_start_date).format("YYYY")}</p>
                </div>
              </div>
            </div>
            <div className={styles.proStartEndDatechild}>
              <div className={styles.icontextTitle}>
                <YelloCircle className={styles.yellowcircle} />
                <p className={styles.proDetailLabel}>Shooting End Date</p>
              </div>
              <div className={styles.innerDateSec}>
                <div className={styles.dateBoxone}>
                  <p>Month</p>
                  <p className={styles.selectbox}>{Moment(projectData?.shooting_end_date).format("MMMM")}</p>
                </div>
                <div className={styles.dateBoxtwo}>
                  <p>Day</p>
                  <p className={styles.selectbox}>{Moment(projectData?.shooting_end_date).format("DD")}</p>
                </div>
                <div className={styles.dateBoxthree}>
                  <p>Year</p>
                  <p className={styles.selectbox}>{Moment(projectData?.shooting_end_date).format("YYYY")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default ProjectDetail;
