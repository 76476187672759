import axios from "axios";
import { ApiUrls, axiosConfig } from "../redux/apiUrls";

/**
 * @param: null
 * @returns : Error or success with data
 * Comment: Get list of countries
 */
export const getCountriesList = async () => {
	try {
		const data = await axios.get(ApiUrls.GET_FILTERS + "/country", axiosConfig());
		return {
			status: true,
			data: data?.data?.data
		}
	} catch (error) {
		return {
			status: false,
			errorMsg: error?.response?.data?.msg
		}
	}
};
