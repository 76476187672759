import React, { useState } from "react"
import styles from "./DownloadPdfModal.module.scss";
import { Button, Modal } from 'react-bootstrap';
import { useParams } from "react-router-dom";

const DownloadPDFModal = (props) => {
  const [selectedEpisode, setSelectedEpisode] = useState("");
  const location = useParams();

  return (
    <Modal
      show={props.show} 
      onHide={props.hide}
    >
      <Modal.Header closeButton>
				<Modal.Title>Select Episode</Modal.Title>
			</Modal.Header>
      <Modal.Body>
        { props.episodeDetail?.map((item, index) => {
          return item?.episodeScenes?.length > 0 &&
          <div className={styles.content} key={index}>
            <input 
              type={"radio"}
              id={'episode-' + index}
              checked={item?.id === selectedEpisode}
              onChange={() => setSelectedEpisode(item?.id)}
              className={styles.radioBtn}
            />
            <label htmlFor={'episode-' + index} className={styles.episodeName}>{item?.episode_name}</label>
          </div>
        })}

        <div className="text-center">
          <Button variant="primary" type="submit" className={styles.submitBtn} onClick={() => 
            props?.openSavedPdf(location.id, selectedEpisode)}
            >
            Download PDF
          </Button>
        </div>
			</Modal.Body>
    </Modal>
  );
}

export default DownloadPDFModal;