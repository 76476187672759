import React, { useState, useEffect} from 'react';
import styles from "./animation.module.scss";
import videoUrl from "../../../src/assets/video/animation.mp4"

const Animation = (props) => {

	const [showAnimation, setShowAnimation] = useState(true);

	useEffect(() => {
		const timeout = setTimeout(() => {
			setShowAnimation(false);
			if (typeof props.showAnimationOnPageLoad === 'function') {
				props.showAnimationOnPageLoad();
			  }
		},4000);
		return () => clearTimeout(timeout);
		 // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div style={{display: showAnimation ? "block" : "none"}}>
			<video className={styles.animation} src={videoUrl} autoPlay={true} muted={true} controls={false} />
		</div>
	);
};

export default Animation;