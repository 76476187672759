import React, { useEffect, useState } from "react";
import CreateNewProject from "../../../components/create-edit-project/index";
import { getCountriesList } from "../../../services/commonService";
import { getProjectTypesList } from "../../../services/projectService";
import NormalSidebar from "../../../components/director-sidebar/normalSidebar/index";
import styles from "./create-edit-project.module.scss";
import Header from "../../../components/shared/header";
import Footer from "../../../components/shared/footer";

const CreateEditProject = (props) => {

	const [countries, setCountries] = useState([]);
	const [projectType, setProjectType] = useState([]);
	const projectId = props.match.params.id || "";

	useEffect(() => {
		getCountryList();
		getProjectTypes();
		 // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * @param: null
	 * Comment: Get list of countries
	 */
  const getCountryList = async() => {
		const result =  await getCountriesList();
		if (result.status) {
			setCountries(result.data);
		}
  }

	/**
	 * @param: null
	 * Comment: Get list of project types
	 */
	const getProjectTypes = async() => {
		const result =  await getProjectTypesList();
		if (result.status) {
			setProjectType(result.data);
		}
  }

	const headingTitle = projectId !== "" ? "Edit Project" : "Create New Project";

	return (
		<>
		<div className="create-edit-project">
		<div className="normalSidebarcontainer">
				<NormalSidebar />
			</div>

			<div className={styles.projects}>
				<div className={styles.header}>
					<Header
						heading= { headingTitle }
						subHeading = { "" }
						isSidebarOpen = { false }
						pageType = { "create-project" }
						headingColorClass = { "orange" }
					/>
				</div>

				{projectType?.length > 0 &&
					<CreateNewProject 
						countries = { countries } 
						projectTypes = { projectType }
					/>
				}
			</div>
			
			<div className="director-footer">
        <Footer history={props.history} />
			</div>
			</div>
		</>
	)
}

export default CreateEditProject;