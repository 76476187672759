import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { updateUserStatus } from "../../services/userService";
import styles from "../../../src/components/shared/user-status.module.scss";


const UserStatusList = ({ userInfo, pageType }) => {
    const replaceWord = value => {
        return value.replace(/(?:_| |\b)(\w)/g, function($1){return $1.toUpperCase().replace('_',' ');});
    }

    const tempStatus = userInfo?.user_status || "Not Viewed";
    const [userStatus, setUserStatus] = useState(replaceWord(tempStatus));

    const changeUserStatus = (status, userId) => {
        setUserStatus(replaceWord(status));
        updateUserStatus(userId, status);
    }

    useEffect(()=> {
        if (pageType === "detailPage" && userInfo?.user_status === "not_viewed") {
            updateUserStatus(userInfo.id, "viewed");
            setUserStatus(replaceWord("viewed"));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={`${styles.userStatusContainer} dropdownSearchAccount`}>
            <Dropdown 
            className={`${styles.userStatusTextDropDown} ${
                userStatus === "Not Viewed" ? styles.notViewedColor :
                userStatus === "Viewed" ? styles.viewedColor :
                userStatus === "Call Back" ? styles.callBackColor :
                styles.noAnswerColor}
              `}
              >
                <Dropdown.Toggle variant="secondary" size="sm" id="dropdown-basic" >
                    { userStatus }
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item onSelect={() => changeUserStatus("not_viewed", userInfo.id)}>Not Viewed</Dropdown.Item>
                    <Dropdown.Item onSelect={() => changeUserStatus("viewed", userInfo.id)}>Viewed</Dropdown.Item>
                    <Dropdown.Item onSelect={() => changeUserStatus("call_back", userInfo.id)}>Call Back</Dropdown.Item>
                    <Dropdown.Item onSelect={() => changeUserStatus("no_answer", userInfo.id)}>No Answer</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
}

export default UserStatusList;
