import React, { useState, useEffect } from "react";
import styles from "./talents.module.scss";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProjectsByID } from "../../../redux/Projects/actions";
import Talents from "../../../components/talents-list";
import ModelFilters from "../../../components/director-sidebar/filtersSidebar";
import ProjectRoles from "../../../components/director-sidebar/projectRoles";
import PaginationSidebar from "../../../components/shared/pagination-sidebar";

const CastTalents = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const projectList = useSelector((state) => state?.Projects?.data?.projectList);	
	const localSelectedProjectId = localStorage.getItem("selectedProjectId") || null;
	const localSelectedProjectName = localStorage.getItem("selectedProjectName") || null;

	const [projectParamsData, setProjectParamsData] = useState({
		limit: 8,
		offSet: 0,
	});

	const queryParams = new URLSearchParams(location.search);
	let favouritekeyValue = queryParams.get("favourite") || false;
	if (favouritekeyValue === "1") {
		favouritekeyValue = true;
	}

	const [isFavourite, setIsFavourite] = useState(favouritekeyValue);
	const [showFilterType, setShowFilterType] = useState("modalFilter");
	const [selectedProjectId, setSelectedProjectId] = useState(localSelectedProjectId);
	const [selectedProjectName, setSelectedProjectName] = useState(localSelectedProjectName);
	const [selectedTalentId, setSelectedTalentId] = useState(0);
	const [filterData, setFilterData] = useState({});
	const [showLoader, setShowLoader] = useState(false);

	const data = projectList?.rows;
	const totalCount = projectList?.count;
	
	useEffect(() => {
		if (!selectedProjectId) {
			let userData = localStorage.getItem("user");
			userData = JSON.parse(userData);
			dispatch(getProjectsByID({
				userId: userData.id,
				offset: projectParamsData?.offSet,
				limit: projectParamsData?.limit
			}));
		}
		setShowLoader(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [projectParamsData?.offSet]);

	useEffect(() => {
		setIsFavourite(favouritekeyValue);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [favouritekeyValue]);	

	const handleTalentPagination = (type) => {
		setShowLoader(true);
		let offSet = projectParamsData.offSet - projectParamsData.limit;
		if (type === "next") {
			offSet = projectParamsData.offSet + projectParamsData.limit;
		}
		setProjectParamsData({
				...projectParamsData,
				offSet
		});
	};

	const handleSelectedCast = (talentId) => {
		setSelectedTalentId(talentId);
		setShowFilterType("projectSidebar");
		if (selectedProjectId) {
			setShowFilterType("projectRoles");
		}
	}

	const handleSelectedProjectInfo = (projectId, projectName) => {
		if (projectId) {
			setSelectedProjectId(projectId);
			setSelectedProjectName(projectName);
			setShowFilterType("projectRoles");
		}
	}
	
	return (
    <>
			<div className={styles.talentsMainContent}>
				{/* {showFilterType === "modalFilter" && */}
					<ModelFilters
						onFilterChange={(filtersData) => setFilterData(filtersData)}
						showFilterType = {showFilterType}
					/>
				{/* } */}
				
				{showFilterType === "projectSidebar" &&
					<PaginationSidebar
						data={data}
						limit={projectParamsData?.limit}
						totalCount={totalCount}
						offSet = { projectParamsData?.offSet }
						handlePagination={handleTalentPagination}
						heading={"Add Cast to Role."}
						subHeading={"Please Select A Project."}
						pageType={"talents"}
						selectedProjectInfo={handleSelectedProjectInfo}
						showLoader={showLoader}
						subTitleForCount = { "Projects" }
						setShowFilterType = { setShowFilterType }
					/>
				}

				{showFilterType === "projectRoles" &&
					<ProjectRoles 
						projectId = { selectedProjectId }
						projectName = { selectedProjectName }
						talentId = { selectedTalentId }
						setShowFilterType = { setShowFilterType }
						selectedProject = { localSelectedProjectId }
					/>
				}

				<Talents
					isFavourite={isFavourite}
					handleSelectedCast={handleSelectedCast}
					showFilterType={showFilterType}
					filterData={filterData}
				/>
			</div>
		</>
	);
}

export default CastTalents;
