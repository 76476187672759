import React from "react";
import styles from "./ImageModal.module.scss";
import Modal from "react-bootstrap/Modal";

const ImageModal = (props) => {
  return (
    <Modal
      show={props.show}
      onHide={props.hide}
      centered={true}
      dialogClassName={styles.modalMainContainer}
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <img
          src={props.src}
          alt="user"
          className={styles.image}
        />
      </Modal.Body>
    </Modal>
  );
};

export default ImageModal;
