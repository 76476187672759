import axios from "axios";
import { ApiUrls, axiosConfig } from "../redux/apiUrls";

/**
 * @param {integer} projectId
 * @returns: Error or success response
 * Comment: Get episodes and scenes based on project Id
 */
export const getEpisodeSceneByProjectId = async (projectId) => {
	try {
		const data = await axios.get(ApiUrls.GET_EPISODES_AND_SCENES_BY_PROJECT_ID + "/" + projectId, axiosConfig());
		return {
			status: true,
			data: data?.data?.data
		}
	} catch (error) {
		return {
			status: false,
			errorMsg: error?.response?.data?.msg
		}
	}
};

/**
 * @param {integer} projectId
 * @param {string} episodeSceneType
 * @param {integer} offSet
 * @param {integer} limit
 * @returns: Error or success response
 * Comment: Get episodes and scenes list based on project Id
 */
export const getEpisodeSceneListByProjectId = async (projectId, episodeSceneType, offSet, limit) => {
	try {
		const data = await axios.get(`${ApiUrls.GET_EPISODES_AND_SCENES_LIST_BY_PROJECT_ID}/${episodeSceneType}/${projectId}?offset=${offSet}&limit=${limit}`, axiosConfig());
		return {
			status: true,
			data: data?.data?.data
		}
	} catch (error) {
		return {
			status: false,
			errorMsg: error?.response?.data?.msg
		}
	}
};

/**
 * @param {object} saveData
 * @returns: Error or success response
 * Comment: Add or Update Episode details
 */
export const saveOrUpdateEpisodeAndSceneDetails = async (saveData, type) => {
	try {
		const data = await axios.post(`${ApiUrls.ADD_OR_UPDATE_EPISODES}/${type}`, saveData, axiosConfig());
		return {
			success: true,
			status: 200,
			data: data?.data?.data || {}
		}
	} catch (error) {
		return {
			success: false,
			errorMsg: error?.response?.data?.msg
		}
	}
};

/**
 * @param {integer} id
 * @returns: Error or success response
 * Comment: Delete the episodes by Id
 */
export const deleteEpisodesAndSceneById = async (id, type) => {
	try {
		const data = await axios.delete(`${ApiUrls.DELETE_EPISODES_BY_ID}/${type}/${id}`, axiosConfig());
		return {
			success: true,
			status: 200,
			data: data?.data?.data
		}
	} catch (error) {
		return {
			success: false,
			errorMsg: error?.response?.data?.msg
		}
	}
};

/**
 * @param {integer} episodeId
 * @param {integer} offSet
 * @param {integer} limit
 * @returns: Error or success response
 * Comment: Get scenes list based on episode Id
 */
export const getSceneListByEpisodeAndProjectId = async (params) => {
	try {
		const data = await axios.get(`${ApiUrls.GET_SCENES_LIST_BY_EPISODE_ID}/${params.projectId}/${params.episodeId}?offset=${params.offSet}&limit=${params.limit}`, axiosConfig());
		return {
			status: true,
			data: data?.data?.data
		}
	} catch (error) {
		return {
			status: false,
			errorMsg: error?.response?.data?.msg
		}
	}
};

/**
 * @param {object} saveData
 * @returns: Error or success response
 * Comment: Insert the project role into scene
 */
export const insertRoleIntoScene = async (saveData) => {
	try {
		const data = await axios.post(`${ApiUrls.CREATE_PROJECT_ROLE_INTO_SCENE}`, saveData, axiosConfig());
		return {
			status: true,
			data: data?.data?.data
		}
	} catch (error) {
		return {
			status: false,
			errorMsg: error?.response?.data?.msg
		}
	}
};

/**
 * @param {integer} projectId
 * @param {integer} episodeId
 * @returns: Error or success response
 * Comment: Get project and episode detail by Id
 */
export const getProjectEpisodeDetails = async (projectId, episodeId) => {
	try {
		const data = await axios.get(`${ApiUrls.GET_PROJECT_EPISODE_DETAIL_BY_ID}/${projectId}/${episodeId}`, axiosConfig());
		return {
			status: true,
			data: data?.data?.data
		}
	} catch (error) {
		return {
			status: false,
			errorMsg: error?.response?.data?.msg
		}
	}
};

/**
 * @param {integer} sceneId
 * @param {integer} roldId
 * @returns: Error or success response
 * Comment: Delete the project role from specific scene by Id
 */
export const deleteProjectRoleFromSceneById = async (sceneId, roleId) => {
	try {
		const data = await axios.delete(`${ApiUrls.DELETE_PROJECT_ROLE_FROM_SCENE_BY_ID}/${sceneId}/${roleId}`, axiosConfig());
		return {
			status: true,
			data: data?.data?.data
		}
	} catch (error) {
		return {
			status: false,
			errorMsg: error?.response?.data?.msg
		}
	}
};