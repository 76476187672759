import axios from 'axios'

import { ApiUrls, axiosConfig } from '../apiUrls';

export const loginApi = (Credentials, loginType) => {
    return axios.post(ApiUrls.LOGIN + '/' + loginType , {
        ...Credentials
    },
        axiosConfig()
    );
}