import React, { useEffect, useState } from "react";
import styles from "./talents.module.scss";
import ModelOne from "../../assets/icons/ModelOne.png";
import Footer from "../../components/shared/footer/index";
import { ReactComponent as MainimgWhiteYell } from "../../assets/icons/mainimgWhiteYell.svg";
import { ReactComponent as LeftHand } from "../../assets/icons/LeftHand.svg";
import { ReactComponent as RightHand } from "../../assets/icons/RightHand.svg";
import { getFavoriteStatus } from "../../services/talentsService";
import { createProjectRoleOptions } from "../../services/projectRole";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getTalentsListing } from "../../redux/Talents/actions";
import Spinner from "react-bootstrap/Spinner";

const Talents = (props) => {
	const [talents, setTalents] = useState([]);
	const [talentsCount, setTalentsCount] = useState(0);
	const [selectedTalent, setSelectedTalent] = useState(0);
  const [loader, setLoader] = useState(false);
  const location = useLocation();
	const limit = props?.paramsData?.limit;
  const history = useHistory();
	const dispatch = useDispatch();
	const talentState = useSelector((state) => state?.Talent?.data);
	const screenWidth = window.innerWidth;

  const queryParams = new URLSearchParams(location.search);
  let addCast = queryParams.get("addCast") || "";
  let roleId = queryParams.get("roleId") || "";
  let projectId = queryParams.get("projectId") || "";
  let talentId = queryParams.get("talentId") || "";
	let pageType = queryParams.get("pageType") || "";
	let activeSceneIndex = queryParams.get("activeSceneIndex") || "";
	let offSet = queryParams.get("offSet") || "";
  
	const [paramsData, setParamsData] = useState({
		limit: screenWidth > 1600 ? 12 : 10,
		offSet: 0,
		filters: {}
	});

	useEffect(() => {
		const offSetValue = 0;
		setParamsData({
			...paramsData,
			offSet: offSetValue
		});
		getTalentsList(offSetValue); // show by default data from first page
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props?.isFavourite, props?.filterData, talentId]);

	/**
	 * 
	 * @param {Integer} offSetValue
	 * @return void
	 * Comment: Get list of talents based on different filter changes 
	 */
	const getTalentsList = (offSetValue) => {
		setLoader(true);
		const params = getParams(props?.filterData, offSetValue);
		dispatch(getTalentsListing(props?.isFavourite, params, talentId ? talentId : 0));
	};

	useEffect(() => {
		setTalents(talentState?.talentList);
		setTalentsCount(talentState?.talentCount);
    if (loader) {
      setLoader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [talentState]);

	const getParams = (filterParams, offSetValue) => {
		let params = {
			limit: paramsData.limit,
			offset: offSetValue,
		};

		for (let key in filterParams) {
			params[key] = filterParams[key];
		}
		return params;
	};

	const showingFrom = paramsData?.offSet + 1;
	const showingTo = showingFrom + talents?.length - 1;

	const markOrUnmarkTalentAsFavorite = async(id, favouriteStatus) => {
		try {
			const result = await getFavoriteStatus(id, favouriteStatus);
			if (result.status) {
				if (props?.isFavourite) {
					setTalents(talents.filter((item) => item.id !== id));
					toast.success("Talent has been removed from favorite");
				} else {
					setTalents(talents.map((item) => item.id === id ? {...item , favourite: favouriteStatus} : item));
					toast.success("Talent has been marked as favorite");
				}
			}
		} catch (error) {
			toast.error("Some error occurred");
		}
	}

  const saveProjectRoleOption = async (roleId, talentId) => {
		try {
			const result = await createProjectRoleOptions(roleId, talentId);
			if (!result.status) {
				toast.error(result.errorMsg);
			} else {
				toast.success("Project role has been assigned to talent.");
				if (pageType) {
					history.push(`/director/scenes-detail/${projectId}?activeSceneIndex=${activeSceneIndex}&offSet=${offSet}`);
				} else {
					history.push(`/director/project-roles-breakdown/${projectId}?roleIndex=${addCast}&offSet=${offSet}`);
				}
			}
		} catch (error) {
			toast.error("Some issue occurred while assigning roles");
		}
	};

	const openInNewTab = (talentId, queryString) => {
    window.open(`/director/talent-profile/${talentId}${queryString}`, "_blank", "noreferrer");
  };

	const showTalentDetail = (item, index) => {
		let imgSrc = ModelOne;
		if (item?.title && item?.title !== "") {
			imgSrc = `${process.env.REACT_APP_YOUCAST_USER_IMAGES_PATH}/${process.env.REACT_APP_MODE}/${item?.id}/account/thumbnail/th${item?.title}`;
		}
				
		let queryString = '';
		if (roleId && projectId) {
			queryString = `?roleId=${roleId}&projectId=${projectId}`;
		}
		return (
			<div className={selectedTalent === item.id ? styles.activeTalent : styles.profilebox} key={index} onClick={(e) => {
				openInNewTab(item.id, queryString)
      }} >
				<img src={imgSrc} alt="ModelPic" />
				<h4 className={styles.modelprofileName}>{item.name}</h4>
        <p className={styles.modelDetail}>{item?.category_name}</p>
				<div className={styles.ancherbtnsbox}>
					<div className={styles.ancherbtns} onClick={(e) => {
            e.stopPropagation();
            if (addCast || pageType === "scenesDetail") {
              saveProjectRoleOption(roleId, item.id);
            } else {
              props.handleSelectedCast(item?.id); 
						  setSelectedTalent(item?.id);
            };
					}}>
						Cast
					</div>
					<div 
						to="" 
						className={item?.favourite === 1 ? styles.removefavourite : styles.ancherbtns}
						onClick={(e) => {
              e.stopPropagation();
              markOrUnmarkTalentAsFavorite(item?.id, item?.favourite === 1 ? 0 : 1);
            }}
					>
						{item?.favourite === 1 ? "Remove Favorite" : "Favorite"}
					</div>
				</div>
			</div>
		);
	}

	const handleTalentPagination = (type) => {
		let offSet = paramsData.offSet - paramsData.limit;
		if (type === "next") {
			offSet = paramsData.offSet + paramsData.limit;
		}

		setParamsData({
			...paramsData,
			offSet
		});
		
		getTalentsList(offSet);
	};

	return (
		<>
			<div className={styles.ModelsProfile}>
				{talents?.length === 0 && !loader &&
					<div className={styles.noDatabox}>
						<div className={styles.noDataMsg}>
							<MainimgWhiteYell className={`mainWhiteCricleSvg ${styles.Noroleimg}`}/>
							<p className={styles.noRoleText}>No Talent Found</p>
						</div>
					</div>
				}
				{!loader ? <div className={styles.profilemainboxes}>
					{talents?.map((item, index) => (
						showTalentDetail(item, index)
					))}
				</div> : <div className={styles.loaderInSidebar}>
							<Spinner animation="border" variant="primary" className={styles.InnerLoader} />
						</div>}
			</div>

			<div className={styles.leftRightHands}>
				<div className={showingFrom === 1 ? styles.leftDisable : styles.leftHandArrow} onClick={() => handleTalentPagination("prev")}>
					<LeftHand className={styles.lefthanfSvg} />
					<p className={styles.lefthandtext}>Previous Talents</p>
				</div>

				<Footer history={props.history} />

				<div className={(talents?.length < limit || showingTo >= talentsCount) ? styles.rightDisable : styles.rightHandarrow} onClick={() => handleTalentPagination("next")}>
					<p className={styles.Righthandtext}>More Talents</p>
					<RightHand className={styles.lefthanfSvg} />
				</div>
			</div>
		</>
	)
}

export default Talents;