import React, { useState, useEffect } from "react";
import "./filters.scss";
import Form from "react-bootstrap/Form";
import Darkbrowneye from "../../assets/images/darkbrown-eye.png";
import filterIcon from "../../assets/images/refresh-icon.png";
import Slider from '@material-ui/core/Slider';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles({
  root: {
    width: 300,
  },
});
const Filters = (props) => {
  const [filters, setFilters] = useState();
  const [selectedFilters, selectFilter] = useState();
  const classes = useStyles();
  const location = useLocation();
  const [catagoryId, setCatagoriesId] = useState(null)
  const [selectedCatagoryName, setSelectedCatagoryName] = useState("")

  useEffect(() => {
    if (props.filterParams.list.loaded) {
      setFilters(props.filterParams.list.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.filterParams?.list]);

  var setFiltersHandler = (type, value, title) => {
    let filterState = { ...selectedFilters };

    if (type === 'height' || type === 'weight' || type === "age") {
      filterState[type] = value;
    } else {
      filterState[type] = {id:value, title};
    }

    selectFilter(filterState);
    let filterEmitData = {};
    for (let key in filterState) {
      filterEmitData[key] =
        key === "age" ? filterState[key] : filterState[key].id;
    }
    props.onFilterChange(filterEmitData);
  };

  var setFiltersSelectHandler = (event, type) => {
    // if(!event.target.value){ return }
    setFiltersHandler(type, { id: Number(event.target.value) });
  };

  const subCatagoriesHandle = (id) => {
    setCatagoriesId(id);
  };

  var resetFilter = () => {
    selectFilter(undefined);
    props.onFilterChange(undefined);
  };

  return (
    <div className="filterContainer">
      <div className="filterRow">
        {location.pathname.indexOf('/dashboard') > -1 &&
          <ul>
            <li className={props.preferences === 'top' ? 'selected' : ''} onClick={() => props.preferenceCHange('top')}>Top</li>
            <li className={props.preferences === 'favourites' ? 'selected' : ''} onClick={() => props.preferenceCHange('favourites')}>Favourites</li>
          </ul>
        }

        <div className="refreshBtn">
          <span>Smart Filter</span>
          <button onClick={() => resetFilter()} className="btn-ref">
            <img src={filterIcon} alt="filter icon" /> Refresh
          </button>
        </div>
      </div>
      <div className="usersFiltertab">
        <ul>
          {filters &&
            filters.categories.map((ele, i) => {
              return (
                ele.parent_category_id === null && <li
                  className={
                    selectedFilters?.category?.id === ele.id
                      ? "selectedCategory"
                      : ""
                  }
                  value={ele}
                  onClick={() => {
                    setFiltersHandler("category", ele.id)
                    subCatagoriesHandle(ele.id)
                    setSelectedCatagoryName(ele.name)
                  }}
                  key={i}
                >
                  {ele.name}
                </li>
              );
            })}
        </ul>
      </div>
      {catagoryId !== null && <div className="usersFiltertab">
        <ul>
          {filters.categories.map((category) => category.parent_category_id).includes(catagoryId) && <span className="subCatagoryHeading" >{selectedCatagoryName} Type : </span>}
          {filters &&
            filters.categories.map((ele, i) => {
              return (
                ele.parent_category_id === catagoryId && <li
                  className={
                    selectedFilters?.subCategory?.id === ele.id
                      ? "selectedCategory"
                      : ""
                  }
                  value={ele}
                  onClick={() => setFiltersHandler("subCategory", ele.id)}
                  key={i}
                >
                  {ele.name}
                </li>
              );
            })}
        </ul>
      </div>}
      <div className="filterSelection">
        {filters && (
          <>
            <Gender
              data={filters.gender}
              selectedFilters={selectedFilters}
              setFiltersHandler={setFiltersHandler}
              type="gender"
            />
            <div className={classes.root}>
              <AgeSlider
                setFiltersHandler={setFiltersHandler}
                type="age"
                selectedFilters={selectedFilters}
              />
            </div>
            <SkinTone
              setFiltersHandler={setFiltersHandler}
              type="skintone"
              selectedFilters={selectedFilters}
              data={filters.skinTone}
            />
            <Height
              data={filters.height}
              setFiltersHandler={setFiltersSelectHandler}
              type="height"
              selectedFilters={selectedFilters}
            />
            <Weight
              data={filters.weight}
              setFiltersHandler={setFiltersSelectHandler}
              type="weight"
              selectedFilters={selectedFilters}
            />
            <HairType
              setFiltersHandler={setFiltersHandler}
              type="hairtype"
              selectedFilters={selectedFilters}
              data={filters.hair_type}
            />
             <SocialStatus
              setFiltersHandler={setFiltersHandler}
              type="SocialStatus"
              selectedFilters={selectedFilters}
              data={filters.social_status}
            />
            <SpecialTalents
              setFiltersHandler={setFiltersHandler}
              type="SpecialTalents"
              selectedFilters={selectedFilters}
              data={filters.special_talents}
            />
            <HairColor
              setFiltersHandler={setFiltersHandler}
              type="haircolor"
              selectedFilters={selectedFilters}
              data={filters.hair_color}
            />
            <EyeColor
              setFiltersHandler={setFiltersHandler}
              type="eyecolor"
              selectedFilters={selectedFilters}
              data={filters.eye_color}
            />
            <BodyShape
              setFiltersHandler={setFiltersHandler}
              type="bodyshape"
              selectedFilters={selectedFilters}
              data={filters.body_shape}
            />
          </>
        )}
      </div>
    </div>
  );
};

const Gender = ({ selectedFilters, data, type, setFiltersHandler }) => {
  return (
    <div className="filterItems genderCol">
      <div className="genderData itemData">
        {data.map((ele, i) => {
          return (
            <span
              className={
                selectedFilters?.[type]?.id === ele.id ? "selectedCategory" : ""
              }
              onClick={() => setFiltersHandler(type, ele.id)}
              key={i}
            >
              {ele.title}
            </span>
          );
        })}
      </div>
    </div>
  );
};

const AgeSlider = ({ selectedFilters, data, type, setFiltersHandler }) => {
  const [value, setValue] = React.useState([20, 60]);
  const [sliderValue, setSliderValue] = useState(false);

  const handleChange = (event, newValue) => {
    setSliderValue(true);
    setValue(newValue);
  };

  useEffect(() => {
    if (sliderValue) {
      const callApi = setTimeout(() => {
        setFiltersHandler(type, value)
      }, 700);
      return () => clearTimeout(callApi);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  function valuetext(value) {
    return value;
  }

  return (
    <div className="filterItems ageCol">
      <div className="itemData ageData">
        <span>Age</span>
        <span style={{ width: '125PX' }}>
          <Slider
            value={value}
            onChange={handleChange}
            valueLabelDisplay="auto"
            aria-labelledby="range-slider"
            getAriaValueText={valuetext}
            type="range"
          />
        </span>
      </div>
    </div>
  );
};

const SkinTone = ({ selectedFilters, data, type, setFiltersHandler }) => {
  return (
    <div className="filterItems skinCol">
      <div className="itemData skinData">
        <span>Skin tone</span>
        <span className="selectedTonetext">
          {selectedFilters && selectedFilters[type]
            ? selectedFilters[type].title
            : ""}
        </span>
        <ul className="chooseTone">
          {data.map((ele) => (
            <li
              key={ele.id}
              className={`${ele.color} ${(selectedFilters &&
                  selectedFilters[type] ) &&
                  selectedFilters[type].id === ele.id
                  ? "selectedTone"
                  : ""
                }`}
              onClick={() => setFiltersHandler(type, ele.id, ele.title)}
            >
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const Height = ({ selectedFilters, data, type, setFiltersHandler }) => {
  let selectedId =
    selectedFilters && selectedFilters[type] ? selectedFilters[type].id : '';

  return (
    <div className="filterItems heightCol">
      <div className="itemData heightData">
        <span>Height</span>
        <Form.Control
          className="filterSelect"
          size="sm"
          as="select"
          value={selectedId}
          onChange={(e) => setFiltersHandler(e, type)}
        >
          <option value={null}>Select</option>
          {data.map((ele, i) => {
            return (
              <option value={ele.id} key={i}>
                {ele.title}
              </option>
            );
          })}
        </Form.Control>
      </div>
    </div>
  );
};
const Weight = ({ selectedFilters, data, type, setFiltersHandler }) => {
  let selectedId =
    selectedFilters && selectedFilters[type] ? selectedFilters[type].id : '';

  return (
    <div className="filterItems weightCol">
      <div className="itemData  weightData">
        <span>Weight</span>
        <Form.Control
          className="filterSelect"
          size="sm"
          as="select"
          value={selectedId}
          onChange={(e) => setFiltersHandler(e, type)}
        >
          <option value={null}>Select</option>
          {data.map((ele, i) => {
            return (
              <option value={ele.id} key={i}>
                {ele.title}
              </option>
            );
          })}
        </Form.Control>
      </div>
    </div>
  );
};

const HairType = ({ selectedFilters, data, type, setFiltersHandler }) => {
  return (
    <div className="filterItems  hairtypeCol">
      <div className="itemData hairtypeData">
        <span>Hair type</span>
        <ul className="hairType">
          {data.map((ele, i) => {
            return (
              <li
                className={
                  selectedFilters &&
                    selectedFilters[type] &&
                    selectedFilters[type].id === ele.id
                    ? "selectedHiarType"
                    : ""
                }
                value={ele}
                onClick={() => setFiltersHandler(type, ele.id)}
                key={i}
              >
                {ele.title}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
const HairColor = ({ selectedFilters, data, type, setFiltersHandler }) => {
  return (
    <div className="filterItems haircolorCol">
      <div className="itemData haircolorData">
        <span>Hair color</span>
        <span className="selectedHaircolor">
          {selectedFilters && selectedFilters[type]
            ? selectedFilters[type].title
            : ""}
        </span>
        <ul className="hairColor">
          {data.map((ele) => (
            <li
              key={ele.id}
              className={`${ele.color}
                ${selectedFilters &&
                  selectedFilters[type] &&
                  selectedFilters[type].id === ele.id
                  ? "selectedHairColor"
                  : ""
              }`}
              onClick={() => setFiltersHandler(type, ele.id, ele.title)}
            >
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
const EyeColor = ({ selectedFilters, data, type, setFiltersHandler }) => {
  return (
    <div className="filterItems eyecolorCol">
      <div className="itemData eyecolorData">
        <span>Eye color</span>
        <span className="selectedEyecolor">
          {selectedFilters && selectedFilters[type]
            ? selectedFilters[type].title
            : ""}
        </span>
        <ul className="eyeColor">
          {data.map((ele) => (
            <li
              key={ele.id}
              className={`${ele.color}
                ${selectedFilters &&
                  selectedFilters[type] &&
                  selectedFilters[type].id === ele.id
                  ? "selectedEyeColor"
                  : ""
              }`}
              onClick={() => setFiltersHandler(type, ele.id, ele.title)}
            >
              <img src={Darkbrowneye} alt="Darkbrowneye" />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
const BodyShape = ({ selectedFilters, data, type, setFiltersHandler }) => {
  return (
    <div className="filterItems bodyshapeCol">
      <div className="itemData ">
        <span>Body Shape</span>
        <ul className="bodyShape">
          {/* for selectecd tone plz add daynamicly class (selectedBodyShape) */}
          {/* <li>Slim</li>
                    <li className="selectedBodyShape">Avarege</li>
                    <li>Athletic</li>
                    <li>Heavy</li> */}
          {data.map((ele, i) => {
            return (
              <li
                className={
                  selectedFilters &&
                    selectedFilters[type] &&
                    selectedFilters[type].id === ele.id
                    ? "selectedBodyShape"
                    : ""
                }
                value={ele}
                onClick={() => setFiltersHandler(type, ele.id)}
                key={i}
              >
                {ele.title}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

const SocialStatus = ({ selectedFilters, data, type, setFiltersHandler }) => {
  return (
    <div className="filterItems  socialStatusCol">
      <div className="itemData hairtypeData">
        <span>Social Status</span>
        <ul className="socialStatus">
          {data.map((ele, i) => {
            return (
              <li
                className={
                  selectedFilters &&
                    selectedFilters[type] &&
                    selectedFilters[type].id === ele.id
                    ? "selectedSocialStatusType"
                    : ""
                }
                value={ele}
                onClick={() => setFiltersHandler(type, ele.id)}
                key={i}
              >
                {ele.name}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  )
}

const SpecialTalents = ({ selectedFilters, data, type, setFiltersHandler }) => {
  return (
    <div className="filterItems  specialTalentCol">
      <div className="itemData hairtypeData">
        <span className="specialTalentsHeading">Special Talents</span>
        <ul className="specialTalents">
          {data.map((ele, i) => {
            return (
              <li
                className={
                  selectedFilters &&
                    selectedFilters[type] &&
                    selectedFilters[type].id === ele.id
                    ? "selectedSpecialTalentsType"
                    : ""
                }
                value={ele}
                onClick={() => setFiltersHandler(type, ele.id)}
                key={i}
              >
                {ele.name}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  )
}

Filters.propTypes = {};

export default Filters;
