import React, { useState, useEffect } from "react";
import styles from "./sceneDetailSidebar.module.scss";
import { ReactComponent as Sidelogo } from "../../../assets/icons/sidelogo.svg";
import { ReactComponent as Listicon } from "../../../assets/icons/listicon.svg";
import { ReactComponent as DoubleUpArrow } from "../../../assets/icons/doubleUpArrow.svg";
import { ReactComponent as DoubleDownAroow } from "../../../assets/icons/doubleDownAroow.svg";
import { ReactComponent as NoDataImgCircle } from "../../../assets/icons/mainimgWhiteYell.svg";
import { ReactComponent as PlusIcon } from "../../../assets/icons/PlusIcon.svg";
import { ReactComponent as CastLogo } from "../../../assets/icons/circleArc.svg";
import CancelArrow from "../../../assets/icons/cancelArrow.png";
import RevCancelArrow from "../../../assets/icons/revCancelArrow.png";
import { getSceneLisingtByEpisodeAndProjectID, setSidebarPageViewType } from "../../../redux/Scenes/actions";
import { saveOrUpdateEpisodeAndSceneDetails, insertRoleIntoScene } from "../../../services/episodeSceneService";
import { getProjectRolesBasedOnProjectId } from "../../../services/projectRole";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import { defaultLimit } from "../../../constant";
import { getActiveRoleIndex, calculateOffsetByLimitAndCount } from "../../../helpers/commonHelper";

const ScenesDetailSidebar = (props) => {
	const dispatch = useDispatch();
	const location = useParams();
	const episodeId = location?.episodeId;
	const projectId = location?.projectId;
	const selectedSceneIndex = props?.activeSceneindex;
	const episodeName = props?.projectEpisodeDetails?.episodes;
	const scenesList = useSelector((state) => state?.Scenes?.data);
	const sidebarPageViewType = useSelector((state) => state?.Scenes?.data?.sidebarPageViewType);
	const showListing = sidebarPageViewType === "created" || sidebarPageViewType === "updated" || sidebarPageViewType === "insert" ? false : true;
	const [showError, setShowError] = useState(false);
	const [projectRole, setProjectRole] = useState([]);
	const [selectedProjectRole, setSelectedProjectRole] = useState();
	const [params, setParams] = useState({
		projectId: projectId,
		episodeId: episodeId !== undefined ? episodeId : 0,
		limit: window.innerWidth > 1600 ? defaultLimit : 6,
		offSet: props?.offsetPage ? props?.offsetPage : props?.offSet,
	});

	useEffect(() => {
		setParams({
			...params,
			offSet: props?.offSet
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props?.offSet])

	const [createScene, setCreateScene] = useState({
		projectId: projectId,
    episodeId: episodeId !== undefined ? episodeId : 0,
		pId: "",
		name: "",
	});
	const [hitRequest, setHitRequest] = useState(false);

	const data = scenesList?.scenesList;
	const totalCount = scenesList?.scenesCount;
	const showingFrom = parseInt(params?.offSet) + 1;
	const showingTo = showingFrom + data?.length - 1;
	const projectSceneRoleData = data[selectedSceneIndex]?.projectSceneRoles;
	const existingProjectRole = projectSceneRoleData?.map((item) => (
		item?.role_id
	));
	
	const insertRoleIntoScenes = {
		projectId: projectId,
    episodeId: episodeId !== undefined ? episodeId : 0,
		sceneId: data[selectedSceneIndex]?.id,
		roleId: selectedProjectRole?.id || projectRole?.rows?.[0]?.id
	};
	
	useEffect(() => {
		dispatch(getSceneLisingtByEpisodeAndProjectID(params));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params?.offSet, hitRequest]);

	useEffect(() => {
		if (sidebarPageViewType === "insert") {
			getProjectRoles();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sidebarPageViewType]);

	useEffect(() => {
		setCreateScene({
			...createScene, 
			pId: sidebarPageViewType === "updated" ? props?.sceneData?.sceneId : "",
			name: sidebarPageViewType === "updated" ? props?.sceneData?.sceneName : "",
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props?.sceneData]);

	const toggleSidebar = () => {
		props.isOpenSidebar === false ? props.setIsOpenSidebar(true) : props.setIsOpenSidebar(false);
	};
	
	const handleScenesPagination = (type) => {
		props.setActiveSceneIndex(0);
		let offSet = params.offSet - params.limit;
		if (type === "next") {
			offSet = params.offSet + params.limit;
		}
		setParams({
			...params,
			offSet
		});
	};

	useEffect(() => {
	  let offset = params.offSet;
		props.setOffSet(offset);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params.offSet]);

	const getProjectRoles = async () => {
		const result = await getProjectRolesBasedOnProjectId({
			projectId: projectId,
			offSet: 0,
			limit: 100,
			existingProjectRole: existingProjectRole
		});
		if (result.status) {
			setProjectRole(result.data);
		}
	};

	const handleRoleChange = (e) => {
		setSelectedProjectRole(JSON.parse(e.target.value));
	};

	const saveOrUpdateScenes = async () => {
		try {
			if (createScene.name.trim() === "") {
				setShowError(true);
				return false;
			}
			const result = await saveOrUpdateEpisodeAndSceneDetails(createScene, "scenes");
			if (result.status) {
				toast.success("Scene has been saved successfully");
				if (props?.createdSceneType === "update") {
					dispatch(getSceneLisingtByEpisodeAndProjectID(params));
				} else if (props?.createdSceneType === "create") {
					const newCount = parseInt(totalCount + 1);
					const getNewOffset = calculateOffsetByLimitAndCount(newCount, parseInt(params?.limit));
					setParams({ ...params, offSet: getNewOffset });
					params?.offSet === getNewOffset && setHitRequest((prev) => !prev);
					props.setActiveSceneIndex(getActiveRoleIndex(totalCount, props?.params?.limit));
				}
				dispatch(setSidebarPageViewType("view"));
			} else {
				toast.error(result.errorMsg);
			}
		} catch (error) {
			toast.error(`Some issue occurred while ${sidebarPageViewType} roles`);
		}
	};

	const saveProjectRoleIntoScene = async () => {
		try {
			const result = await insertRoleIntoScene(insertRoleIntoScenes);
			if (result?.status) {
				toast.success("Role has been inserted successfully");
				dispatch(getSceneLisingtByEpisodeAndProjectID(params));
				if (projectRole?.rows?.length === 1) {
					dispatch(setSidebarPageViewType("view"));
				}
			} else {
				toast.error(result?.errorMsg);
			}
		} catch (error) {
			toast.error(`Some issue occurred while inserting roles`);
		}
	};

	return (
		<>
			<div className={props.isOpenSidebar === false ? styles.toggleSidebar : styles.sideBigMenu}>
				<div>
					<div className={styles.sideImglogo}>
						<div className={styles.sideImgbox}>
							<Sidelogo className={styles.sideimgsvgicon} />
							<p className={styles.sideimgsvgtxt}>Youcast</p>
						</div>
						<div className={styles.sideBarlogoTxt}>
							<h4 className={styles.sidelgtxt}>{props?.projectEpisodeDetails?.project_name}</h4>
							{episodeId !== undefined &&
								<p className={styles.sidelgsubtxt}>Eps 1 : {episodeName ? episodeName[0]?.episode_name : ''}</p>
							}
						</div>
					</div>

					{showListing &&
						<div className={styles.collapseExpandBtn} onClick={toggleSidebar}>
							{props.isOpenSidebar === false ?
								<img src={RevCancelArrow} alt="revCancelArrow" /> :
								<img src={CancelArrow} alt="cancelArrow" />
							}
						</div>
					}
					<p className={styles.silelogosubText}>Total {totalCount} Scenes</p>

					{sidebarPageViewType !== "insert" &&
						(showListing ?
							<ul className={styles.ulListsb}>
								{data?.map((item, index) => (
									<li
										key={index}
										className={selectedSceneIndex === index ? styles.selectedList : styles.lilistsb}
										onClick={() => props?.setActiveSceneIndex(index)}
									>
										<Listicon className={styles.listiconsb} /> <span className={styles.listIconName}>{item?.scene_name}</span>
									</li>
								))}
							</ul> :
							<>
								{sidebarPageViewType === "updated" ? 
									<h6 className={styles.createSceneHeading}>Update This Scene</h6> :
									<h6 className={styles.createSceneHeading}>Create a New Scene</h6>
								}
								<Form.Control
									type={"text"}
									placeholder={"Name Your Scene"}
									value={createScene.name}
									onChange={(event) => setCreateScene({...createScene, name: event.target.value})}
									className={!showError ? styles.createSceneInput : styles.showErrorInput}
									required
								/>
								{showError && 
									<p className={styles.showError}> Please enter Scene name </p>
								}
								<div className={styles.createSceneBtn} onClick={saveOrUpdateScenes}>
									{sidebarPageViewType === "updated" ? "Update" : "Create"}
								</div> 
								<div className={styles.createSceneBtn} onClick={() => dispatch(setSidebarPageViewType("view"))}>
									Back
								</div>
							</>
						)
					}

					{sidebarPageViewType === "insert" &&
						<div className={styles.insertSceneSidebar}>
							{/* <h6 className={styles.insertSceneHeading}>Create a New Scene</h6>
							<p className={styles.insertSubHeading}>Nemra Etnen <span className={styles.insertSubHeading}>Eps 1</span></p> */}
							{/* <div className={styles.sceneNameHeading}>
								<p className={styles.sceneName}>Scene Name</p>
								<p className={styles.sceneName}>Optional</p>
							</div> */}
							{/* <div className={styles.insertSceneInput}>{data[selectedSceneIndex]?.scene_name}</div> */}
							<div className={styles.logolinetext}>
								<CastLogo className={styles.casticonsvg} />
								<p className={styles.casticonsvgText}>Insert Role to Scene</p>
							</div>
							<div className={styles.roleBoxMain}>
								<div className={styles.roleBoxSet}>
									{projectRole?.rows?.length > 0 &&
										<>
											<CastLogo className={styles.rolesImg} />
											<p className={styles.roleLabelOne}>{selectedProjectRole?.role_name || projectRole?.rows?.[0]?.role_name}</p>
											<p className={styles.roleLabelTwo}>Inserted</p>
										</>
									}
								</div>
							</div>
							<Form.Control
								as="select"
								onChange={handleRoleChange}
								className={styles.projectRoleDropdown}
							>
								{projectRole?.rows?.length === 0 &&
									<option>Select</option>
								}
								{projectRole?.rows?.map((item, index) => (
									<option value={JSON.stringify(item)} key={index}>
										{item?.roleOptions?.length} -	{item?.role_name}
									</option>
								))}
							</Form.Control>
							<div className={styles.insertBtn}>
								<ul className={styles.arrowcontainer}>
									<li className={`${styles.lastarrowbox} ${styles.arrowbox}`} onClick={saveProjectRoleIntoScene}><span>Insert</span></li>
									<li
										className={`${styles.createBtn} ${styles.arrowbox}`}
										onClick={() => dispatch(setSidebarPageViewType("created"))}
									>
										<PlusIcon className={styles.dblarrowSvg} /><span>Create</span>
									</li>
									<li className={`${styles.arrowbox}`} onClick={() => dispatch(setSidebarPageViewType("view"))}><span>Back</span></li>
								</ul>
							</div>
						</div>
					}
				</div>

				{props?.data?.length === 0 &&
					<div className={styles.noDataMsg}>
						<NoDataImgCircle className={styles.Noroleimg} />
						<p className={styles.noRoleText}>No Scene Found</p>
					</div>
				}

				{sidebarPageViewType === "view" &&
					<div>
						<p className={styles.silebotsubText}>Showing {showingFrom} to {showingTo > 0 ? showingTo : ''} Scenes</p>
						<ul className={styles.arrowcontainer}>
							<li className={(data?.length < props?.limit || showingTo === totalCount) ? styles.disable : styles.arrowbox} onClick={() => handleScenesPagination("next")}>
								<DoubleDownAroow className={styles.dblarrowSvg} /><span>Move Down</span>
							</li>
							<li className={showingFrom === 1 ? styles.disable : styles.arrowbox} onClick={() => handleScenesPagination("prev")}>
								<DoubleUpArrow className={styles.dblarrowSvg} /><span>Move Up</span>
							</li>
							<li 
								className={`${styles.lastarrowbox} ${styles.arrowbox}`} 
								onClick={() => {
									dispatch(setSidebarPageViewType("created"));
									props.setIsOpenSidebar(true);
									props.setCreatedSceneType("create")
								}}
							>
								<PlusIcon className={styles.dblarrowSvg} /><span>Create New Scene</span>
							</li>
						</ul>
					</div>
				}
			</div>
		</>
	);
}

export default ScenesDetailSidebar;