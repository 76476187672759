import React, { useEffect, useState } from "react";
import "./physicalDetail.color.scss";
import styles from "./physicalDetail.module.scss";
import Darkbrowneye from "../../../../assets/images/darkbrown-eye.png";
import Form from 'react-bootstrap/Form';
import DummUser from '../../../../assets/images/user-dummy.png';
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';
import { getFiltersList } from "../../../../redux/Filters/actions";
import { setUserInfo } from "../../../../redux/Users/actions";
import { saveOrUpdateUserDetails } from "../../../../services/userService";
import { ReactComponent as EditIcon } from '../../../../icons/editIcon.svg';
import { ReactComponent as CancelButton } from '../../../../assets/icons/cancelButton.svg';
import { ReactComponent as SaveButton } from '../../../../assets/icons/saveDetailButton.svg';
import { nationalityData } from "../../../../constant/nationality-json";

const PhysicalDetail = ({ accountDetail }) => {
    const physicalDetail = accountDetail?.yc_models_model;
		const [categoriesId, setCategoriesId] = useState([]);
		const [subCategoriesId, setSubCategoriesId] = useState([]);
		const [categoriesList, setCategoriesList] = useState([]);
		const [subCategoriesList, setSubCategoriesList] = useState([]);
		const [defaultCategoriesIds, setDefaultCategoriesIds] = useState([]);
		const [specialTalentIds, setSpecialTalentIds] = useState([]);
    const userId = accountDetail?.id;
    const dispatch = useDispatch();
    const filters =  useSelector((state) => state?.Filter?.list?.data);
    const [pageViewType, setPageViewType] = useState("view");

    const [physicalData, setPhysicalData] = useState({
			id: physicalDetail?.id ? physicalDetail?.id : 0,
			user_id: userId,
			facebook: physicalDetail?.sm_facebook,
			instagram: physicalDetail?.sm_instagram,
			price: physicalDetail?.price,
			language: physicalDetail?.languages,
			tattoos: physicalDetail?.tattoos,
			scars: physicalDetail?.scars,
			nationality: physicalDetail?.nationality_country_iso_code,
			gender: physicalDetail?.gender_id,
			skinTone: physicalDetail?.skin_tone_id,
			hairType: physicalDetail?.hair_type_id,
			hairColor: physicalDetail?.hair_color_id,
			eyeColor: physicalDetail?.eye_color_id,
			height: physicalDetail?.height_id,
			bodyShape: physicalDetail?.body_shape_id,
			weight: physicalDetail?.weight_id,
			socialStatus: physicalDetail?.social_status_id,
			specialTalent: "",
			modelCatagories: []
    });

    const handleUpdatePhysicalData = (event) => {
			if (pageViewType === "edit") {
				let newValue = event.target.value;

				if ((event.target.name === "height" || event.target.name === "weight" || event.target.name === "nationality") && newValue === "Select") {
					newValue = null;
				}
				setPhysicalData({
					...physicalData,
					[event.target.name]: newValue,
				});
			}
    };

		const handleUpdatePhysiqueData = (value, name) => {
			if (pageViewType === "edit") {
				setPhysicalData({
					...physicalData,
					[name]: value,
				});
			}
		};

		const getCategories = async () => {
			let categoryId = [];
			let subCategoryId = [];
			for (let i = 0; i < accountDetail?.modelCategories?.length; i++) {
				if (defaultCategoriesIds.includes(accountDetail?.modelCategories[i]?.model_category_id)) {
					categoryId.push(accountDetail?.modelCategories[i]?.model_category_id);
				} else {
					subCategoryId.push(accountDetail?.modelCategories[i]?.model_category_id);
				}				
			}
			setCategoriesId(categoryId);
			setSubCategoriesId(subCategoryId);
			if (physicalDetail?.special_talent_id) {
				setSpecialTalentIds(physicalDetail?.special_talent_id?.split(","));
			}
		};

		const handleCatagoriesData = (value, type) => {
			if (pageViewType === "edit") {
				if (type === "categories") {
					if (!categoriesId.includes(value)) {
						setCategoriesId([...categoriesId, value]);
					} else {
						setCategoriesId(categoriesId.filter((category) => category !== value));
            let data = subCategoriesList.filter((category) => category.parent_category_id === value );
            setSubCategoriesId(subCategoriesId.filter((categoryId) => !(data.map((item) => item.id).includes(categoryId))));
					}
				} else {
					if (!subCategoriesId.includes(value)) {
						setSubCategoriesId([...subCategoriesId, value]);
					} else {
						setSubCategoriesId(subCategoriesId.filter((category) => category !== value));
					}
				}
			}
		}

		const handleSpecialTalent = (value) => {
			if (pageViewType === "edit") {
				const talentIds = value.toString();
				if (!specialTalentIds?.includes(talentIds)) {
					setSpecialTalentIds([...specialTalentIds, talentIds]);
				} else {
					setSpecialTalentIds(specialTalentIds.filter((specialTalentId) => specialTalentId !== talentIds));
				}
			}
		}

		useEffect( () => {
			setPhysicalData({
				...physicalData,
				modelCatagories: categoriesId.concat(subCategoriesId),
				specialTalent: specialTalentIds?.join().replace(/^,/, "")
			});
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [categoriesId, subCategoriesId, specialTalentIds]);
		
    const savePhysicalDetail = async () => {
			const saveResult = await saveOrUpdateUserDetails(physicalData);
			if (!saveResult.success) {
				toast.error(saveResult.msg);
			} else {
				toast.success("Physical details has been updated.");
				dispatch(setUserInfo(saveResult.data));
			}
    };

    useEffect(() => {
			dispatch(getFiltersList());			
			// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

		useEffect( () => {			
			setCategoriesList(filters.categories.filter((item) => item.parent_category_id === null));
			setSubCategoriesList(filters.categories.filter((item) => item.parent_category_id !== null));
			let tempCategoryIds = [];
			for (var i = 0; i < filters.categories.length; i++) {
				if (filters.categories[i].parent_category_id === null) {
					tempCategoryIds.push(filters.categories[i].id);
				}
			}
			setDefaultCategoriesIds(tempCategoryIds);

			setTimeout(() => {
				getCategories();		
			}, 300);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [filters.categories]);

		// const getNationalityData = () => {
		// 	return "dsf"
		// }
    return (
        <React.Fragment>
            <div className={styles.userActionBtn}>
                {pageViewType === 'view' ? (
                    <div className={styles.actionButton}>
                        <Button className={styles.userEditBtn} onClick={() => setPageViewType("edit")}><EditIcon />Edit Details</Button>
                    </div>
                ) : (
                    <div className={styles.actionButton}>
                        <Button className={styles.userEditBtn} onClick={savePhysicalDetail}><SaveButton />Save Details</Button>
                        <Button className={styles.userDeleteBtn} onClick={() => setPageViewType("view")}><CancelButton />Cancel</Button>
                    </div>
                )}
            </div>
            <div className={styles.accountPhysicalTab}>
                <div className={styles.modalPhyinfo}>
                    <div className={styles.phyinforow}>
												<div className={`${styles.filterItems} ${styles.catagoriesCol}`}>
                            <div className={`${styles.itemData} ${styles.catagoriesData}`}>
                                <span className={styles.filterHeading}>Catagories</span>
                                <ul className={styles.catagories}>
																	{categoriesList.map((ele) => (
																		<li 
																			key={ele.id}
																			name="catagories"
																			onClick={() => handleCatagoriesData(ele.id , "categories")}
																			className={
																				categoriesId.includes(ele.id) ? styles.selectedCatagoriesType : ""
																			}
																		>
																			{ele.name}
																		</li>
																	))}
                                </ul>
                            </div>
                        </div>

                        {categoriesId.map((item) => {
                          let data = subCategoriesList.filter((category) => category.parent_category_id === item);
                          const isSubCategories = data.length > 0;
                          const isSelected = data.map((category) => category.id).filter((id) => subCategoriesId.includes(id)).length > 0;
                            return (                              
															(isSelected || pageViewType === 'edit') && isSubCategories && 
                              <div key={item} className={`${styles.filterItems} ${styles.catagoriesCol}`}>
																<div className={`${styles.itemData} ${styles.catagoriesData}`}>
																	<span className={styles.filterHeading}>
																		{categoriesList.filter((ele) => ele.id === item)[0]?.name} Type
																	</span>
																	<ul className={styles.catagories}>
																		{subCategoriesList.map((ele) => {
																			return (																				
																				ele.parent_category_id === item && 
																					(
																					<li
																						key={ele.id}
																						name="catagories"
																						onClick={() => {
																							handleCatagoriesData(ele.id, "subcategories");
																						}}
																						className={
																							subCategoriesId.includes(ele.id)
																								? styles.selectedCatagoriesType
																								: ""
																						}
																					>
																						{ele.name}
																					</li>
																				)
																			);
																		})}
																	</ul>
																</div>
															</div>                              
                            )
                          })
                        }

                        <div className={`${styles.filterItems} ${styles.genderCol}`}>
                            <div className={`${styles.genderData} ${styles.itemData}`}>
                                {filters?.gender?.map((ele) => (
																	<span 
																			key={ele.id}
																			name="gender"
																			className={physicalData?.gender === ele.id ? styles.selectedGender : ""}
																			onClick={() => handleUpdatePhysiqueData(ele.id , "gender")}
																	>
																		{ele.title}
																	</span>
                                ))}
                            </div>
                        </div>

                        <div className={`${styles.filterItems} ${styles.skinCol}`}>
                            <div className={`${styles.itemData} ${styles.skinData}`}>
															<span>Skin tone</span><span className={styles.selectedTonetext}></span>
														{filters?.skinTone?.map((ele) => {                         
                                  return physicalData?.skinTone === ele.id && <span key={ele.id} className={styles
                                  .hairColor} >{ele.title}</span> 
																	})}
                                <ul className={styles.chooseTone}>
																	{filters?.skinTone?.map((ele) => (
																		<li 
																			key={ele.id} 
																			className={`${ele.color} ${physicalData?.skinTone === ele.id ? styles.selectedTone : ""}`}
																			onClick={() => handleUpdatePhysiqueData(ele.id , "skinTone")}
																		>
																		</li>
																	))}
                                </ul>
                            </div>
                        </div>
                        <div className={`${styles.filterItems} ${styles.hairtypeCol}`}>
                            <div className={`${styles.itemData} ${styles.hairtypeData}`}>
                                <span className={styles.hairTypeHeading}>Hair type</span>
                                <ul className={styles.hairType}>
                                    {filters?.hair_type?.map((ele) => (
																			<li 
																				key={ele.id}
																				name="hairType"
																				className={physicalData?.hairType === ele.id ? styles.selectedHairType : ""}
																				onClick={() => handleUpdatePhysiqueData(ele.id , "hairType")}
																			>
																				{ele.title}
																			</li>
                                    ))}
                                </ul>
                            </div>
                        </div>

												<div className={`${styles.filterItems} ${styles.socialStatusCol}`}>
                            <div className={`${styles.itemData} ${styles.socialStatusData}`}>
                                <span className={styles.socialStatusHeading}>Social Status</span>
                                <ul className={styles.socialStatus}>
                                    {filters?.social_status?.map((ele) => (
																			<li 
																				key={ele.id}
																				name="socialStatus"
																				className={physicalData?.socialStatus === ele.id ? styles.selectedSocialStatus : ""}
																				onClick={() => handleUpdatePhysiqueData(ele.id , "socialStatus")}
																			>
																				{ele.name}
																			</li>
                                    ))}
                                </ul>
                            </div>
                        </div>

												<div className={`${styles.filterItems} ${styles.specialTalentCol}`}>
                            <div className={`${styles.itemData} ${styles.specialTalentData}`}>
                                <span>Special Talent</span>
                                <ul className={styles.specialTalent}>
                                    {filters?.special_talents?.map((ele) => (
																			<li 
																				key={ele.id}
																				name="special_talents"
																				className={physicalData?.specialTalent?.includes(ele.id) ? styles.selectedSpecialTalent : ""}
																				onClick={() => handleSpecialTalent(ele.id , "specialTalent")}
																			>
																				{ele.name}
																			</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                   
                        <div className={`${styles.filterItems} ${styles.haircolorCol}`}>
                            <div className={`${styles.itemData} ${styles.haircolorData}`}>
                                <span className={styles.filterHeading}>Hair color</span>
                                
                                {filters?.hair_color?.map((ele) => {                         
                                  return physicalData?.hairColor === ele.id && <span key={ele.id} className={styles
                                  .hairColor} >{ele.title}</span> 
																	})}
                                	<span className={styles.selectedHaircolor}></span>
                                <ul className={styles.hairColor}>
																{filters?.hair_color?.map((ele) => {
                                    return <li 
																		key={ele.id}
																		className={`${ele.color} ${physicalData?.hairColor === ele.id ? styles.selectedHairColor : ""}`}
																		onClick={() => handleUpdatePhysiqueData(ele.id , "hairColor")}
																	>
																	</li>
																})}
                                </ul>
                            </div>
                        </div>

                        <div className={`${styles.filterItems} ${styles.eyecolorCol}`}>
                            <div className={`${styles.itemData} ${styles.eyecolorData}`}>
                                <span className={styles.filterHeading}>Eye color</span>
																{filters?.eye_color?.map((ele) => {
                                		return physicalData?.eyeColor === ele.id && <span key={ele.id} className={styles.eyeColor} >{ele.title}</span> 
																})}
                                <span className={styles.selectedEyecolor}></span>
                                <ul className={styles.eyeColor}>
																{filters?.eye_color?.map((ele) => (
																	<li
																		key={ele.id}
																		className={`${ele.color} ${physicalData?.eyeColor === ele.id ? styles.selectedEyecolor : ""}`}
																		onClick={() => handleUpdatePhysiqueData(ele.id , "eyeColor")}
																	>
																		<img src={Darkbrowneye} alt="Darkbrowneye" />
																	</li>
																))}
                                </ul>
                            </div>
                        </div>

                        <div className={`${styles.filterItems} ${styles.heightCol}`}>
                            <div className={`${styles.itemData} ${styles.heightData}`}>
                                <span>Height</span>
                                <Form.Control
																	name="height"
																	className={styles.filterSelect}
																	size="sm"
																	as="select"
																	value={physicalData.height}
																	onChange={handleUpdatePhysicalData}            
                                >
                                <option value={null}>Select</option>
                                {filters?.height?.map((ele) => (
                                    <option key={ele.id} value={ele.id}>{ele.title}</option>
                                ))}
                                </Form.Control>
                            </div>
                        </div>

                        <div className={`${styles.filterItems} ${styles.bodyshapeCol}`}>
                            <div className={styles.itemData}>
                                <span className={styles.filterHeading}>Body Shape</span>
                                <ul className={styles.bodyShape}><span className={styles.selectedBodyShape}></span>
                                    {filters?.body_shape?.map((ele) => (
																			<li
																				key={ele.id}
																				name="bodyShape"
																				className={physicalData?.bodyShape === ele.id ? styles.selectedBodyShape : ""}
																				onClick={() => handleUpdatePhysiqueData(ele.id , "bodyShape")}
																			>
																				{ele.title}
																			</li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        <div className={`${styles.filterItems} ${styles.weightCol}`}>
                            <div className={`${styles.itemData} ${styles.weightData}`}>
                                <span>Weight</span>
                                <Form.Control
																	name="weight"
																	className={styles.filterSelect}
																	size="sm"
																	as="select" 
																	value={physicalData.weight}
																	onChange={handleUpdatePhysicalData}
                                >
																	<option value={null}>Select</option>  
																	{filters?.weight?.map((ele) => (
																			<option key={ele.id} value={ele.id}>{ele.title}</option>                          
																	))}                           
                                </Form.Control>
                            </div>
                        </div>
                    </div>

                    {pageViewType === "view" &&
                        <div className={styles.manualphyInfo}>
                            <div className={styles.manualinfoCol}>
                                <h4>Facebook</h4>
                                <p>
                                    {physicalDetail?.sm_facebook}
                                </p>
                            </div>

                            <div className={styles.manualinfoCol}>
                                <h4>Instagram</h4>
                                <p>
                                    {physicalDetail?.sm_instagram}
                                </p>
                            </div>
                            
                            <div className={styles.manualinfoCol}>
                                <h4>Price</h4>
                                <p>
                                    {physicalDetail?.price}
                                </p>
                            </div>

                            <div className={styles.manualinfoCol}>
                                <h4>Languages</h4>
                                <p>
                                    {physicalDetail?.languages}
                                </p>
                            </div>

                            <div className={styles.manualinfoCol}>
                                <h4>Tattoos</h4>
                                <p>
                                    {physicalDetail?.tattoos}
                                </p>
                            </div>
                            
                            <div className={styles.manualinfoCol}>
                                <h4>Scars</h4>
                                <p>
                                    {physicalDetail?.scars}
                                </p>
                            </div>

                            <div className={styles.manualinfoCol}>
                                <h4>Nationality</h4>
                                <p>
																	{
																		nationalityData.filter(item => item.code === physicalDetail?.nationality_country_iso_code)[0]?.name
																	}
                                </p>
                            </div>
                        </div>
                    }

                    {pageViewType === "edit" &&
                        <div className={styles.manualphyInfoEdit}>
                            <div className={styles.manualinfoCol}>
                                <h4>Facebook</h4>
                                <Form.Control
                                    type="text"
                                    name="facebook"
                                    value={physicalData.facebook}
                                    onChange={handleUpdatePhysicalData}
                                />
                            </div>

                            <div className={styles.manualinfoCol}>
                                <h4>Instagram</h4>
                                <Form.Control
                                    type="text"
                                    name="instagram"
                                    value={physicalData.instagram}
                                    onChange={handleUpdatePhysicalData}
                                />
                            </div>

                            <div className={styles.manualinfoCol}>
                                <h4>Price</h4>
                                <Form.Control
                                    type="number"
                                    name="price"
                                    value={physicalData.price}
                                    onChange={handleUpdatePhysicalData}
                                />
                            </div>

                            <div className={styles.manualinfoCol}>
                                <h4>Languages</h4>
                                <Form.Control
                                    type="text"
                                    name="language"
                                    value={physicalData.language}
                                    onChange={handleUpdatePhysicalData}
                                />
                            </div>

                            <div className={styles.manualinfoCol}>
                                <h4>Tattoos</h4>
                                <Form.Control
                                    type="text"
                                    name="tattoos"
                                    value={physicalData.tattoos}
                                    onChange={handleUpdatePhysicalData}
                                />
                            </div>

                            <div className={styles.manualinfoCol}>
                                <h4>Scars</h4>
                                <Form.Control
                                    type="text"
                                    name="scars"
                                    value={physicalData.scars}
                                    onChange={handleUpdatePhysicalData}
                                />
                            </div>

                            <div className={styles.manualinfoCol}>
                                <h4>Nationality</h4>
																<Form.Control
																	as="select"
																	name="nationality"
																	value={physicalData.nationality}
																	onChange={handleUpdatePhysicalData}
																	>																
																	<option value="">--Select Nationality--</option>
																	{nationalityData &&
																		nationalityData.map((data) => (
																			<option key={data.code} value={data.code}>{data.name}</option>
																	))}
                                </Form.Control>
                            </div>
                        </div>
                    }
                </div>
                
                <div className={styles.modelImginfo}>
                    <div className={styles.imginfoRow}>
                        <div className={styles.modalimgCol}>
                            <div className={styles.userImgBox}>
                                <div className={styles.userInnerImgBox}>
																{accountDetail?.images.length > 0 ? <img src={`${process.env.REACT_APP_YOUCAST_USER_IMAGES_PATH}/${process.env.REACT_APP_MODE}/${accountDetail.id}/account/thumbnail/th${accountDetail?.images[0].title}`} alt="DummUser" /> : <img src={DummUser} alt="DummUser" />}
                                </div>                         
                            </div> 
                            <p>{accountDetail.name}</p>
                        </div>
                        <div className={styles.modalCat}>
                            <h4>Category</h4>
														{categoriesList.map((item) => {
															return physicalData.modelCatagories.includes(item.id) && 
                              <>
                                <p key={item.id}>{item.name}</p>
                                {subCategoriesList.map((data) => {
                                  return data.parent_category_id === item.id && subCategoriesId.includes(data.id) &&
                                    <p className={styles.catagoriesName} >{data.name}</p>
                                  })
                                }
                              </>
														})}
                        </div>               
                    </div>

                    <div className={styles.modelNameinfo}>
                        <h4>{accountDetail.name}</h4>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default PhysicalDetail;