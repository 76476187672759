import { getEpisodeSceneListByProjectId } from "../../services/episodeSceneService";
import {
	SHOW_LOADING,
	GET_PROJECT_BY_ID_SUCCESS,
	GET_PROJECT_BY_ID_FAILURE,
	GET_EPISODES_LIST_BY_PROJECT_ID_SUCCESS,
	GET_EPISODES_LIST_BY_PROJECT_ID_ERROR
} from "./../types";

import {
	getProjectsByIDApi
} from "./api";

//fetching data
const showloading = () => {
	return {
		type: SHOW_LOADING,
	};
};

//when error is occurred
const Error = (error, type) => {
	return {
		type,
		payload: error,
	};
};

//when successfully fetching data
const Success = (data, type) => {
	return {
		type,
		payload: data.data,
	};
};

export const getProjectsByID = (options) => {
	return async (dispatch) => {
		dispatch(showloading());
		try {
			let data = await getProjectsByIDApi(options);
			dispatch(Success(data.data, GET_PROJECT_BY_ID_SUCCESS));
			options.handleSpinner()
		} catch (error) {
			dispatch(Error(error.response?.data.msg, GET_PROJECT_BY_ID_FAILURE));
		}
	};
}

/**
 * 
 * @param {integer} projectId
 * @param {string} episodeSceneType 
 * @param {integer} offset 
 * @param {integer} limit 
 * @returns: Returns the list of episodes based on project id
 */
export const getEpisodesSceneListingByProjectId = (projectId, episodeSceneType, offset, limit) => {
	return async (dispatch) => {
		dispatch(showloading());
		let result = await getEpisodeSceneListByProjectId(projectId, episodeSceneType, offset, limit);
		if (result.status) {
			dispatch(Success(result, GET_EPISODES_LIST_BY_PROJECT_ID_SUCCESS));
		} else {
			dispatch(Error(result?.errorMsg, GET_EPISODES_LIST_BY_PROJECT_ID_ERROR));
		}
	};
}