import { SignInReducer } from "./SignIn/reducer";
import { UserReducer } from "./Users/reducer";
import { combineReducers } from 'redux';
import { FilterReducer } from './Filters/reducer';
import { SubscriptionsReducer } from './Subscriptions/reducer';
import { CityReducer } from './City/reducer';
import { ProjectReducer } from "./Projects/reducer";
import { TalentReducer } from "./Talents/reducer";
import { SceneReducer } from "./Scenes/reducer";

export const rootReducer = combineReducers({
    SignIn: SignInReducer,
    User: UserReducer,
    Filter: FilterReducer,
    Subscriptions: SubscriptionsReducer,
    CityReducer: CityReducer,
    Projects: ProjectReducer,
    Talent: TalentReducer,
    Scenes: SceneReducer
});
